import React, {createRef} from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';

import SlideContent from './SlideContent';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {useDispatch, useSelector} from 'react-redux';
import {saveActiveDocIndex} from 'core/reducer/essay/readingReducer';
import {Box} from '@mui/material';

const SlickCarousel = props => {
  const dispatch = useDispatch();
  const materialSlider = createRef();

  const contentReducer = useSelector(state => state.contentReducer);
  const readingReducer = useSelector(state => state.readingReducer);

  const NextArrow = props => {
    let distance = 15 * contentReducer.content.docs.length + 25;
    return (
      <ArrowForwardIosIcon
        className={'slick-arrow'}
        onClick={handleNextArrow}
        sx={{
          right: 'calc(50% - ' + distance + 'px) !important',
          top: 'unset !important',
          bottom: '-33px !important',
        }}
      />
    );
  };
  const PrevArrow = props => {
    let distance = 15 * contentReducer.content.docs.length + 25;
    console.log(distance);
    return (
      <ArrowBackIosNewIcon
        className={'slick-arrow'}
        onClick={handlePrevArrow}
        sx={{
          left: 'calc(50% - ' + distance + 'px) !important',
          top: 'unset !important',
          bottom: '-33px !important',
        }}
      />
    );
  };

  function handleNextArrow(element) {
    console.log(
      'handleNextArrow..... contentReducer.content.docs.length =' +
        contentReducer.content.docs.length,
    );
    console.log(
      'handleNextArrow..... readingReducer.activeDocIndex =' +
        readingReducer.activeDocIndex,
    );
    materialSlider.current.slickNext();
  }

  function handlePrevArrow(element) {
    console.log(
      'handlePrevArrow..... contentReducer.content.docs.length =' +
        contentReducer.content.docs.length,
    );
    console.log(
      'handlePrevArrow..... readingReducer.activeDocIndex =' +
        readingReducer.activeDocIndex,
    );
    materialSlider.current.slickPrev();
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <NextArrow className="slick-next" onClick={handleNextArrow} />,
    prevArrow: <PrevArrow className="slick-prev" onClick={handlePrevArrow} />,
    beforeChange: (current, next) => {
      if (next < contentReducer.content.docs.length) {
        dispatch(saveActiveDocIndex(next));
      }
    },
  };

  return (
    <Slider {...settings} ref={materialSlider}>
      {contentReducer.content.docs.map((doc, index) => (
        <SlideContent doc={doc} index={index} key={index} />
      ))}
    </Slider>
  );
};

export default SlickCarousel;
