import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponseHeaders,
} from 'axios';
import {contextPath} from 'core/services/api.context';
import GlobalHistory from 'core/router/GlobalHistory';
import {saveLoginData} from 'core/reducer/common/authReducer';

export const ApiService = {
  apiAxios: null,
  store: null,

  getAccessToken: (store: any) => {
    const state = store.getState();
    console.log(state);
    const authReducer = state.authReducer;
    console.log(authReducer);
    const accessToken = authReducer.accessToken;
    console.log(accessToken);
    return accessToken;
  },

  init: function (store: any) {
    this.store = store;

    const axiosInstance: AxiosInstance = axios.create({
      withCredentials: true,
      timeout: 1000 * 60 * 10,
    });

    axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
      console.log(config);
      if (config.url?.endsWith('/signin')) {
        return config;
      }

      const accessToken = this.getAccessToken(this.store);
      if (config.headers) {
        config.headers['Authorization'] = 'Bearer ' + accessToken;
      }
      return config;
    });

    axiosInstance.interceptors.response.use(
      response => {
        console.log(response);
        const status = response.status;
        const result = response.data.result;
        const res = response.data;
        const headers = response.headers;
        console.log(status);
        console.log(result);
        console.log(res);
        console.log(headers);

        if ([200, 201].includes(status)) {
          if (headers['content-type'] === 'application/octet-stream') {
            console.log(
              'interceptors file down -----------------------------------------------------------',
            );
            const resUrl = window.URL.createObjectURL(new Blob([res]));
            this.doFileDownload(resUrl, headers);
          } else if (result === 1) {
            console.log(response);
            console.log('res = ');
            console.log(status);
            console.log(result);
            console.log(res);
            // console.log(headers);
            return res;
          } else {
            alert((res.message || '통신에 실패하였습니다.') + ' (A)');
            return Promise.reject(new Error(res.message || 'Error'));
          }
        } else {
          alert((res.message || '통신에 실패하였습니다.') + ' (B)');
          return Promise.reject(new Error(res.message || 'Error'));
        }
      },

      async error => {
        console.log('api response error' + error);
        const originalConfig = error.config;

        if (
          !originalConfig.url.endsWith('/signin') &&
          !originalConfig.url.endsWith('/signout') &&
          error.response &&
          error.response.status === 401 &&
          !originalConfig._retry
        ) {
          console.log('reissue token - expired etc');
          originalConfig._retry = true;
          return axios
            .post(contextPath + '/api/v1/auth/reissue', {})
            .then(res => {
              console.log('reissue------------------------------------------');
              console.log(res);
              if (res.data.result === 0) {
                return Promise.reject(
                  new Error('통신에 실패하였습니다. 다시 로그인 하세요. (E)'),
                );
              } else {
                store.dispatch(saveLoginData(res.data));
                return axiosInstance(originalConfig);
              }
            })
            .catch(async error => {
              alert(
                (error.message ||
                  '통신에 실패하였습니다(C). 다시 로그인 하세요.') + ' (C)',
              );
              GlobalHistory.push('/signin', {replace: true});
              return Promise.reject(error);
            });
        } else if (error.response.status === 404) {
          GlobalHistory.push('/404', {replace: true});
          return null;
        } else {
          console.log('not 404, 401 error');
          if (originalConfig.url.endsWith('/signout')) {
            console.log(
              (error.response.data.message ||
                error.message ||
                '통신에 실패하였습니다(D).') + ' (D)',
            );
          } else {
            alert(
              (error.response.data.message ||
                error.message ||
                '통신에 실패하였습니다.') + ' (D)',
            );
          }
          console.log(error);
          return Promise.reject(error);
          // return Promise.reject(new Error(error.response.data.message || error.message || '통신에 실패하였습니다(C).'))
        }
      },
    );

    this.apiAxios = axiosInstance;
  },

  query(url: string, params: object) {
    return this.apiAxios.get(url, params).catch(error => {
      console.log(error);
      throw new Error('ApiService ${error}');
    });
  },

  get(url: string, slug = '') {
    return this.apiAxios.get(`${url}/${slug}`).catch(error => {
      throw new Error('ApiService ${error}');
    });
  },

  post(url: string, params: any) {
    if (params.isMultiPart) {
      console.log(url);
      const config = {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      };
      const data = new FormData();
      data.append('file', params.file);
      return this.apiAxios.post(`${url}`, data, config);
    } else if (params.isLoad) {
      const config2 = {
        headers: {responseType: 'arraybuffer'},
      };
      return this.apiAxios.post(`${url}`, params, config2);
    } else {
      return this.apiAxios.post(`${url}`, params);
    }
  },

  postJson(url: string, params: object) {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
    return this.apiAxios.post(`${url}`, params, config);
  },

  update(url: string, slug: string, params: object) {
    return this.apiAxios.put(`${url}/${slug}`, params);
  },

  put(url: string, params: object) {
    return this.apiAxios.put(`${url}`, params);
  },

  delete(url: string, params: object) {
    const config = {data: params};
    return this.apiAxios.delete(url, config);
  },

  doFileDownload(resUrl: string, headers: AxiosResponseHeaders) {
    const link = document.createElement('a');
    const contentDisposition = headers['content-disposition'];
    let fileName = 'unknown';
    if (contentDisposition) {
      const [fileNameMatch] = contentDisposition
        .split(';')
        .filter((str: string | string[]) => str.includes('filename'));
      if (fileNameMatch) [, fileName] = fileNameMatch.split('=');
    }
    if (fileName.includes('UTF-8')) {
      // fileName = fileName.replace('"UTF-8"', '');
      fileName = fileName.replace('UTF-8', '').replace("''", '');
    }

    link.href = resUrl;
    link.setAttribute('download', `${decodeURI(fileName)}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  },
};

export default ApiService;
