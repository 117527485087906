import React, {useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Box} from '@mui/material';
import {useSelector} from 'react-redux';
import {isNotLogin} from '../../core/utils/AuthUserSettingsUtils';

const Home = () => {
  const navigate = useNavigate();
  const authReducer = useSelector(state => state.authReducer);

  useEffect(() => {
    console.log('Home...');
    console.log(authReducer);
    if (isNotLogin(authReducer.loginInfo.sid)) {
      console.log('Essay isNotLogin #############');
      navigate('/signin', {replace: false});
      return;
    }

    navigate('/usersettings', {replace: false});
  }, []);

  return (
    // <Box sx={{textAlign: 'center'}}>
    //   Coala 에 오신것을 환영합니다.
    //   <Link to="/signin">
    //     <p>로그인</p>
    //   </Link>
    // </Box>
    <Box />
  );
};

export default Home;
