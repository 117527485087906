import {createAction, handleActions} from 'redux-actions';
import produce from 'immer';
import {StringUtils} from 'core/utils/StringUtils';

const SAVE_LOGIN_DATA = 'authReducer/saveLoginData';
const REMOVE_LOGIN_DATA = 'authReducer/removeLoginData';

export const saveLoginData = createAction(SAVE_LOGIN_DATA, data => data);
export const removeLoginData = createAction(REMOVE_LOGIN_DATA);

const initialState = {
  loginInfo: {sid: ''},
  accessToken: '',
  authorities: [],
  role: '',
  isLogin: false,
};

function initLoginData(draft) {
  draft.loginInfo = {};
  draft.accessToken = '';
  draft.authorities = [];
  draft.role = '';
  draft.isLogin = false;
}

const authReducer = handleActions(
  {
    [SAVE_LOGIN_DATA]: (state, action) =>
      produce(state, draft => {
        console.log('SAVE_LOGIN_DATA..................');
        let jwtToken = action.payload;

        if (StringUtils.isEmpty(jwtToken.accessToken)) {
          initLoginData(draft);
        } else {
          draft.loginInfo = {sid: jwtToken.key};
          draft.accessToken = jwtToken.accessToken;
          draft.authorities = jwtToken.authorities;
          draft.role = jwtToken.role;
          draft.isLogin = true;
        }
      }),
    [REMOVE_LOGIN_DATA]: state =>
      produce(state, draft => {
        console.log('REMOVE_LOGIN_DATA..................');
        initLoginData(draft);
      }),
  },
  initialState,
);

export default authReducer;
