import React, {useEffect} from 'react';
import UserSettingsOptions from 'view/components/setting/UserSettingsOptions';
import {Box} from '@mui/material';
import {
  isNotContent,
  isNotLogin,
  isNotUserSettingsOptions,
  notSelectedUserSettings,
} from '../../core/utils/AuthUserSettingsUtils';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {StringUtils} from '../../core/utils/StringUtils';

const UserSettings = () => {
  const navigate = useNavigate();

  const authReducer = useSelector(state => state.authReducer);
  const commonEssayReducer = useSelector(state => state.commonEssayReducer);

  useEffect(() => {
    console.log('==================== useEffect UserSettings');
    if (isNotLogin(authReducer.loginInfo.sid)) {
      console.log('Essay isNotLogin #############');
      navigate('/signin', {replace: false});
      return;
    }

    if (isNotUserSettingsOptions(commonEssayReducer.userSettingsOptions.cids)) {
      console.log('Essay isNotUserSettingsOptions #############');
      navigate('/signin', {replace: false});
    }
  }, []);

  if (
    isNotLogin(authReducer.loginInfo.sid) ||
    isNotUserSettingsOptions(commonEssayReducer.userSettingsOptions.cids)
  ) {
    return <Box />;
  }

  return (
    <Box
      sx={{
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
      }}
    >
      <UserSettingsOptions />
    </Box>
  );
};

export default UserSettings;
