import {StringUtils} from './StringUtils';
import _ from 'lodash';
import {CoalaConstants} from './CoalaConstants';
import {saveHint} from '../reducer/essay/structureReducer';
import {saveContent} from '../reducer/essay/contentReducer';
import {saveUiQuizzesAll} from '../reducer/essay/readingReducer';
import {saveWritingForced} from '../reducer/essay/writingReducer';
import {initAndSaveUserSettings} from '../reducer/essay/commonEssayReducer';

export const DbSubmitUtils = {
  store: null,
  authReducer: null,
  commonEssayReducer: null,
  contentReducer: null,
  readingReducer: null,
  structureReducer: null,
  writingReducer: null,
  analysisReducer: null,

  init(store) {
    this.store = store;
    console.log('DbSubmitUtils init');
    console.log(store);
    this.nowReducer();
    console.log(this.authReducer);
    console.log(this.commonEssayReducer);
    console.log(this.contentReducer);
    console.log(this.readingReducer);
    console.log(this.structureReducer);
    console.log(this.writingReducer);
    console.log(this.analysisReducer);
  },

  nowReducer() {
    const state = this.store.getState();
    this.authReducer = state.authReducer;
    this.commonEssayReducer = state.commonEssayReducer;
    this.contentReducer = state.contentReducer;
    this.readingReducer = state.readingReducer;
    this.structureReducer = state.structureReducer;
    this.writingReducer = state.writingReducer;
    this.analysisReducer = state.analysisReducer;
  },

  getDbKey(userSettings) {
    this.nowReducer();
    return (
      this.authReducer.loginInfo.sid +
      CoalaConstants.DELI_KEY +
      userSettings.mode +
      CoalaConstants.DELI_KEY +
      userSettings.content.value
    );
  },

  settingContent(contentResult) {
    this.nowReducer();
    console.log('DbSubmitUtils-----------------------------------------');
    console.log(contentResult);
    this.store.dispatch(saveContent(contentResult));

    if (Object.keys(this.readingReducer.uiQuizzes).length === 0) {
      console.log('set readingReducer Reducer uiQuizzes ................');
      const newUiQuizzes = [];
      console.log(contentResult);
      for (let docIndex = 0; docIndex < contentResult.docs.length; docIndex++) {
        const answers = Array.from(
          {length: contentResult.docs[docIndex].qnas.length},
          () => '',
        );

        const doc = contentResult.docs[docIndex];
        console.log(doc);
        doc.qnas.forEach((qna, qnaIndex) => {
          console.log(qna);
          console.log('docIndex=' + docIndex + ', qnaIndex' + qnaIndex);
          answers[qnaIndex] = qna.sa;
          console.log(answers);
        });

        const btnValues = Array.from(
          {length: contentResult.docs[docIndex].qnas.length},
          () => CoalaConstants.DEFAULT_QUIZ_BTN_VALUE,
        );

        newUiQuizzes[docIndex] = {
          answers: answers,
          btnValues: btnValues,
        };
      }
      console.log(newUiQuizzes);
      this.store.dispatch(saveUiQuizzesAll({uiQuizzes: newUiQuizzes}));
    }

    if (this.structureReducer.structureBoxes.length === 0) {
      const newStructureBoxes = [];
      const selectedHintIndex =
        contentResult.etc.selectedHintIndex !== undefined
          ? contentResult.etc.selectedHintIndex
          : this.structureReducer.selectedHintIndex;
      console.log(contentResult.hints[selectedHintIndex]);
      contentResult.hints[selectedHintIndex].hint_paragraphs.map(
        (hint_para, index) => {
          newStructureBoxes.push({
            noid: index,
            title: hint_para,
            text: contentResult.hints[selectedHintIndex].hint_writings[index],
            collapsed: false,
            boxIndex: index,
          });
        },
      );
      this.store.dispatch(
        saveHint({
          selectedHintIndex: selectedHintIndex,
          structureBoxes: newStructureBoxes,
        }),
      );
    }

    if (!StringUtils.isEmpty(contentResult.writing)) {
      this.store.dispatch(saveWritingForced(contentResult.writing));
    }

    if (!StringUtils.isEmpty(contentResult.etc)) {
      const settings = {
        clickableCompletedStepsArr: [],

        activeStep: contentResult.etc.activeStep,
        workedLastStep: contentResult.etc.workedLastStep,

        activeSlide: contentResult.etc.activeSlide,
        selectedHintIndex: contentResult.etc.selectedHintIndex,
      };

      if (contentResult.etc.clickableCompletedStep) {
        Object.keys(contentResult.etc.clickableCompletedStep).map(
          (key, index) => {
            console.log(
              'saveStepValues clickableCompletedStep = ' +
                key +
                ':' +
                contentResult.etc.clickableCompletedStep[key] +
                ' ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~INIT_AND_SAVE_USER_SETTINGS',
            );
            if (key !== CoalaConstants.STEP_INDEX_SUBMIT + '') {
              console.log(
                'saveStepValues clickableCompletedStep = ' +
                  key +
                  ':' +
                  contentResult.etc.clickableCompletedStep[key] +
                  ' ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~INIT_AND_SAVE_USER_SETTINGS2',
              );
              settings.clickableCompletedStepsArr.push({
                index: key,
                value: contentResult.etc.clickableCompletedStep[key],
              });
            } else {
              settings.clickableCompletedStepsArr.push({
                index: key,
                value: false,
              });
            }
          },
        );
      }

      console.log(settings);

      this.store.dispatch(initAndSaveUserSettings(settings));
    }
  },

  createContext(paramsStep) {
    this.nowReducer();
    console.log(this.contentReducer.content);
    console.log(this.contentReducer.content.id);
    if (StringUtils.isEmpty(this.contentReducer.content.id)) {
      console.log(
        'createContext empty ----------------------------------------------------',
      );
      return '';
    }

    console.log(
      '+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++',
    );
    console.log(this.readingReducer.uiQuizzes);
    const context = _.cloneDeep(this.contentReducer.content);
    context.docs.forEach((doc, docIndex) => {
      console.log(doc);
      doc.qnas.forEach((qna, qnaIndex) => {
        console.log('docIndex=' + docIndex + ', qnaIndex' + qnaIndex);
        context.docs[docIndex].qnas[qnaIndex].sa =
          this.readingReducer.uiQuizzes[docIndex].answers[qnaIndex];
        console.log(this.readingReducer.uiQuizzes[docIndex].answers[qnaIndex]);
        console.log(context.docs[docIndex].qnas[qnaIndex]);
      });
    });

    const hint = context.hints[this.structureReducer.selectedHintIndex];
    hint.hint_writings.forEach((hint_writing, index) => {
      console.log(
        'index: ' +
          index +
          ' - ' +
          this.structureReducer.structureBoxes[index].text,
      );
      context.hints[this.structureReducer.selectedHintIndex].hint_writings[
        index
      ] = this.structureReducer.structureBoxes[index].text;
    });
    // for (let i = 0; i < hint.hint_writings.length; i++) {
    //   hint.hint_writings[i] = structureReducer.structureBoxes[i].text;
    // }

    context.writing = this.writingReducer.writing;
    if (paramsStep) {
      context.etc = {
        clickableCompletedStep: Object.assign(
          paramsStep.clickableCompletedStepsArr,
        ),
        activeStep: paramsStep.activeStep,
        workedLastStep: paramsStep.workedLastStep,
      };
    } else {
      context.etc = {
        clickableCompletedStep: Object.assign(
          this.commonEssayReducer.clickableCompletedStep,
        ),
        activeStep: this.commonEssayReducer.activeStep,
        workedLastStep: this.commonEssayReducer.workedLastStep,
      };
    }
    context.etc.activeSlide = this.commonEssayReducer.activeSlide;
    context.etc.selectedHintIndex = this.structureReducer.selectedHintIndex;
    context.etc.teacher = this.analysisReducer.teacherScore;

    console.log(this.analysisReducer.scores);
    context.scores = this.analysisReducer.scores;

    return context;
  },

  getStructurePlainText(structureBoxes) {
    const boxTexts = structureBoxes.map((item, index) => ' ' + item.text);
    const boxReview = boxTexts.join('\n');

    console.log(
      '_getStructurePlainText, boxReview-=-------------------------------------------',
    );
    console.log(boxReview);
    return boxReview;
  },
};
