import {ApiService} from 'core/services/api.service';
import {contextPath} from 'core/services/api.context';

export default {
  signin: (params = {}) => {
    const url = contextPath + '/api/v1/auth/signin';
    console.log('authApi signin...............');
    console.log(params);
    return ApiService.post(url, params);
  },

  signout: (params = {}) => {
    const url = contextPath + '/api/v1/auth/signout';
    console.log('authApi signin...............');
    console.log(params);
    return ApiService.post(url, params);
  },

  changePassword: (params = {}) => {
    const url = contextPath + '/api/v1/auth/changepwd';
    console.log('authApi signin...............');
    console.log(params);
    return ApiService.post(url, params);
  },
};
