import {styled} from '@mui/material/styles';
import {Button, Dialog, DialogActions, DialogContent} from '@mui/material';

export const DlgCmm = styled(Dialog)(props => ({
  '& .MuiPaper-root': {
    border: 'solid 1.5px #c1b0a0',
    borderRadius: '20px',
    // backgroundColor: '#fefcf8',
  },
}));

export const DlgContentCmm = styled(DialogContent)(props => ({
  padding: '2rem 2rem 2rem 2rem',
  fontSize: '20px',
  fontWeight: '500',
  width: '400px',
}));

export const DlgActionsCmm = styled(DialogActions)(props => ({
  padding: '0 2rem 1.5rem 1.5rem',
}));
