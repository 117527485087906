import React from 'react';
import {Box} from '@mui/material';
import {Outlet} from 'react-router-dom';
import {useSelector} from 'react-redux';

const EssayLayout = () => {
  return (
    <Box className={'essay-layout'}>
      {/*<TopHeader/>*/}
      <Outlet maxWidth={false} />
    </Box>
  );
};

export default EssayLayout;
