import {createAction, handleActions} from 'redux-actions';
import produce from 'immer';

const INIT_AND_SAVE_USER_SETTINGS =
  'commonEssayReducer/initAndSaveUserSettings';
const SAVE_STEP_VALUES = 'commonEssayReducer/saveStepValues';
const SAVE_TIMER_ID = 'commonEssayReducer/saveTimerId';
const SAVE_ACTIVE_SLIDE = 'commonEssayReducer/saveActiveSlide';
const SAVE_USER_SETTINGS_OPTIONS = 'commonEssayReducer/saveUserSettingsOptions';

export const initAndSaveUserSettings = createAction(
  INIT_AND_SAVE_USER_SETTINGS,
  data => data,
);
export const saveStepValues = createAction(SAVE_STEP_VALUES, data => data);
export const saveTimerId = createAction(SAVE_TIMER_ID, data => data);
export const saveUserSettingsOptions = createAction(
  SAVE_USER_SETTINGS_OPTIONS,
  data => data,
);

const initialState = {
  age: 0,
  name: '',

  userSettingsOptions: {
    cids: [{value: '', text: ''}],
    modes: [],
  },

  selUserSettings: {
    mode: '',
    content: {value: '', text: ''},
  },

  clickableCompletedStep: {},
  activeStep: 0,
  workedLastStep: 0,

  activeSlide: 0,
  timerId: 0,
};

const commonEssayReducer = handleActions(
  {
    [INIT_AND_SAVE_USER_SETTINGS]: (state, action) =>
      produce(state, draft => {
        console.log('INIT_AND_SAVE_USER_SETTINGS..................');
        if (action.payload.age !== undefined) {
          draft.age = action.payload.age;
        }
        if (action.payload.name !== undefined) {
          draft.name = action.payload.name;
        }

        let userSettingsOptions = action.payload.userSettingsOptions;
        if (action.payload.userSettingsOptions !== undefined) {
          draft.userSettingsOptions = userSettingsOptions;
        }
        // else {
        //   draft.userSettingsOptions = {
        //     cids: [{value: '', text: ''}],
        //     modes: [],
        //   };
        // }

        let selUserSettings = action.payload.selUserSettings;
        if (selUserSettings !== undefined) {
          console.log(draft.selUserSettings);
          console.log(selUserSettings);
          draft.selUserSettings.mode = selUserSettings.mode;
          draft.selUserSettings.content = selUserSettings.content;
        }

        if (
          action.payload.clickableCompletedStepsArr !== undefined &&
          action.payload.clickableCompletedStepsArr.length > 0
        ) {
          action.payload.clickableCompletedStepsArr.map(
            (clickableCompletedStep, index) => {
              console.log(
                'saveStepValues clickableCompletedStep = ' +
                  clickableCompletedStep.index +
                  ':' +
                  clickableCompletedStep.value +
                  ' ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~INIT_AND_SAVE_USER_SETTINGS',
              );
              draft.clickableCompletedStep[clickableCompletedStep.index] =
                clickableCompletedStep.value;
            },
          );
        } else {
          draft.clickableCompletedStep = {};
        }

        draft.activeStep =
          action.payload.activeStep !== undefined
            ? Number(action.payload.activeStep)
            : 0;
        draft.workedLastStep =
          action.payload.workedLastStep !== undefined
            ? Number(action.payload.workedLastStep)
            : 0;

        draft.activeSlide =
          action.payload.activeSlide !== undefined
            ? Number(action.payload.activeSlide)
            : 0;
        draft.timerId =
          action.payload.timerId !== undefined ? action.payload.timerId : 0;
      }),
    [SAVE_STEP_VALUES]: (state, action) =>
      produce(state, draft => {
        console.log('saveStepValues~~~~~~~~~~~~~~~~~~~~~~~~~action.payload');
        console.log(action.payload);
        console.log(action.payload.clickableCompletedStepsArr);
        console.log('will workedLastStep: ' + action.payload.workedLastStep);
        console.log('will activeStep: ' + action.payload.activeStep);

        if (action.payload.clickableCompletedStepsArr !== undefined) {
          action.payload.clickableCompletedStepsArr.forEach(
            (clickableCompletedStep, index) => {
              console.log(
                'saveStepValues clickableCompletedStep = ' +
                  clickableCompletedStep.index +
                  ':' +
                  clickableCompletedStep.value +
                  ' ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~',
              );
              draft.clickableCompletedStep[clickableCompletedStep.index] =
                clickableCompletedStep.value;
            },
          );
        }
        if (action.payload.activeStep !== undefined) {
          console.log(
            'saveStepValues activeStep = ' +
              action.payload.activeStep +
              ' ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~',
          );
          draft.activeStep = Number(action.payload.activeStep);
        }
        if (action.payload.workedLastStep !== undefined) {
          console.log(
            'saveStepValues workedLastStep = ' +
              action.payload.workedLastStep +
              ' ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~',
          );
          draft.workedLastStep = Number(action.payload.workedLastStep);
        }
      }),
    [SAVE_ACTIVE_SLIDE]: (state, action) =>
      produce(state, draft => {
        console.log('SAVE_ACTIVE_SLIDE..................' + action.payload);
        draft.activeSlide = action.payload;
      }),
    [SAVE_TIMER_ID]: (state, action) =>
      produce(state, draft => {
        console.log('SAVE_TIMER_ID..................' + action.payload);
        draft.timerId = action.payload;
      }),
    [SAVE_USER_SETTINGS_OPTIONS]: (state, action) =>
      produce(state, draft => {
        console.log(
          'SAVE_USER_SETTINGS_OPTIONS..................' + action.payload,
        );
        draft.userSettingsOptions = action.userSettingsOptions;
      }),
  },
  initialState,
);

export default commonEssayReducer;
