export const CoalaConstants = {
  DEFAULT_QUIZ_BTN_VALUE: '이 답은 어때?',
  DEFAULT_HINT_INDEX: 0,
  DEFAULT_AGE: 10,
  DEFAULT_COUNT: 10,

  DEFAULT_TIMER_MILLI_SEC: 60000,
  DEFAULT_TIMER_SAVE_GAP: 5,

  DELI_KEY: ':',
  HINT: 'help',
  RESULT_SUCC: 1,
  RESULT_FAIL: 0,

  STEP_INDEX_STRUCTURE: 1,
  STEP_INDEX_WRITING: 2,
  STEP_INDEX_ANALYSIS: 3,
  STEP_INDEX_SUBMIT: 4,

  MEDIA_MODE_TYPE_MOBILE: 0,
  MEDIA_MODE_TYPE_TABLET: 1,
  MEDIA_MODE_TYPE_PC: 2,

  ANALYSIS_TOOLTIP_1: '문법에 맞고 자연스러운 문장을 구성하였는가?',
  ANALYSIS_TOOLTIP_2: '읽는 사람이 이해하기 쉽게 글을 구성하였는가?',
  ANALYSIS_TOOLTIP_3: '얼마나 완결성 있고 분명하게 문장을 구성하였는가?',
  ANALYSIS_TOOLTIP_4: '얼마나 풍부하고 구체적으로 내용을 구성하였는가?',
  ANALYSIS_TOOLTIP_5: '얼마나 수준 높은 어휘를 사용하여 문장을 표현하였는가?',
};
