import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Tooltip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {StringUtils} from 'core/utils/StringUtils';
import CloseIcon from '@mui/icons-material/Close';
import {styled} from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Draggable from 'react-draggable';
import {
  BootstrapDialog,
  BootstrapDialogTitle,
  StructAccChip,
  StructAccDetails,
  StructAccDetailsBox,
  StructAccordion,
  StructAccSummary,
  StructAccSummaryBox,
} from '../CourseComps';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const SearchResults = props => {
  const searchResults = props.searchResults;
  console.log('Start SearchResults....................');
  console.log(searchResults);

  const [openMoreDlg, setOpenMoreDlg] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [expandedPanel, setExpandedPanel] = useState(false);

  useEffect(async () => {
    console.log('SearchResults useEffect props.searchResults ...............');
    setExpandedPanel(false);
  }, [props.searchResults]);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleMoreSearchResultWindowPopup = e => {
    console.log('handleMoreSearchResultWindowPopup...' + e.target);
    const closest = e.target.closest('.MuiAccordion-root');
    const accordionIndex = closest.getAttribute('data-index');
    console.log('accordionIndex = ' + accordionIndex);

    const popupFeatures =
      'height=600,width=810,menubar=yes,resizable=yes,scrollbars=yes,status=yes';
    window.open(
      searchResults[accordionIndex].link,
      'moreResult',
      popupFeatures,
    );
  };

  const handleCopyClick = e => {
    console.log('handleCopyClick....');
    console.log(e.target);
    let clickedIndex = e.target.getAttribute('data-index');
    if (StringUtils.isEmpty(clickedIndex)) {
      const closest = e.target.closest('.MuiButtonBase-root');
      clickedIndex = closest.getAttribute('data-index');
    }
    console.log('clickedIndex = ' + clickedIndex);
    console.log('searchResults[clickedIndex] = ' + searchResults[clickedIndex]);
    navigator.clipboard.writeText(searchResults[clickedIndex].link).then(r => {
      setOpenTooltip(true);
      setTimeout(() => {
        setOpenTooltip(false);
      }, 1500);
    });
  };

  function handleClose() {
    setOpenMoreDlg(false);
  }

  const handleChangePanel = panel => (event, isExpanded) => {
    console.log(panel);
    setExpandedPanel(isExpanded ? panel : false);
  };

  //
  // if (searchResults === undefined || searchResults === null || searchResults.length === 0) {
  //   return (
  //     <Box/>
  //   );
  // }

  return (
    <Box>
      {searchResults.map((item, index) => (
        <StructAccordion
          expanded={expandedPanel === 'panel' + index}
          key={index}
          data-index={index}
          onChange={handleChangePanel('panel' + index)}
        >
          <StructAccSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={'panel' + index + 'content'}
            id={'panel' + index + 'header'}
            content={{}}
          >
            <StructAccSummaryBox
              dangerouslySetInnerHTML={StringUtils.createMarkup(item.title)}
            />
          </StructAccSummary>
          <StructAccDetails>
            <StructAccDetailsBox
              dangerouslySetInnerHTML={StringUtils.createMarkup(
                item.description,
              )}
            />

            <Box sx={{textAlign: 'right'}}>
              <StructAccChip
                label="+ 더보기"
                onClick={handleMoreSearchResultWindowPopup}
              />
            </Box>
          </StructAccDetails>
        </StructAccordion>
      ))}
    </Box>
  );
};

SearchResults.propTypes = {
  searchResults: PropTypes.array,
};

export default SearchResults;
