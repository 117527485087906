import React, {useEffect} from 'react';
import {Box, Button, Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {DbSubmitUtils} from 'core/utils/DbSubmitUtils';
import essayRest from 'core/services/api/essay/essayRest';

import {useNavigate} from 'react-router-dom';

const StepSubmit = () => {
  const navigate = useNavigate();

  const commonEssayReducer = useSelector(state => state.commonEssayReducer);
  console.log(
    'StepStructure. start commonEssayReducer.selUserSettings................ = ',
  );
  console.log(commonEssayReducer.userSettings);

  const contentReducer = useSelector(state => state.contentReducer);
  console.log(contentReducer.content);

  const readingReducer = useSelector(state => state.readingReducer);
  console.log(readingReducer.uiQuizzes);

  const structureReducer = useSelector(state => state.structureReducer);
  console.log(
    'StepStructure. start structureReducer.structureBoxes................ = ',
  );
  console.log(structureReducer.structureBoxes);

  const writingReducer = useSelector(state => state.writingReducer);
  console.log('StepWriting. start writingReducer.writing................ = ');
  console.log(writingReducer.writing);

  useEffect(() => {
    console.log(
      'useEffect #####################################################################',
    );
    const submitDb = async timerId => {
      console.log(
        new Date() +
          ': submit #####################################################################',
      );

      const context = DbSubmitUtils.createContext();

      await essayRest
        .submitDb({
          cmd: 'put',
          key: DbSubmitUtils.getDbKey(commonEssayReducer.selUserSettings),
          context: JSON.stringify(context),
        })
        .then(res => {
          console.log(res);
          clearTimeout(timerId);
        })
        .finally(() => {
          console.log('submitDb finally');
        });
    };

    const timerId = commonEssayReducer.timerId;
    submitDb(timerId).then(r => {
      console.log('submitDb End...');
    });
  }, []);

  function handleClickHome() {
    navigate('/usersettings', {replace: false});
  }

  //
  // if (error) return (
  //   <ErrorPageComponent err={error}/>
  // );
  //

  return (
    <Box
      sx={{
        margin: '0 auto',
        textAlign: 'center',
        padding: '5rem 0 0 0',
      }}
    >
      <Box
        pb={5}
        sx={{
          paddingTop: '8rem',
          fontSize: '30px',
          color: '#4e5c43',
          fontFamily: 'Noto Sans KR',
        }}
      >
        글이 제출되었습니다.
      </Box>
      <Button
        variant="contained"
        color="success"
        size="medium"
        onClick={handleClickHome}
        sx={{
          height: '45px !important',
          borderRadius: '4px !important',
        }}
      >
        홈으로
      </Button>
    </Box>
  );
};

export default StepSubmit;
