import {Box, Container, Grid, Chip} from '@mui/material';
import {
  coloredStyle,
  commentLeft,
  commentRight,
  chipHelp,
  chipHelp2,
  AtGrid,
  AtGridRightEnd,
  RadarDescItem,
} from './AnalysisComps';
import {
  ColorUtilsAnaly1,
  ColorUtilsLabel1,
  ColorUtilsAnaly2,
  ColorUtilsLabel2,
} from 'core/utils/ColorUtils';
import {AnalysisConstants} from 'core/utils/AnalysisConstants';
import {FunctionsTwoTone} from '@mui/icons-material';
export const AnalysisText = {
  createTypeText: function (type) {
    try {
      let title = '';
      let content = '';
      if (type == '주장' || type == '대안제시' || type == '찬성반대') {
        title = (
          <>
            <span>내가 쓴 글은 </span>
            <span style={{color: '#EE7D00'}}>[주장 글]</span>
            <span>로 보여요.</span>
          </>
        );
        content = (
          <>
            <span style={{color: '#EE7D00'}}>[주장 글]</span>
            <span>
              은 어떤 주제에 대한 자신의 주장을 펼치는 글로, {"'"}주장의 명확성
              {"'"}과 {"'"}근거의 타당성{"'"}이 중요하게 평가돼요.{' '}
            </span>
            <span style={{color: '#EE7D00'}}>[주장 글]</span>
            <span>에서 나타나는 문장의 종류에는 이런 것들이 있어요.</span>
            <span>
              <br />
              💬화제 제시, 주장, 근거, 예시, 주장 반박, 반박에 대한 재반박, 결론
            </span>
          </>
        );
      } else if (type == '설명글') {
        title = title = (
          <>
            <span>내가 쓴 글은 </span>
            <span style={{color: '#EE7D00'}}>[설명 글]</span>
            <span>로 보여요.</span>
          </>
        );
        content = (
          <>
            <div>
              <span style={{color: '#EE7D00'}}>[설명 글]</span>
              <span>
                은 어떤 대상에 대해 설명하는 글로, {"'"}정보의 사실성{"'"}과{' '}
                {"'"}
                객관적인 표현{"'"}이 중요하게 평가돼요.{' '}
              </span>
              <span style={{color: '#EE7D00'}}>[설명 글]</span>
              <span>에서 나타나는 문장의 종류에는 이런 것들이 있어요.</span>
            </div>
            <div
              style={{
                display: 'inline-block',
                marginTop: '10px',
              }}
            >
              💬 화제 제시, 중심 내용, 마무리
            </div>
          </>
        );
      } else {
        title = (
          <>
            <span>내가 쓴 글은 </span>
            <span style={{color: '#EE7D00'}}>[글짓기]</span>
            <span>로 보여요.</span>
          </>
        );
        content = (
          <>
            <span style={{color: '#EE7D00'}}>[글짓기]</span>
            <span>
              는 형식에 구애받지 않고 자신의 느낌과 생각을 자유롭게 표현하는
              글이에요.{' '}
            </span>
            <span style={{color: '#EE7D00'}}>[글짓기]</span>
            <span>에서 나타나는 문장의 종류에는 이런 것들이 있어요.</span>
            <span style={{paddingBottom: '5'}}>
              <br />
              💬 화제 제시, 중심 내용, 마무리
            </span>
          </>
        );
      }
      return (
        <>
          <Box pb={2} style={{fontSize: '21px', fontWeight: 'bold'}}>
            {title}
          </Box>
          <Box
            style={{
              fontSize: '18px',
              fontWeight: 'normal',
            }}
          >
            {content}
          </Box>
        </>
      );
    } catch (e) {
      console.error(e);
      return '';
    }
  },
  createHtmlTextLabel: function (type) {
    try {
      if (type == '주장' || type == '대안제시' || type == '찬성반대') {
        const keys = [
          '화제 제시',
          '주장',
          '근거',
          '예시',
          '주장 반박',
          '반박 재반박',
          '결론',
        ];
        return (
          <div>
            {keys.map((item, index) => (
              <span key={index}>
                <strong
                  style={coloredStyle.coloredLabel(ColorUtilsLabel1[item])}
                >
                  ■{' '}
                </strong>
                <span style={{fontSize: '16px', fontWeight: 'bold'}}>
                  {item + '  '}
                </span>
              </span>
            ))}
          </div>
        );
      } else {
        const keys = ['화제 제시', '중심 내용', '마무리'];
        return (
          <div>
            {keys.map((item, index) => (
              <span key={index}>
                <strong
                  style={coloredStyle.coloredLabel(ColorUtilsLabel2[item])}
                >
                  ■{' '}
                </strong>
                <span style={{fontSize: '16px', fontWeight: 'bold'}}>
                  {item + '  '}
                </span>
              </span>
            ))}
          </div>
        );
      }
    } catch (e) {
      console.error(e);
      return '';
    }
  },

  createHtmlTextAnaly: function (paragraph_texts, type) {
    let isOpinion = false;
    if (type == '주장' || type == '대안제시' || type == '찬성반대') {
      isOpinion = true;
    }
    try {
      return (
        <div>
          {paragraph_texts.map((paragraph, index) => (
            <div key={index}>
              {paragraph.map((sentence, index2) => (
                <span key={index2}>
                  <span
                    style={coloredStyle.coloredText(
                      isOpinion
                        ? ColorUtilsAnaly1[sentence.tag]
                        : ColorUtilsAnaly2[sentence.tag],
                    )}
                  >
                    {sentence.text}
                  </span>
                  <span> </span>
                </span>
              ))}
            </div>
          ))}
        </div>
      );
    } catch (e) {
      console.error(e);
      return '';
    }
  },
  createWholeComment: function (
    paragraph_text,
    trait_comment,
    index,
    trait_score,
  ) {
    const general = this.createGeneralComment(index, trait_score);

    let idx = 0;
    const firstComment =
      trait_comment == ''
        ? ''
        : trait_comment.map((comment, index) => {
            if (comment['pid'] == -1) {
              if (comment['type'] == 'ABC') {
                idx++;
                return this.createDetailCommentABC(
                  idx,
                  comment['A'],
                  comment['B'],
                  comment['C'],
                );
              } else if (comment['type'] == 'AC') {
                idx++;
                return this.createDetailCommentAC(
                  idx,
                  comment['A'],
                  comment['C'],
                );
              } else {
                return this.createDetailCommentC(comment['C']);
              }
            }
            return '';
          });
    const commentPerParagraph = paragraph_text.map((paragraph, index1) => {
      let commentCount = 0;
      const para = this.createParagrph(paragraph_text[index1]);
      const comment =
        trait_comment == ''
          ? ''
          : trait_comment.map((comment, index) => {
              if (comment['pid'] == index1) {
                commentCount++;
                if (comment['type'] == 'ABC') {
                  idx++;
                  return this.createDetailCommentABC(
                    idx,
                    comment['A'],
                    comment['B'],
                    comment['C'],
                  );
                } else if (comment['type'] == 'AC') {
                  idx++;
                  return this.createDetailCommentAC(
                    idx,
                    comment['A'],
                    comment['C'],
                  );
                } else {
                  return this.createDetailCommentC(comment['C']);
                }
              }
              return '';
            });

      if (commentCount == 0) {
        return '';
      }
      return (
        <Grid key={index1} container item xs={12}>
          {para}
          {comment}
        </Grid>
      );
    });
    return (
      <Grid
        container
        item
        xs={12}
        style={{
          margin: 'auto',
          padding: 20,
          textAlign: 'left',
        }}
      >
        {general}
        {firstComment}
        {commentPerParagraph}
      </Grid>
    );
  },
  createGeneralComment: function (index, trait_score) {
    let detailText = '';
    try {
      detailText = AnalysisConstants[index][trait_score].detail;
    } catch {
      detailText = '';
    }
    return (
      <>
        <Grid container>
          <Grid item xs={2.5} style={{fontSize: '21px'}}>
            {this.getLabelText(index)}
          </Grid>
          <Grid item xs={5} style={{color: '#EE7D00', fontSize: '21px'}}>
            {this.getAlphbeticScore(trait_score)}
          </Grid>
        </Grid>
        <Box
          style={{
            fontSize: '18px',
            fontWeight: 'normal',
            marginTop: '10px',
            marginBottom: '20px',
          }}
        >
          {detailText}
        </Box>
      </>
    );
  },
  createDetailCommentABC: function (idx, A, B, C) {
    return (
      <Grid container style={{marginBottom: '15px'}}>
        <Grid item xs={5} style={commentLeft}>
          {' '}
          ({idx}) {A} →{' '}
          <span style={{color: '#EE7D00', fontWeight: '700'}}>{B}</span>
        </Grid>
        <Grid item xs={1.5} style={{textAlign: 'center'}}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              marginTop: '5px',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Chip
              label="도움말"
              variant="outlined"
              size="small"
              style={chipHelp}
            />
          </div>
        </Grid>
        <Grid item xs={5} style={commentRight}>
          {' '}
          {C}
        </Grid>
      </Grid>
    );
  },

  createDetailCommentAC: function (idx, A, C) {
    return (
      <Grid container style={{marginBottom: '15px'}}>
        <Grid item xs={5} style={commentLeft}>
          {' '}
          ({idx}) {A}
        </Grid>
        <Grid item xs={1.5}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              marginTop: '5px',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Chip
              label="도움말"
              variant="outlined"
              size="small"
              style={chipHelp}
            />
          </div>
        </Grid>
        <Grid item xs={5} style={commentRight}>
          {' '}
          {C}
        </Grid>
      </Grid>
    );
  },
  createDetailCommentC: function (C) {
    return (
      <Grid
        container
        style={{
          marginBottom: '15px',
        }}
      >
        <Grid item xs={1}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              marginTop: '5px',
            }}
          >
            <Chip
              label="포인트"
              variant="filled"
              size="small"
              style={chipHelp2}
            />
          </div>
        </Grid>
        <Grid item xs={11} style={commentRight}>
          {C}
        </Grid>
      </Grid>
    );
  },
  createParagrph(sentence_list) {
    //const paragraph = sentence_list.join(' ');
    const paragraph = sentence_list.map((sentence, index) => {
      try {
        return sentence.text + ' ';
      } catch {
        return '';
      }
    });
    return (
      <Grid
        container
        style={{
          marginTop: '20px',
          marginBottom: '15px',
          fontSize: '15px',
          fontWeight: 'normal',
          color: 'rgba(0, 0, 0, 1)',
          border: '1px solid rgba(0, 0, 0, 1)',
          borderRadius: '10px',
          padding: '20px',
        }}
      >
        {paragraph}
      </Grid>
    );
  },

  getAlphbeticScore: function (num) {
    if (num == 9) {
      return 'A+';
    } else if (num == 8) {
      return 'A';
    } else if (num == 7) {
      return 'B+';
    } else if (num == 6) {
      return 'B';
    } else if (num == 5) {
      return 'C+';
    } else if (num == 4) {
      return 'C';
    } else if (num == 3) {
      return 'D+';
    } else if (num == 2) {
      return 'D';
    } else if (num == 1) {
      return 'F';
    } else {
      return '분석불가';
    }
  },
  getLabelText: function (num) {
    if (num == 1) {
      return '문법의 정확성';
    } else if (num == 2) {
      return '어휘의 풍부성';
    } else if (num == 3) {
      return '의도의 표현';
    } else if (num == 4) {
      return '문장 구사력';
    } else if (num == 5) {
      return '조직과 전개';
    } else {
      return '주제의 명확성';
    }
  },
};
