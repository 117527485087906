import {createAction, handleActions} from 'redux-actions';
import produce from 'immer';

const INIT_READING = 'readingReducer/initReading';
const SAVE_UI_QUIZZES_ALL = 'readingReducer/saveUiQuizzesAll';
const SAVE_ANSWER = 'readingReducer/saveUiQuizzesByMaterialIndex';
const SAVE_BTN_VALUE = 'readingReducer/saveBtnValue';
const SAVE_ACTIVE_DOC_INDEX = 'readingReducer/saveActiveDocIndex';
const MARK_CLICKED_BTN = 'readingReducer/markClickedBtn';

export const initReading = createAction(INIT_READING, data => data);
export const saveUiQuizzesAll = createAction(SAVE_UI_QUIZZES_ALL, data => data);
export const saveAnswer = createAction(SAVE_ANSWER, data => data);
export const saveBtnValue = createAction(SAVE_BTN_VALUE, data => data);
export const saveActiveDocIndex = createAction(
  SAVE_ACTIVE_DOC_INDEX,
  data => data,
);
export const markClickedBtn = createAction(MARK_CLICKED_BTN, data => data);

const initialState = {
  uiQuizzes: [],
  activeDocIndex: 0,
  clickedBtn: {},
};

const readingReducer = handleActions(
  {
    [INIT_READING]: state =>
      produce(state, draft => {
        console.log('INIT_READING..................');
        draft.uiQuizzes = [];
        draft.activeDocIndex = 0;
        draft.clickedBtn = {};
      }),
    [SAVE_UI_QUIZZES_ALL]: (state, action) =>
      produce(state, draft => {
        console.log('SAVE_UI_QUIZZES_ALL..................');
        console.log(action.payload);
        let data = action.payload;
        draft.uiQuizzes = data.uiQuizzes;
      }),
    [SAVE_ANSWER]: (state, action) =>
      produce(state, draft => {
        console.log('SAVE_ANSWER..................');
        let data = action.payload;
        draft.uiQuizzes[data.activeDocIndex].answers[data.quizIndex] =
          data.answer;
      }),
    [SAVE_BTN_VALUE]: (state, action) =>
      produce(state, draft => {
        console.log('SAVE_BTN_VALUE..................');
        let data = action.payload;
        draft.uiQuizzes[data.activeDocIndex].btnValues[data.quizIndex] =
          data.btnValue;
      }),
    [SAVE_ACTIVE_DOC_INDEX]: (state, action) =>
      produce(state, draft => {
        console.log('SAVE_ACTIVE_DOC_INDEX..................');
        draft.activeDocIndex = action.payload;
      }),
    [MARK_CLICKED_BTN]: (state, action) =>
      produce(state, draft => {
        console.log('MARK_CLICKED_BTN..................');
        console.log(state.clickedBtn);
        draft.clickedBtn[action.payload] = true;
      }),
  },
  initialState,
);

export default readingReducer;
