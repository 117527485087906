import {StringUtils} from './StringUtils';

export const isNotLogin = sid => {
  if (StringUtils.isEmpty(sid)) return true;
  else return false;
};

export const isNotUserSettingsOptions = cids => {
  if (StringUtils.isEmpty(cids[0].value)) return true;
  else return false;
};

export const notSelectedUserSettings = selUserSettings => {
  if (StringUtils.isEmpty(selUserSettings.content.value)) return true;
  else return false;
};

export const isNotContent = content => {
  if (StringUtils.isEmpty(content.id)) return true;
  else return false;
};
