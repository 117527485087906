export const StringUtils = {
  isEmpty: function (value = '') {
    return value == null || value === '' || value === undefined;
  },
  isNotEmpty(value) {
    return !this.isEmpty(value);
  },
  createMarkup: function (html) {
    return {__html: html};
  },

  coHtmlToText: function (htmlText) {
    let midText = htmlText.replace(/&nbsp;/gi, '');
    console.log('midText = ', midText);
    return midText.replace(/<\s*br[^>]?>/, '\n').replace(/(<([^>]+)>)/g, '');
  },

  getParagraphs: function (writingHtml) {
    console.log('getParagraphs==========================================');
    if (this.isEmpty(writingHtml)) {
      return [];
    }

    const newArr = [];
    let convertedIndex = 0;
    const divArr = writingHtml.split('<div');
    divArr.forEach((item, index, arr) => {
      console.log('index = ' + index + ', item = ' + item);
      if (index === 0) {
        return;
      }

      let para = item.replace(/(\s*)/g, '');
      if (para === '><br/></div>' || para === '><br></div>') {
        return;
      }
      item = '<div' + item;
      console.log('index = ' + index + ', item = ' + item);
      newArr[convertedIndex++] = item;
    });
    console.log(newArr);
    return newArr;
  },

  getPureText(paragraphs) {
    let text = '';
    paragraphs.forEach((para, index) => {
      const paraText = this.coHtmlToText(para);
      text += paraText + '\n';
    });

    return text;
  },

  coHtmlParaToText(htmlText, param2) {
    console.log(
      'coHtmlParaToText ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~',
    );
    console.log(htmlText);
    const paragraphs = this.getParagraphs(htmlText);
    console.log(paragraphs);
    const pureText = this.getPureText(paragraphs);
    console.log(pureText);

    return pureText;
  },

  getParaWithoutEmpty(orgText) {
    console.log(
      'StringUtils.getTextWithoutEmptyPara orgText ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~',
    );
    console.log(orgText);
    const splitedArr = orgText.split(/\n/);
    console.log(splitedArr);
    const splitedArrWithout = [];
    for (let i = 0; i < splitedArr.length; i++) {
      const item = splitedArr[i];
      // console.log(item);
      if (!StringUtils.isEmpty(item.trim())) {
        splitedArrWithout.push(item.trim());
      }
    }
    return splitedArrWithout;
  },
  isWhite(para) {
    const trimmedPara = para.trim();
    if (StringUtils.isEmpty(trimmedPara) || trimmedPara === '\n') {
      return true;
    }
    return false;
  },

  replaceAll(str, searchStr, replaceStr) {
    return str.split(searchStr).join(replaceStr);
  },

  getFirst(inputText) {
    try {
      const first = inputText.slice(0, inputText.indexOf(' '));
      return first;
    } catch (e) {
      console.error(e);
      return '';
    }
  },
  getGradeText(inputText) {
    try {
      const kReadVal = Number(this.getFirst(inputText));
      const age = Math.floor(kReadVal / 100);
      let grade = '';
      if (age <= 8) {
        grade = '초등 1학년';
      } else if (age === 9) {
        grade = '초등 2학년';
      } else if (age === 10) {
        grade = '초등 3학년';
      } else if (age === 11) {
        grade = '초등 4학년';
      } else if (age === 12) {
        grade = '초등 5학년';
      } else if (age === 13) {
        grade = '초등 6학년';
      } else if (age === 14) {
        grade = '중등 1학년';
      } else if (age === 15) {
        grade = '중등 2학년';
      } else if (age === 16) {
        grade = '중등 3학년';
      } else if (age === 17) {
        grade = '고등 1학년';
      } else if (age === 18) {
        grade = '고등 2학년';
      } else if (age === 19) {
        grade = '고등 3학년';
      } else {
        grade = '성인';
      }
      return grade + '이 읽기에 적합한 수준의 글을 썼어요.';
    } catch (e) {
      console.error(e);
      return '';
    }
  },
};
