export const ColorUtilsAnaly1 = {
  LED: 'rgba(240, 113, 170, 0.3)',
  POS: 'rgba(46, 188, 173, 0.3)',
  CLA: 'rgba(16, 132, 48, 0.3)',
  EVD: 'rgba(138, 139, 143, 0.3)',
  COC: 'rgba(235, 228, 62, 0.3)',
  REB: 'rgba(232, 122, 60, 0.3)',
  CON: 'rgba(185, 138, 233, 0.3)',
  O: 'rgba(255, 255, 255, 0.1)',
};
export const ColorUtilsLabel1 = {
  '화제 제시': 'rgba(240, 113, 170, 0.7)',
  주장: 'rgba(46, 188, 173, 0.7)',
  근거: 'rgba(16, 132, 48, 0.7)',
  예시: 'rgba(138, 139, 143, 0.7)',
  '주장 반박': 'rgba(235, 228, 62, 0.7)',
  '반박 재반박': 'rgba(232, 122, 60, 0.7)',
  결론: 'rgba(185, 138, 233, 0.7)',
};
export const ColorUtilsAnaly2 = {
  LED: 'rgba(240, 113, 170, 0.3)',
  POS: 'rgba(16, 132, 48, 0.3)',
  CLA: 'rgba(16, 132, 48, 0.3)',
  EVD: 'rgba(255, 255, 255, 0)',
  COC: 'rgba(255, 255, 255, 0)',
  REB: 'rgba(255, 255, 255, 0)',
  CON: 'rgba(185, 138, 233, 0.3)',
  O: 'rgba(255, 255, 255, 0)',
};
export const ColorUtilsLabel2 = {
  '화제 제시': 'rgba(240, 113, 170, 0.7)',
  '중심 내용': 'rgba(16, 132, 48, 0.7)',
  마무리: 'rgba(185, 138, 233, 0.7)',
};
