export const ElementUtils = {
  getClosestDataInfo: function (e, selector) {
    let noid = e.target.getAttribute('data-noid');
    let clickedIndex = e.target.getAttribute('data-index');
    if (clickedIndex) {
      clickedIndex = parseInt(e.target.getAttribute('data-index'));
    } else {
      const near = e.currentTarget.closest(selector);
      console.log(near);
      clickedIndex = parseInt(near.getAttribute('data-index'));
      noid = near.getAttribute('data-noid');
    }
    return {noid, clickedIndex};
  },
};
