import {ApiService} from 'core/services/api.service';
import {contextPath} from 'core/services/api.context';

export default {
  settingsLogin: (params = {}) => {
    const url = contextPath + '/api/v1/essay/settingsLogin';
    console.log('essayApi getUserSettingsOptions...............');
    console.log(params);
    return ApiService.postJson(url, params);
  },

  getUserContent: (params = {}) => {
    const url = contextPath + '/api/v1/essay/content';
    console.log('essayApi getUserContent...............');
    console.log(params);
    return ApiService.postJson(url, params);
  },

  getOrSaveDraftDb: (params = {}) => {
    const url = contextPath + '/api/v1/essay/db';
    console.log('essayApi getOrSaveDraftDb...............');
    console.log(params);
    return ApiService.postJson(url, params);
  },

  submitDb: (params = {}) => {
    const url = contextPath + '/api/v1/essay/submit';
    console.log('essayApi submitDb...............');
    console.log(params);
    return ApiService.postJson(url, params);
  },

  //----------------------------------------------------------

  readingPage: (params = {}) => {
    const url = contextPath + '/api/v1/essay/reading/page';
    console.log('essayApi readingPage...............');
    console.log(params);
    return ApiService.post(url, params);
  },

  structurePage: (params = {}) => {
    const url = contextPath + '/api/v1/essay/structure/page';
    console.log('essayApi structurePage...............');
    console.log(params);
    return ApiService.post(url, params);
  },

  getStructureSearch: (params = {}) => {
    const url = contextPath + '/api/v1/essay/structure/search';
    console.log('essayApi checkSpelling...............');
    console.log(params);
    return ApiService.postJson(url, params);
  },

  checkSpelling: (params = {}) => {
    const url = contextPath + '/api/v1/essay/writing/spelling';
    console.log('essayApi checkSpelling...............');
    console.log(params);
    return ApiService.post(url, params);
  },

  analysisPage: (params = {}) => {
    const url = contextPath + '/api/v1/essay/analysis/page';
    console.log('essayApi analysisPage...............');
    console.log(params);
    return ApiService.post(url, params);
  },
  analysisKeewiPage: (params = {}) => {
    const url = contextPath + '/api/v1/essay/analysis/keewi/page';
    console.log('essayApi analysisKeewiPage...............');
    console.log(params);
    return ApiService.post(url, params);
  },
};
