import React, {useEffect, useRef, useState} from 'react';
import essayRest from 'core/services/api/essay/essayRest';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Button, Divider, Grid} from '@mui/material';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import Spelling from './Spelling';
import {StringUtils} from 'core/utils/StringUtils';
import SpellEditor from './SpellEditor';
import ApiLoading from '../../commons/ApiLoading';
import {DbSubmitUtils} from 'core/utils/DbSubmitUtils';
import {
  CourseTitleWrapper,
  GreenDashBox,
  MissionBox,
  QnaTitleBox,
  WritingButton,
  WritingNoteBox,
} from '../CourseComps';
import {DlgActionsCmm, DlgCmm, DlgContentCmm} from '../../commons/DialogComps';
import writingNote from 'assets/media/bg/writing-note.png';

import {saveLengthForced} from 'core/reducer/essay/textLengthReducer';

const StepWriting = () => {
  const dispatch = useDispatch();
  const spellEditor = useRef();

  const [retText, setRetText] = useState('');
  const [correction, setCorrection] = useState([]);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const commonEssayReducer = useSelector(state => state.commonEssayReducer);
  const contentReducer = useSelector(state => state.contentReducer);
  const structureReducer = useSelector(state => state.structureReducer);
  const writingReducer = useSelector(state => state.writingReducer);

  const textLengthReducer = useSelector(state => state.textLengthReducer);

  useEffect(() => {
    console.log('StepWriting useEffect...............');

    let plainText = writingReducer.initialized
      ? writingReducer.writing
      : DbSubmitUtils.getStructurePlainText(structureReducer.structureBoxes);

    console.log(plainText);
    if (StringUtils.isNotEmpty(plainText)) {
      fetchSpelling(plainText).then(r => {
        console.log('fetchSpelling then');
      });

      console.log('text length : ' + plainText.trim().length);
    }
  }, []);

  const fetchSpelling = async (plainText, isRefresh) => {
    if (StringUtils.isEmpty(plainText)) {
      return;
    }

    setLoading(true);

    console.log(
      'fetchSpelling plainText = ' + plainText + ', isRefresh = ' + isRefresh,
    );
    const params = {
      age: commonEssayReducer.age,
      //eslint-disable-next-line
      text: plainText.replace(/\"/gi, "'"),
    };
    return await essayRest
      .checkSpelling(params)
      .then(res => {
        console.log(res);
        setRetText(res.data.retText);
        if (isRefresh) {
          spellEditor.current.refresh(plainText, correction);
        }
        setCorrection(res.data.results);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClickOpenWritingRefresh = e => {
    setOpen(true);
    console.log('handleClickOpenWritingRefresh....................');
  };

  const handleCloseChangeWritingRefresh = e => {
    console.log('handleCloseChangeWritingRefresh....................');
    console.log(e.target);
    const plainText = DbSubmitUtils.getStructurePlainText(
      structureReducer.structureBoxes,
    );
    fetchSpelling(plainText, true).then(r => {
      setOpen(false);
    });
  };

  const handleCloseCancelWritingRefresh = e => {
    setOpen(false);
  };

  const handleClickCheckSpelling = async e => {
    console.log(
      'handleClickCheckSpelling..................' + writingReducer.writing,
    );
    fetchSpelling(writingReducer.writing);
  };

  return (
    <CourseTitleWrapper>
      <Grid container spacing={4} mb={2}>
        <Grid item xs={12} sx={{paddingTop: '16px !important'}}>
          <MissionBox>{contentReducer.content.mission}</MissionBox>
          <QnaTitleBox>{contentReducer.content.hint_title}</QnaTitleBox>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} md={2}>
          <Box ml={8} mt={3} sx={{textAlign: 'left', color: '#343f2b'}}>
            <span style={{color: '#ff8414', fontWeight: 'bold'}}>
              {textLengthReducer.textLength ? textLengthReducer.textLength : 0}
            </span>
            <span> 글자</span>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box pb={1} mb={1} sx={{textAlign: 'right'}}>
            <WritingButton
              variant="outlined"
              startIcon={<SystemUpdateAltIcon />}
              size="small"
              onClick={handleClickOpenWritingRefresh}
            >
              글 다시 가져오기
            </WritingButton>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} mb={2}>
          <Box
            sx={{
              position: 'relative',
              height: '100%',
              textAlign: 'right',
            }}
          >
            <WritingButton
              variant="contained"
              startIcon={<PlaylistAddCheckCircleIcon />}
              onClick={handleClickCheckSpelling}
              size="small"
            >
              맞춤법 검사하기
            </WritingButton>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              // border: '1px solid #ddd',
              padding: '0 1.25rem 0px 0.5rem',
              backgroundColor: '#ffffff',
              // boxShadow: '2px 2px 3px 0 rgba(116, 136, 101, 0.46)',
              borderTopLeftRadius: '25px',
              borderTopRightRadius: '25px',
              height: '25px',
            }}
          />
          <Box sx={{display: 'flex'}}>
            <WritingNoteBox
              sxStyle={{
                // border: '1px solid #ddd',
                width: '50px',
                padding: '0 0 0 0',
                backgroundImage: `url(${writingNote})`,
                backgroundRepeat: 'repeat',
              }}
            />
            <WritingNoteBox
              sxStyle={{
                // border: '1px solid #ddd',
                padding: '0 1.25rem 0 0.25rem',
                // marginLeft: '2rem',
                backgroundColor: '#ffffff',
                // boxShadow: '2px 2px 3px 0 rgba(116, 136, 101, 0.46)',
                // borderTopRightRadius: '25px',
                // borderBottomRightRadius: '25px',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  paddingLeft: '16px',
                  paddingRight: '38px',
                  marginTop: '0.75rem',
                  marginBottom: '1rem',
                }}
              >
                <Divider
                  sx={{
                    borderColor: 'rgba(178, 177, 177, 0.5)',
                    borderBottomWidth: '2px',
                  }}
                />
              </Box>

              <SpellEditor correction={correction} ref={spellEditor} />
            </WritingNoteBox>
            <WritingNoteBox
              sxStyle={{
                width: '5px',
                padding: '0',
                boxShadow: '2px 2px 3px 0 rgba(116, 136, 101, 0.46)',
                backgroundColor: '#ffffff',
              }}
            />
          </Box>
          <Box
            sx={{
              // border: '1px solid #ddd',
              // padding: '35px 1.25rem 10px 0.5rem',
              backgroundColor: '#ffffff',
              boxShadow: '2px 2px 3px 0 rgba(116, 136, 101, 0.46)',
              borderBottomLeftRadius: '25px',
              borderBottomRightRadius: '25px',
              height: '25px',
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <GreenDashBox>
            <Spelling correction={correction} />
          </GreenDashBox>
        </Grid>
      </Grid>

      <DlgCmm
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DlgContentCmm sx={{width: '420px', paddingLeft: '3rem'}}>
          <Box>글 다시 가져오기를 하면 작성 중인 글이 사라져요.</Box>
          <Box>글을 다시 가져올까요?</Box>
        </DlgContentCmm>
        <DlgActionsCmm>
          <Button
            variant="outlined"
            color="warning"
            onClick={handleCloseCancelWritingRefresh}
          >
            취소하기
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={handleCloseChangeWritingRefresh}
            autoFocus
          >
            가져오기
          </Button>
        </DlgActionsCmm>
      </DlgCmm>

      <ApiLoading loading={loading} />
    </CourseTitleWrapper>
  );
};

export default StepWriting;
