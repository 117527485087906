import {createAction, handleActions} from 'redux-actions';
import produce from 'immer';

const INIT_CONTENT = 'contentReducer/initContent';
const SAVE_CONTENT = 'contentReducer/saveContent';

export const initContent = createAction(INIT_CONTENT, data => data);
export const saveContent = createAction(SAVE_CONTENT, data => data);

const initialState = {
  content: {
    id: '',
    title: '',
    search: '',
    mission: '',
    hint_title: '',
    summary: '',
    docs: [{id: '', kind: '', url: '', qna_title: '', qnas: []}],
    hints: [{id: '', hint_option: '', hint_writings: [], hint_paragraphs: []}],
    writing: '',
    etc: {},
    level: '',
  },
  qnasCount: 0,
  hint_options: [],
};

const contentReducer = handleActions(
  {
    [INIT_CONTENT]: (state, action) =>
      produce(state, draft => {
        draft.content = {
          id: '',
          title: '',
          search: '',
          mission: '',
          hint_title: '',
          summary: '',
          docs: [{id: '', kind: '', url: '', qna_title: '', qnas: []}],
          hints: [
            {id: '', hint_option: '', hint_writings: [], hint_paragraphs: []},
          ],
          writing: '',
          etc: {},
          level: '',
        };
        draft.qnasCount = '';
        draft.hint_options = [];
      }),
    [SAVE_CONTENT]: (state, action) =>
      produce(state, draft => {
        console.log('SAVE_CONTENT..................');
        console.log(action.payload);
        draft.content = action.payload;
        action.payload.docs.forEach((doc, index) => {
          console.log(doc.qnas.length);
          draft.qnasCount = draft.qnasCount + doc.qnas.length;
        });
        if (action.payload.hints && action.payload.hints.length > 0) {
          console.log('make hint_options..........................');
          draft.hint_options = action.payload.hints.map((item, index) => {
            return {text: item.hint_option, index: index};
          });
          console.log(draft.hint_options);
        }
      }),
  },
  initialState,
);

export default contentReducer;
