import React, {useEffect, useState} from 'react';
import {styled, useTheme} from '@mui/material/styles';
import {useDispatch, useSelector} from 'react-redux';
import {
  Box,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';

import {StringUtils} from 'core/utils/StringUtils';
import essayRest from 'core/services/api/essay/essayRest';
import ReactWordCloud from 'react-wordcloud';
import HeapMap from './HeatMap';
import {NumberUtils} from 'core/utils/NumberUtils';
import {DateUtils} from 'core/utils/DateUtils';
import RadarChart from 'view/components/commons/RadarChart';
import BarChart from 'view/components/commons/BarChart';
import ApiLoading from 'view/components/commons/ApiLoading';
import analysisReducer, {saveScores} from 'core/reducer/essay/analysisReducer';
import ErrorPageComponent from 'view/components/commons/ErrorPageComponent';
import {saveStepValues} from 'core/reducer/essay/commonEssayReducer';
import {CoalaConstants} from 'core/utils/CoalaConstants';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ProgressBar from 'view/components/commons/ProgressBar';
import authReducer from 'core/reducer/common/authReducer';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import {grey} from '@mui/material/colors';
import {
  AtGrid,
  AtGridRightEnd,
  ItemBase,
  ItemBaseAtTop,
  RadarDescItem,
  OverallItem,
  stContent2,
  stLabel,
  stLabel2,
  stScore,
  stScore2,
  stScore3,
  TooltipBox,
  coloredStyle,
  CustomWidthTooltip,
} from './AnalysisComps';
import HelpIcon from '@mui/icons-material/Help';
import {result} from 'lodash';
import {
  ColorUtilsAnaly1,
  ColorUtilsLabel1,
  ColorUtilsAnayly2,
  ColorUtilsLabel2,
} from 'core/utils/ColorUtils';
import {AnalysisText} from './AnalysisText';
import information from 'assets/media/icon/information.png';

const StyledTypography = styled(Box)(({theme}) => ({
  paddingTop: '0.75rem',
  fontSize: '28px',
  fontWeight: 'bold',
  color: '#fff',
}));

const Item = styled(Box)(({theme}) => ({
  backgroundColor: '#fff',
}));

const TitleItem = styled(Box)(({theme}) => ({
  textAlign: 'center',
  border: '1px solid #578634',
  lineHeight: '1.5rem !important',
  borderRadius: '7px',
}));

const PdfContainer = styled(Container)(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: '920px',
  },
  paddingBottom: '2rem',
}));

const analysisDesc_1 =
  '문장을 자연스럽게 구성하는 능력이 탁월해요. 문장력이 뛰어난 작가들의 작품을 꾸준히 읽는 습관을 들이면 더 좋을 거예요.';
const analysisDesc_2 =
  '독자가 이해하기 쉽고 읽기 편한 글을 탁월하게 구성했어요. 다양한 장르의 글을 지속적으로 쓰는 훈련을 하면 더 좋을 거예요';
const analysisDesc_3 =
  '주제를 이해하고 분석하는 능력이 뛰어나 자신의 생각이 잘 드러나도록 명료하게 표현했어요. 의미가 모호한 표현을 피하고 간결하게 꾸준히 글 쓰는 훈련을 하면 더 좋을 거예요.';
const analysisDesc_4 =
  '전달하려는 주제에 대해 다양한 어휘로 풍부하게 표현하는 능력이 뛰어나요. 다양한 주제로 꾸준히 글쓰기 훈련을 하면 더 좋을 거예요.';
const analysisDesc_5 =
  '또래에 비해 사용하는 어휘의 수준이 높고 어휘를 선택하는 능력이 탁월해요. 다양한 어휘를 사용하여 자신의 생각을 구체적으로 표현하는 연습을 꾸준히 하면 더 좋을 거예요.';

const CircleBox = styled(Box)(({theme}) => ({
  width: '20px',
  height: '20px',
  borderRadius: '50%',
  backgroundColor: '#ffffff',
  boxShadow:
    '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  margin: '1.25rem 1rem 0.5rem 1.6rem',
}));

const wordCloudOptions = {
  // colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
  // enableTooltip: true,
  deterministic: false,
  fontFamily: 'roboto',
  fontSizes: [5, 60],
  fontStyle: 'normal',
  fontWeight: '600',
  padding: 1,
  rotations: 2,
  rotationAngles: [0, 90],
  scale: 'sqrt',
  spiral: 'archimedean',
  transitionDuration: 1000,
};

const StepAnalysis = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [hm, setHm] = useState([]);
  const [wc, setWc] = useState([]);
  const [keewiWc, setKeewiWc] = useState([]);
  const [paragraphs, setParagraph] = useState(null);
  const [score, setScore] = useState({
    totalScore: 0,
    kRead: 0,
    kReadText: '',
    score: 0,
    totalNumChars: 0,
    totalNumParas: 0,
    numSesByPara: 0,
    numWordsPerSe: 0,
    numDiffWords: 0,
    totalNumToned: 0,
    totalNumPredicate: 0,

    radarData1: 0,
    radarData2: 0,
    radarData3: 0,
    radarData4: 0,
    radarData5: 0,

    apprPrezPercent: 0,
    shortSeRatio: 0,
    ceExpr: 0,
    ceClarity: 0,
    ceAbundance: 0,
    ceStructure: 0,
    ceDiversity: 0,
    overall_msg: '',
    score_1_msg: '',
    score_2_msg: '',
    score_3_msg: '',
    score_4_msg: '',
    score_5_msg: '',
    hardword: '',
    ner_result: {
      msg: '',
      nes: {},
    },
  });
  const [keewiScore, setKeewiScore] = useState({
    aes_score: 0,
    keewi_score: 0,
    paragraph_texts: [],
    trait_1_comment: [],
    trait_1_score: [],
    trait_2_comment: [],
    trait_2_score: [],
    trait_3_comment: [],
    trait_3_score: [],
    trait_4_comment: [],
    trait_4_score: [],
    trait_5_comment: [],
    trait_5_score: [],
    trait_6_comment: [],
    trait_6_score: [],
    type: '',
    sameWordRatio: 0,
    avgWordLength: 0,
    sentenceInParagraph: 0,
    wordInParagraph: 0,
    erroredSentence: 0,
    totalCharacters: 0,
    totalSentences: 0,
    totalParagraphs: 0,
    smoothFlowRatio: 0,
  });
  const [typeText, setTypeText] = useState('');
  const [labelText, setLabelText] = useState('');
  const [analyText, setAnalyText] = useState('');
  const [commentText, setCommentText] = useState('');
  const [keewiLoading, setKeewiLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [radarData, setRadarData] = useState({
    labels: [],
    datasets: [],
  });
  const [barData, setBarData] = useState({
    labels: [],
    datasets: [],
  });

  const commonEssayReducer = useSelector(state => state.commonEssayReducer);
  const contentReducer = useSelector(state => state.contentReducer);
  const writingReducer = useSelector(state => state.writingReducer);
  const analysisReducer = useSelector(state => state.analysisReducer);

  useEffect(() => {
    console.log('teacherScore = ' + analysisReducer.teacherScore);
    const fetchAnalysisPage = async text => {
      // const review = '바야흐로 인문학에 대한 관심이 나날이 높아지고 있습니다.';
      console.log('StepAnalysis');
      console.log(text);
      console.log(contentReducer);
      const params = {
        age: commonEssayReducer.age,
        text: text,
        cid: commonEssayReducer.selUserSettings.content.value,
        mode: commonEssayReducer.selUserSettings.mode,
        ref_text: contentReducer.content.summary,
        teacher: analysisReducer.teacherScore,
      };
      try {
        const res = await essayRest.analysisPage(params);
        setAnalysisInfoFromApiResult(res);
        dispatch(
          saveStepValues({
            clickableCompletedStepsArr: [
              {index: CoalaConstants.STEP_INDEX_SUBMIT, value: true},
            ],
          }),
        );
      } catch (err) {
        dispatch(
          saveStepValues({
            clickableCompletedStepsArr: [
              {index: CoalaConstants.STEP_INDEX_SUBMIT, value: false},
            ],
          }),
        );
        console.log(err);
        setError(err);
      } finally {
        console.log('finally essayRest.analysisPage');
      }
    };

    const fetchKeewiAnalysisPage = async text => {
      // const review = '바야흐로 인문학에 대한 관심이 나날이 높아지고 있습니다.';
      console.log('StepKeewiAnalysis');
      console.log(text);
      console.log(contentReducer);
      const params = {
        age: commonEssayReducer.age,
        text: text,
        cid: commonEssayReducer.selUserSettings.content.value,
        mode: commonEssayReducer.selUserSettings.mode,
        ref_text: contentReducer.content.summary,
        teacher: analysisReducer.teacherScore,
      };
      try {
        const res = await essayRest.analysisKeewiPage(params);

        setAnalysisInfoFromKeewiApiResult(res);
        dispatch(
          saveStepValues({
            clickableCompletedStepsArr: [
              {index: CoalaConstants.STEP_INDEX_SUBMIT, value: true},
            ],
          }),
        );
      } catch (err) {
        dispatch(
          saveStepValues({
            clickableCompletedStepsArr: [
              {index: CoalaConstants.STEP_INDEX_SUBMIT, value: false},
            ],
          }),
        );
        console.log(err);
        setError(err);
      } finally {
        console.log('finally essayRest.analysisPage');
      }
    };

    if (StringUtils.isEmpty(writingReducer.writing)) {
      alert('글쓰기 한 내용이 존재하지 않습니다.');
    } else {
      const paraWithoutEmpty = StringUtils.getParaWithoutEmpty(
        writingReducer.writing,
      );
      console.log(paraWithoutEmpty);
      const textWithoutEmptyPara = paraWithoutEmpty.join('\n');
      setParagraph(paraWithoutEmpty);
      console.log(textWithoutEmptyPara);
      setLoading(true);
      setKeewiLoading(true);
      fetchAnalysisPage(textWithoutEmptyPara).then(r => {
        console.log('fetchAnalysisPage');
        setLoading(false);
      });
      fetchKeewiAnalysisPage(textWithoutEmptyPara).then(r => {
        console.log('fetchKeewiAnalysisPage');
        setKeewiLoading(false);
      });
    }
  }, []);

  function setAnalysisInfoFromApiResult(res) {
    console.log(
      'useEffect StepAnalysis----------------------------------------------------',
    );
    console.log(res);
    setHm(res.data.result.hm);
    setWc(res.data.result.wc);
    const score = {
      totalScore: NumberUtils.getAnalysisNumber(res.data.result.score),
      kRead: StringUtils.getFirst(res.data.result.kread),
      kReadText: StringUtils.getGradeText(res.data.result.kread),
      score: NumberUtils.getAnalysisNumber(res.data.result.score),
      totalNumChars: NumberUtils.getAnalysisNumber(res.data.result.전체글자수),
      totalNumParas: NumberUtils.getAnalysisNumber(res.data.result.전체문단수),
      numSesByPara: NumberUtils.getAnalysisNumber(res.data.result.문단별문장수),
      numWordsPerSe: NumberUtils.getAnalysisNumber(
        res.data.result.문장별단어수,
      ),
      numDiffWords: NumberUtils.getAnalysisNumber(res.data.result.어려운단어수),
      totalNumToned: NumberUtils.getAnalysisNumber(res.data.result.총격조사수),
      totalNumPredicate: NumberUtils.getAnalysisNumber(
        res.data.result.총서술어수,
      ),

      radarData1: NumberUtils.getRadarChartNumber(res.data.result.score_1),
      radarData2: NumberUtils.getRadarChartNumber(res.data.result.score_2),
      radarData3: NumberUtils.getRadarChartNumber(res.data.result.score_3),
      radarData4: NumberUtils.getRadarChartNumber(res.data.result.score_4),
      radarData5: NumberUtils.getRadarChartNumber(res.data.result.score_5),

      apprPrezPercent: 64,
      shortSeRatio: 34,
      ceExpr: 30,
      ceClarity: 67,
      ceAbundance: 45,
      ceStructure: 20,
      ceDiversity: 30,

      overall_msg: res.data.result.overall_msg,

      score_1_msg: res.data.result.score_1_msg,
      score_2_msg: res.data.result.score_2_msg,
      score_3_msg: res.data.result.score_3_msg,
      score_4_msg: res.data.result.score_4_msg,
      score_5_msg: res.data.result.score_5_msg,

      hardword: res.data.result.hardword,
      ner_result: res.data.result.ner_result,
    };
    setScore(score);
    // let datasets = [];
    // if (res.data.result.ref_score && res.data.result.ref_score.length > 0) {
    //   datasets = [
    //     {
    //       label: '평균',
    //       data: res.data.result.ref_score,
    //       backgroundColor: 'rgba(57, 117, 13, 0.2)',
    //       borderColor: '#39750d',
    //       borderWidth: 1,
    //     },
    //     {
    //       label: authReducer.name + '회원',
    //       data: [
    //         NumberUtils.getRadarChartNumber(res.data.result.score_1),
    //         NumberUtils.getRadarChartNumber(res.data.result.score_2),
    //         NumberUtils.getRadarChartNumber(res.data.result.score_3),
    //         NumberUtils.getRadarChartNumber(res.data.result.score_4),
    //         NumberUtils.getRadarChartNumber(res.data.result.score_5),
    //       ],
    //       backgroundColor: 'rgba(255, 138, 0, 0.3)',
    //       borderColor: '#ff8a00',
    //       borderWidth: 1,
    //     },
    //   ];
    // } else {
    //   datasets = [
    //     {
    //       label: authReducer.name + '회원',
    //       data: [
    //         NumberUtils.getRadarChartNumber(res.data.result.score_1),
    //         NumberUtils.getRadarChartNumber(res.data.result.score_2),
    //         NumberUtils.getRadarChartNumber(res.data.result.score_3),
    //         NumberUtils.getRadarChartNumber(res.data.result.score_4),
    //         NumberUtils.getRadarChartNumber(res.data.result.score_5),
    //       ],
    //       backgroundColor: 'rgba(255, 138, 0, 0.3)',
    //       borderColor: '#ff8a00',
    //       borderWidth: 1,
    //     },
    //   ];
    // }
    // const newRadarData = {
    //   labels: ['표현력', '가독성', '명료성', '풍부성', '어휘력'],
    //   datasets: datasets,
    // };
    // setRadarData(newRadarData);
    console.log(
      'test = ' + NumberUtils.getRadarChartNumber(res.data.result.score_2),
    );
    const scores = [
      res.data.result.score,
      res.data.result.score_1,
      res.data.result.score_2,
      res.data.result.score_3,
      res.data.result.score_4,
      res.data.result.score_5,
    ];
    dispatch(saveScores(scores));
    console.log('docSim = ' + score.docSim);
  }
  function setAnalysisInfoFromKeewiApiResult(res) {
    console.log(
      'useEffect StepKeewiAnalysis----------------------------------------------------',
    );
    console.log(res);
    setKeewiWc(res.data.result.word_cloud);
    const keewiScore = {
      aes_score: NumberUtils.getAnalysisNumber(res.data.result.aes_score),
      keewi_score: NumberUtils.getAnalysisNumber(res.data.result.keewi_score),
      paragraph_texts: res.data.result.paragraph_texts,
      // trait_1_comment: res.data.result.trait_1_comment,
      // trait_1_score: res.data.result.trait_1_score,
      // trait_2_comment: res.data.result.trait_2_comment,
      // trait_2_score: res.data.result.trait_2_score,
      // trait_3_comment: res.data.result.trait_3_comment,
      // trait_3_score: res.data.result.trait_3_score,
      // trait_4_comment: res.data.result.trait_4_comment,
      // trait_4_score: res.data.result.trait_4_score,
      // trait_5_comment: res.data.result.trait_5_comment,
      // trait_5_score: res.data.result.trait_5_score,
      // trait_6_comment: res.data.result.trait_6_comment,
      // trait_6_score: res.data.result.trait_6_score,
      trait_1_comment: AnalysisText.createWholeComment(
        res.data.result.paragraph_texts,
        res.data.result.trait_1_comment,
        1,
        res.data.result.trait_1_score,
      ),
      trait_2_comment: AnalysisText.createWholeComment(
        res.data.result.paragraph_texts,
        res.data.result.trait_2_comment,
        2,
        res.data.result.trait_2_score,
      ),
      trait_3_comment: AnalysisText.createWholeComment(
        res.data.result.paragraph_texts,
        res.data.result.trait_3_comment,
        3,
        res.data.result.trait_3_score,
      ),
      trait_4_comment: AnalysisText.createWholeComment(
        res.data.result.paragraph_texts,
        res.data.result.trait_4_comment,
        4,
        res.data.result.trait_4_score,
      ),
      trait_5_comment: AnalysisText.createWholeComment(
        res.data.result.paragraph_texts,
        res.data.result.trait_5_comment,
        5,
        res.data.result.trait_5_score,
      ),
      trait_6_comment: AnalysisText.createWholeComment(
        res.data.result.paragraph_texts,
        res.data.result.trait_6_comment,
        6,
        res.data.result.trait_6_score,
      ),
      trait_1_score: AnalysisText.getAlphbeticScore(
        res.data.result.trait_1_score,
      ),
      trait_2_score: AnalysisText.getAlphbeticScore(
        res.data.result.trait_2_score,
      ),
      trait_3_score: AnalysisText.getAlphbeticScore(
        res.data.result.trait_3_score,
      ),
      trait_4_score: AnalysisText.getAlphbeticScore(
        res.data.result.trait_4_score,
      ),
      trait_5_score: AnalysisText.getAlphbeticScore(
        res.data.result.trait_5_score,
      ),
      trait_6_score: AnalysisText.getAlphbeticScore(
        res.data.result.trait_6_score,
      ),

      type: res.data.result.type,
      sameWordRatio: res.data.result.겹치는단어비율,
      avgWordLength: res.data.result.단어의평균길이,
      sentenceInParagraph: res.data.result.문단별문장수,
      wordInParagraph: res.data.result.문장별단어수,
      erroredSentence: res.data.result.분석오류문장수,
      totalCharacters: res.data.result.전체글자수,
      totalSentences: res.data.result.전체문장수,
      totalParagraphs: res.data.result.전체문단수,
      smoothFlowRatio: res.data.result.흐름이자연스러운정도,
    };
    setTypeText(AnalysisText.createTypeText(res.data.result.type));
    setLabelText(AnalysisText.createHtmlTextLabel(res.data.result.type));

    setAnalyText(
      AnalysisText.createHtmlTextAnaly(
        res.data.result.paragraph_texts,
        res.data.result.type,
      ),
    );

    setKeewiScore(keewiScore);
    setCommentText(keewiScore.trait_1_comment);

    let datasets = [];
    datasets = [
      {
        label: '평균',
        data: [5, 5, 5, 5, 5, 5],
        backgroundColor: 'rgba(0, 162, 224, 0.2)',
        borderColor: 'rgba(0, 162, 224, 1)',
        borderWidth: 1,
        pointStyle: 'circle',
        pointBackgroundColor: 'rgba(0, 162, 224, 1)',
        pointBorderColor: 'rgba(255, 255, 255, 0)',
      },
      {
        label: authReducer.name + '회원',
        data: [
          NumberUtils.getRadarChartNumber(res.data.result.trait_1_score),
          NumberUtils.getRadarChartNumber(res.data.result.trait_2_score),
          NumberUtils.getRadarChartNumber(res.data.result.trait_3_score),
          NumberUtils.getRadarChartNumber(res.data.result.trait_4_score),
          NumberUtils.getRadarChartNumber(res.data.result.trait_5_score),
          NumberUtils.getRadarChartNumber(res.data.result.trait_6_score),
        ],
        backgroundColor: 'rgba(255, 138, 0, 0.3)',
        borderColor: '#ff8a00',
        borderWidth: 1,
        pointStyle: 'circle',
        pointBackgroundColor: 'rgba(255, 138, 0, 1)',
        pointBorderColor: 'rgba(255, 255, 255, 0)',
      },
    ];

    const newRadarData = {
      labels: ['문법', '어휘', '표현', '문장', '조직', '주제'],
      datasets: datasets,
    };
    setRadarData(newRadarData);

    const barData = {
      labels: ['단어', '문장'],
      datasets: [
        {
          data: [15, 21],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            // 'rgba(255, 205, 86, 0.2)',
            // 'rgba(75, 192, 192, 0.2)',
            // 'rgba(54, 162, 235, 0.2)',
            // 'rgba(153, 102, 255, 0.2)',
            // 'rgba(201, 203, 207, 0.2)',
          ],
          borderColor: [
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            // 'rgb(255, 205, 86)',
            // 'rgb(75, 192, 192)',
            // 'rgb(54, 162, 235)',
            // 'rgb(153, 102, 255)',
            // 'rgb(201, 203, 207)',
          ],
          borderWidth: 1,
          barThickness: 30,
        },
      ],
    };
    setBarData(barData);
  }
  function createKeewiScoreText(type, score) {
    if (score == '분석불가') {
      return (
        <>
          <span style={stLabel2}>{type}</span>
          <span style={stContent2}>
            <span style={stScore2}>{score}</span>
          </span>
        </>
      );
    }
    return (
      <>
        <span style={stLabel2}>{type}</span>
        <span style={stContent2}>
          <span style={stScore3}>{score}</span>
        </span>
      </>
    );
  }
  if (loading || keewiLoading)
    return <ApiLoading loading={loading || keewiLoading} />;

  if (error) return <ErrorPageComponent err={error} />;

  console.log('==============================================');
  console.log(radarData);

  function pdfDown() {
    console.log('pdfDown');
    const element = document.getElementById('pdfCanvas');
    window
      .html2pdf()
      .from(element)
      .set({
        margin: 10,
        filename: '분석하기.pdf',
        html2canvas: {scale: 1},
        jsPDF: {
          orientation: 'portrait',
          unit: 'mm',
          format: 'a4',
          compressPDF: true,
        },
      })
      .save();
  }

  const downloadPdfDocument = () => {
    const element = document.getElementById('pdfCanvas');
    html2canvas(element, {
      allowTaint: true,
      useCORS: true,
      logging: false,
      height: window.outerHeight + window.innerHeight,
      windowHeight: window.outerHeight + window.innerHeight,
      BackgroundColor: '#FFFFFF',
      // scale: 1
    }).then(canvas => {
      const imgData = canvas.toDataURL('image/jpeg');
      const imgWidth = 190;
      // const pageHeight = imgWidth * 1.414;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const margin = 10;

      const pdf = new jsPDF('p', 'mm', 'a4');
      let position = 0;

      pdf.addImage(imgData, 'jpeg', margin, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'jpeg', margin, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save('download.pdf');
    });
  };

  function hoverRadar(evt, element) {
    if (element.length > 0) {
      console.log(element[0]);
      console.log(
        'datasetindex:' +
          element[0].datasetIndex +
          ', index:' +
          element[0].index,
      );
      console.log(
        radarData.datasets[element[0].datasetIndex].data[element[0].index],
      );

      if (element[0].datasetIndex == 0) {
        return;
      }
      if (element[0].index == 0) {
        setCommentText(keewiScore.trait_1_comment);
      } else if (element[0].index == 1) {
        setCommentText(keewiScore.trait_2_comment);
      } else if (element[0].index == 2) {
        setCommentText(keewiScore.trait_3_comment);
      } else if (element[0].index == 3) {
        setCommentText(keewiScore.trait_4_comment);
      } else if (element[0].index == 4) {
        setCommentText(keewiScore.trait_5_comment);
      } else {
        setCommentText(keewiScore.trait_6_comment);
      }
    }
  }

  return (
    <PdfContainer>
      {/*<Box sx={{ textAlign: 'right' }}>*/}
      {/*  <button onClick={downloadPdfDocument}>PDF 다운로드</button>*/}
      {/*</Box>*/}

      <Box
        id={'pdfCanvas'}
        pt={0}
        mt={2}
        sx={{
          // minHeight: 'calc(100vh - 312px) !important',
          paddingBottom: '30px',
          backgroundColor: '#f5eeda',
          padding: '40px 30px 40px 30px',
          borderRadius: '7px',
          boxShadow:
            '-3px 6px 4px 0 rgba(0, 0, 0, 0.25), 3px 4px 4px 0 rgba(0, 0, 0, 0.25)',
        }}
      >
        <Grid container spacing={0} pb={2}>
          <Grid item xs={12} md={2} pr={1}>
            <ItemBaseAtTop
              sx={{
                lineHeight: '47px !important',
                backgroundColor: '#608445',
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '0px',
                color: '#fff',
              }}
            >
              학년
            </ItemBaseAtTop>
            <ItemBase
              sx={{
                lineHeight: '51px !important',
                borderTopLeftRadius: '0px',
                borderTopRightRadius: '0px',
                color: '#5b6f4d',
                fontSize: '25px',
              }}
            >
              {contentReducer.content.level}
            </ItemBase>
          </Grid>
          <Grid item xs={12} md={10} pl={1} sx={{height: '47px !important'}}>
            <ItemBaseAtTop
              sx={{
                lineHeight: '47px !important',
                backgroundColor: '#608445',
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '0px',
                color: '#fff',
              }}
            >
              제목
            </ItemBaseAtTop>
            <ItemBase
              sx={{
                lineHeight: '51px !important',
                borderTopLeftRadius: '0px',
                borderTopRightRadius: '0px',
                color: '#5b6f4d',
                fontSize: '25px',
              }}
            >
              {contentReducer.content.mission}
            </ItemBase>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ItemBase sx={{height: '124px'}}>
              <Box
                textAlign={'center'}
                style={{
                  fontSize: '30px',
                  color: '#f67f10',
                  marginTop: '1.45rem',
                }}
              >
                Writing Power
              </Box>
              <Box
                style={{
                  marginTop: '1.5rem',
                  color: '#5b6f4d',
                  fontSize: '28px',
                }}
              >
                {keewiScore.keewi_score}
              </Box>
            </ItemBase>
          </Grid>
          <Grid container item xs={12}>
            <AtGrid item xs={6} md={2}>
              <OverallItem
                sx={{
                  borderTopLeftRadius: '8px',
                  borderBottomLeftRadius: '8px',
                }}
              >
                <Box
                  mt={0}
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: '24px',
                  }}
                >
                  총평
                </Box>
              </OverallItem>
            </AtGrid>
            <AtGridRightEnd item xs={6} md={10}>
              <RadarDescItem
                sx={{
                  textAlign: 'left',
                  borderTopRightRadius: '8px',
                  borderBottomRightRadius: '8px',
                  padding: '0.75rem 1rem',
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
                className={'dlg-content-box'}
              >
                {score.overall_msg}
              </RadarDescItem>
            </AtGridRightEnd>
          </Grid>
          <Grid item xs={12}>
            <ItemBase
              pt={4}
              pb={5}
              pr={0}
              pl={5}
              sx={{
                //paddingBottom: '2rem',
                textAlign: 'center',
              }}
            >
              <Box pb={4} fontSize={'21px'} fontWeight={'bold'}>
                내가 쓴 글의 분석 결과예요.
              </Box>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  sx={{
                    lineHeight: '3rem',
                  }}
                >
                  <Box
                    pl={6}
                    sx={{
                      lineHeight: '1.5rem',
                    }}
                  >
                    <span
                      style={{
                        display: 'inline-block',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fontSize: '20px',
                      }}
                    >
                      <span
                        style={{
                          color: '#00A2E0',
                          textAlign: 'left',
                        }}
                      >
                        KReaD
                      </span>
                      <span> 지수</span>
                    </span>
                    <span style={stContent2}>
                      <span style={stScore2}>{score.kRead}</span>
                    </span>
                  </Box>
                  <Box
                    mt={1}
                    pl={3}
                    // pl={7}
                    pb={4}
                    sx={{
                      lineHeight: '1.5rem',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'normal',
                        fontSize: '16px',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {score.kReadText}
                    </span>
                  </Box>
                  <Box pl={8} pr={8}>
                    <BarChart
                      barData={barData}
                      width={'180px'}
                      //height={'30px'}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    lineHeight: '3rem',
                    margin: 'auto',
                    //paddingTop: '0.5rem',
                  }}
                >
                  <Box>
                    <span style={stLabel}>전체 글자 수</span>
                    <span style={stContent2}>
                      <span style={stScore}>{score.totalNumChars}</span> 자
                    </span>
                  </Box>
                  <Box>
                    <span style={stLabel}>전체 문단 수</span>
                    <span style={stContent2}>
                      <span style={stScore}>{score.totalNumParas}</span> 개
                    </span>
                  </Box>
                  <Box>
                    <span style={stLabel}>문단별 문장 수</span>
                    <span style={stContent2}>
                      <span style={stScore}>{score.numSesByPara}</span> 개
                    </span>
                  </Box>
                  <Box>
                    <span style={stLabel}>문장별 단어 수</span>
                    <span style={stContent2}>
                      <span style={stScore}>{score.numWordsPerSe}</span> 개
                    </span>
                  </Box>
                  <Box>
                    <span style={stLabel}>어려운 단어 수</span>
                    <span style={stContent2}>
                      <span style={stScore}>{score.numDiffWords}</span> 개
                    </span>
                  </Box>
                </Grid>
              </Grid>
            </ItemBase>
          </Grid>
          <Grid item xs={6}>
            <ItemBase
              sx={{
                height: '460px',
                textAlign: 'center',
                fontSize: '21px',
              }}
            >
              <Box p={4}>이런 단어로 글을 썼어요.</Box>
              <Box
                sx={{
                  margin: 'auto',
                  marginBottom: '1rem',
                  width: '300px !important',
                  height: '360px !important',
                }}
              >
                {wc.length > 0 && (
                  <ReactWordCloud options={wordCloudOptions} words={wc} />
                )}
              </Box>
            </ItemBase>
          </Grid>
          <Grid
            item
            xs={6}
            //md={6}
          >
            <ItemBase
              sx={{
                minHeight: '225px',
                textAlign: 'center',
                fontSize: '21px',
                fontWeight: 'bold',
                marginBottom: '8px',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              <Box pt={4}>나이에 비해 어려운 단어예요.</Box>
              <Box
                style={{
                  padding: '30px',
                  color: '#5b6f4d',
                  fontSize: '20px',
                  fontWeight: 'normal',
                  lineHeight: '2.25rem',
                  whiteSpace: 'normal',
                  wordBreak: 'break-all',
                }}
              >
                {score.hardword}
              </Box>
              <Box></Box>
            </ItemBase>
            <ItemBase
              sx={{
                minHeight: '225px',
                textAlign: 'center',
                //paddingBottom: '2rem',
                fontSize: '21px',
                fontWeight: 'bold',
              }}
            >
              <Box pt={4}>{score.ner_result.msg}</Box>
              <Box
                style={{
                  paddingTop: '1.55rem',
                  color: '#5b6f4d',
                  fontSize: '20px',
                  fontWeight: 'normal',
                }}
              >
                <Box
                  pt={1}
                  sx={{
                    paddingBottom: '2rem',
                    paddingLeft: '5rem',
                    textAlign: 'left',
                  }}
                >
                  <ul className={'analysis-info'}>
                    {Object.keys(score.ner_result.nes).map((nesKey, index) => {
                      return (
                        <li key={index}>
                          <span>{nesKey}</span> :{' '}
                          <span>{score.ner_result.nes[nesKey].join(', ')}</span>
                        </li>
                      );
                    })}
                  </ul>
                </Box>
              </Box>
            </ItemBase>
          </Grid>
          <Grid item xs={12}>
            <ItemBase p={5} sx={{textAlign: 'left'}}>
              <Box
                pb={2}
                style={{
                  //margin: 'auto',
                  //padding: 20,
                  fontSize: '21px',
                  fontWeight: 'bold',
                }}
              >
                AI가 내 글을 문장 종류에 따라 색깔로 구분했어요.
              </Box>
              <Box>{labelText}</Box>
              <Box
                mt={1}
                style={{fontWeight: 'normal', color: 'rgba(48, 48, 48, 1)'}}
              >
                {analyText}
              </Box>
            </ItemBase>
            <ItemBase mt={2.5} sx={{textAlign: 'left'}}>
              <Box p={5}>{typeText}</Box>
            </ItemBase>
          </Grid>
          <Grid item xs={12}>
            <ItemBase p={5}>
              <Box textAlign={'center'} fontSize={'21px'}>
                내가 쓴 글의 지표별 분석 결과예요.
              </Box>
              <Box
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 0)',
                  color: 'rgba(48, 48, 48, 1)',
                  //border: '1px solid #FFAD33',
                  display: 'inline-block',
                  fontSize: '12px',
                  wordBreak: 'keep-all',
                }}
              >
                차트의 각 지표를 누르면 지표별 점수와 분석 상세를 확인할 수
                있습니다.
              </Box>

              {radarData.datasets.length > 0 && (
                <>
                  <Box
                    sx={{
                      margin: 'auto',
                      //width: '420px',
                      // height: '350px',
                      borderRadius: '8px',
                      paddingLeft: '0 !important',
                    }}
                  ></Box>
                  <Grid container>
                    <Grid item xs={8}>
                      <RadarChart
                        radarData={radarData}
                        width={'420px'}
                        height={'350px'}
                        hoverEvent={hoverRadar}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        lineHeight: '2rem',
                        margin: 'auto',
                      }}
                    >
                      <Box>
                        {createKeewiScoreText(
                          '문법의 정확성',
                          keewiScore.trait_1_score,
                        )}
                      </Box>
                      <Box>
                        {createKeewiScoreText(
                          '어휘의 풍부성',
                          keewiScore.trait_2_score,
                        )}
                      </Box>
                      <Box>
                        {createKeewiScoreText(
                          '의도의 표현',
                          keewiScore.trait_3_score,
                        )}
                      </Box>
                      <Box>
                        {createKeewiScoreText(
                          '문장 구사력',
                          keewiScore.trait_4_score,
                        )}
                      </Box>
                      <Box>
                        {createKeewiScoreText(
                          '조직과 전개',
                          keewiScore.trait_5_score,
                        )}
                      </Box>
                      <Box>
                        {createKeewiScoreText(
                          '주제의 명확성',
                          keewiScore.trait_6_score,
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            </ItemBase>
          </Grid>
          <Grid item xs={12}>
            <ItemBase p={3}>{commentText}</ItemBase>
          </Grid>
        </Grid>

        <ApiLoading loading={loading || keewiLoading} />
      </Box>
    </PdfContainer>
  );
};

export default StepAnalysis;
