export const NumberUtils = {
  getAnalysisNumber: function (num = 0) {
    const temp1 = num * 100;
    const temp2 = Math.round(temp1);
    return temp2 / 100;
  },

  // formatNumber: function (number = undefined, formatType = '') {
  //   if (isNaN(number)) {
  //     return number;
  //   }
  //
  //   let numberString = String(number).replace(/,/g, '');
  //   return numberString
  //     .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // }

  getRadarChartNumber: function (num = 0) {
    // const temp1 = num * 10;
    // const temp2 = Math.round(temp1);
    // return temp2 / 10;
    return num.toFixed(0);
  },
};
