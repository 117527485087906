import {createLogger} from 'redux-logger/src';
import {applyMiddleware, compose, createStore} from 'redux';
import ReduxThunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import rootReducer from '../reducer';
import {persistStore} from 'redux-persist';

const logger = createLogger();
const enhancer =
  process.env.NODE_ENV === 'production'
    ? compose(applyMiddleware(ReduxThunk))
    : composeWithDevTools(applyMiddleware(logger, ReduxThunk));
export const store = createStore(rootReducer, enhancer);
export const persistor = persistStore(store);

export default {store, persistor};
