import React from 'react';
import SigninForm from 'view/components/auth/SigninForm';
import {Box} from '@mui/material';

const Signin = () => {
  return (
    <Box
      sx={{
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
      }}
    >
      <SigninForm />
    </Box>
  );
};

export default Signin;
