import React, {useEffect, useRef, useState} from 'react';
import essayRest from 'core/services/api/essay/essayRest';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  InputAdornment,
  Button,
} from '@mui/material';
import {saveHint} from 'core/reducer/essay/structureReducer';
import SearchResults from './SearchResults';
import WritingCards from './WritingCards';
import {CoalaConstants} from 'core/utils/CoalaConstants';
import SearchIcon from '@mui/icons-material/Search';
import ApiLoading from 'view/components/commons/ApiLoading';
import {ValidatorForm} from 'react-material-ui-form-validator';
import {StringUtils} from 'core/utils/StringUtils';

import {
  CourseTitleWrapper,
  GreenDashBox,
  LeftWhiteBox,
  MissionBox,
  QnaTitleBox,
  StructHintSelect,
  StructSearch,
  StructSearchPaper,
} from '../CourseComps';
import {
  DlgActionsCmm,
  DlgCmm,
  DlgContentCmm,
} from 'view/components/commons/DialogComps';

const StepStructure = () => {
  const dispatch = useDispatch();
  const inputKeywordRef = useRef();

  const [loading, setLoading] = useState(false);

  const commonEssayReducer = useSelector(state => state.commonEssayReducer);
  const contentReducer = useSelector(state => state.contentReducer);
  const structureReducer = useSelector(state => state.structureReducer);

  const [openHintDlg, setOpenHintDlg] = useState(false);
  const [writing, setWriting] = useState({tempSelectedHintIndex: 0});
  const [searchResults, setSearchResults] = useState([]);
  const [keyword, setKeyword] = useState('');

  useEffect(async () => {
    console.log('StepStructure useEffect...............');

    console.log(structureReducer.structureBoxes);
    console.log(
      'structureReducer.structureBoxes.length === ' +
        structureReducer.structureBoxes.length,
    );
    await handleClickSearch(contentReducer.content.search);
  }, []);

  // Hint ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleClickOpenHint = e => {
    console.log(e.target);
    setWriting(prevState => ({
      ...prevState,
      tempSelectedHintIndex: e.target.value,
    }));
    console.log(writing.tempSelectedHintIndex);
    setOpenHintDlg(true);
  };

  const handleCloseChangeHint = e => {
    const newStructureBoxes = [];
    contentReducer.content.hints[
      writing.tempSelectedHintIndex
    ].hint_paragraphs.map((hint_para, index) => {
      newStructureBoxes.push({
        noid: index,
        title: hint_para,
        text: '',
        collapsed: false,
        boxIndex: index,
      });
    });

    dispatch(
      saveHint({
        selectedHintIndex: writing.tempSelectedHintIndex,
        structureBoxes: newStructureBoxes,
      }),
    );
    setOpenHintDlg(false);
  };

  const handleCloseCancelHint = e => {
    setOpenHintDlg(false);
  };

  const handleClickSearch = async searchWord => {
    setLoading(true);
    const res = await essayRest
      .getStructureSearch({
        age: commonEssayReducer.age,
        count: CoalaConstants.DEFAULT_COUNT,
        text: searchWord,
      })
      .finally(() => {
        setLoading(false);
      });
    console.log(res);
    setSearchResults(res.data.results);
  };

  function handleClickSearchEvent(e) {
    console.log(e);
    if (StringUtils.isEmpty(keyword) || StringUtils.isEmpty(keyword.trim())) {
      alert('검색어를 입력하세요.');
      inputKeywordRef.current.focus();
      return;
    }

    handleClickSearch(keyword).then(r => {
      console.log(r);
    });
  }

  function handleChangeKeyword(event) {
    const {name, value} = event.target;
    setKeyword(value);
  }

  return (
    <CourseTitleWrapper>
      <Grid container spacing={4} mb={3}>
        <Grid item xs={12} sx={{paddingTop: '16px !important'}}>
          <MissionBox>{contentReducer.content.mission}</MissionBox>
          <QnaTitleBox>{contentReducer.content.hint_title}</QnaTitleBox>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} md={8} sx={{paddingTop: '16px !important'}}>
          {commonEssayReducer.selUserSettings.mode === CoalaConstants.HINT && (
            <Box pb={1} mb={1} sx={{textAlign: 'right'}}>
              <FormControl m={1}>
                <StructHintSelect
                  value={structureReducer.selectedHintIndex}
                  onChange={handleClickOpenHint}
                >
                  {contentReducer.hint_options.map((item, index) => (
                    <MenuItem
                      key={item.index}
                      value={item.index}
                      sx={{
                        fontSize: '0.95rem !important',
                      }}
                    >
                      {item.text}
                    </MenuItem>
                  ))}
                </StructHintSelect>
              </FormControl>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={4} mb={2}>
          <Box
            sx={{
              position: 'relative',
              height: '100%',
            }}
          >
            <StructSearchPaper elevation={0}>
              <ValidatorForm onSubmit={handleClickSearchEvent}>
                <FormControl
                  sx={{m: 0, width: '100%', fontSize: '0.95rem !important'}}
                  variant="outlined"
                >
                  <StructSearch
                    size="small"
                    name="inputSearch"
                    // label="검색어"
                    value={keyword}
                    onChange={handleChangeKeyword}
                    fullWidth
                    placeholder="글상자에 쓴 내용과 관련된 검색어를 입력하세요."
                    validators={['required']}
                    errorMessages={['검색어를 입력하세요.']}
                    ref={inputKeywordRef}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                            type="submit"
                          >
                            {/*<SearchIcon onClick={handleClickSearchEvent} />*/}
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </ValidatorForm>
            </StructSearchPaper>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <LeftWhiteBox>
            <WritingCards />
          </LeftWhiteBox>
        </Grid>
        <Grid item xs={12} md={4}>
          <GreenDashBox>
            <SearchResults searchResults={searchResults} />
          </GreenDashBox>
        </Grid>
      </Grid>

      <DlgCmm
        open={openHintDlg}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DlgContentCmm>
          <Box>힌트를 바꾸면 작성한 내용이 모두 사라져요.</Box>
          <Box>힌트를 바꿀까요?</Box>
        </DlgContentCmm>
        <DlgActionsCmm>
          <Button
            variant="outlined"
            color="warning"
            onClick={handleCloseCancelHint}
          >
            취소하기
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={handleCloseChangeHint}
            autoFocus
          >
            바꾸기
          </Button>
        </DlgActionsCmm>
      </DlgCmm>

      <ApiLoading loading={loading} />
    </CourseTitleWrapper>
  );
};

export default StepStructure;
