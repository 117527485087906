//  comment_dict[ 1~6 ][ 1~9 ][ 'context' or 'detail' ]
//            [ 1: 문법, 2: 어휘, 3: 표현, 4: 문장, 5: 구조, 6: 구성]
//                [ 각 grade 1 ~ 9 (F ~ A+) ]
//                            [ 대표설명, 세부설명 ]
export const AnalysisConstants = {
  1: {
    9: {
      context:
        '맞춤법 등 국어 규범을 잘 숙지하고 있으며, 이를 잘 준수하여 규범에 맞는 글을 쓰는 능력을 갖췄습니다.',
      detail:
        '맞춤법, 철자법, 띄어쓰기, 단락 나누기 등의 문법 규칙에 대한 이해가 매우 뛰어납니다. ',
    },
    8: {
      context: '맞춤법 등 국어 규범을 숙지하고 있습니다. ',
      detail:
        '맞춤법, 철자법, 띄어쓰기, 단락 나누기 등의 문법 규칙에 대한 이해가 상당히 뛰어납니다. 하지만 글에 종종 문법적 오류가 보이는 상태로 실수를 줄이는 연습이 필요합니다.',
    },
    7: {
      context:
        '맞춤법 등 국어 규범을 잘 숙지하고 있으며, 이를 잘 준수하여 규범에 맞는 글을 쓰는 능력을 갖췄습니다.',
      detail:
        '맞춤법, 철자법, 띄어쓰기, 단락 나누기 등의 문법 규칙에 대한 이해가 상당히 뛰어납니다. 하지만 글에 종종 문법적 오류가 보이는 상태로 맞춤법에 맞는 낱말에 대한 연습이 필요합니다.',
    },
    6: {
      context:
        '국어 문법 규칙을 잘 준수하여 규범에 맞는 글을 쓰는 능력을 어느 정도 갖췄습니다.',
      detail:
        '맞춤법, 철자법, 띄어쓰기, 단락 나누기 등의 문법 규칙을 이해하고 있습니다. 맞춤법 실수를 줄이기 위한 학습이 필요합니다. ',
    },
    5: {
      context:
        '국어 문법 규칙을 준수하여 규범에 맞는 글을 쓰는 능력을 갖췄습니다. 하지만 정확한 의사소통을 위해서는 규범에 대한 학습이 필요합니다.',
      detail:
        '맞춤법, 철자법, 띄어쓰기, 단락 나누기 등의 문법 규칙에 대해 이해하고 있습니다. 다만 글에 적용하는 데에 연습이 필요합니다. 문법의 정확성을 정확히 알고, 꾸준한 글쓰기를 실천해 보세요.',
    },
    4: {
      context:
        '국어 문법 규칙을 일부 준수하여 규범에 맞는 글을 쓰는 기본적인 능력을 갖추고 있습니다.',
      detail:
        '맞춤법, 철자법, 띄어쓰기, 단락 나누기 등의 문법 규칙에 대해 기초적인 수준의 이해를 하고 있습니다. 국어 규범을 지키지 않은 글은 오해를 불러일으키거나 제대로 된 의사 전달이 어렵습니다. 자주 틀리는 문법에 관한 공부가 필요합니다.',
    },
    3: {
      context:
        '국어 문법 규칙을 정확히 알고 있지 않습니다. 문법적 오류로 인해 글을 이해하기가 상당히 어려운 수준입니다.',
      detail:
        '국어 규범에 대한 이해가 굉장히 부족합니다. 국어의 규범은 한글 맞춤법, 표준어 규정, 표준어 발음법, 외래어 표기법, 국어의 로마자 표기법 등 국어 사용에 필요한 규범을 말합니다. 문법을 정확하게 지키는 방법을 알고, 꾸준한 글쓰기를 통해 연습하여야 합니다.',
    },
    2: {
      context:
        '국어 문법 규칙을 거의 준수하지 못하여 규범에 맞는 글을 쓰는 능력을 갖추지 못한 상태입니다.',
      detail:
        '맞춤법, 문법, 띄어쓰기, 단락 나누기 규칙과 같은 문법에 대한 이해가 매우 부족합니다. 이러한 문법적 오류로 인해 글을 이해하기가 굉장히 어려운 수준입니다. 국어 규범에 대한 꾸준한 학습과 연습이 필요한 상태입니다.',
    },
    1: {
      context:
        '국어 문법 규칙을 잘 알지 못하고 있습니다. 글을 통한 의사 전달에 어려움이 있습니다.',
      detail:
        '"맞춤법, 문법, 띄어쓰기, 단락 나누기 규칙과 같은 문법에 대한 이해가 매우 부족합니다. 국어 규범은 국어로 글을 작성하는 사람들 사이의 약속입니다. 이를 지키지 않은 글은 오해를 불러일으키거나 제대로 된 의사 전달이 어렵습니다. ',
    },
    0: {
      context:
        '정확한 국어 규범에 대한 학습과 연습이 필요하며, 꾸준하게 진행되어야 합니다. ',
    },
  },
  2: {
    9: {
      context:
        '다양한 어휘를 내용과 의미에 맞춰 정확하게 활용하는 능력을 갖췄습니다.',
      detail:
        '전달하고자 하는 내용을 표현하기 위해 풍부한 어휘를 적재적소에 사용하고 있습니다. ',
    },
    8: {
      context: '어휘를 내용과 의미에 맞춰 정확하고 다양하게 활용하고 있습니다.',
      detail:
        '다양한 어휘를 적재적소에 활용하여 내용 전달을 효과적으로 전달하고 있습니다. 어휘를 다양하게 사용하는 글은 독자를 지루하게 하지 않으며, 정확한 이해에도 도움을 줍니다.',
    },
    7: {
      context:
        '다양한 어휘 활용하여 전달하고자 하는 내용을 표현하는 방법을 알고 있습니다. 꾸준한 어휘 학습을 추천합니다.',
      detail:
        '다양한 어휘를 정확하게 활용하여 글을 쓰는 것은, 글의 힘을 더합니다. 글의 내용을 명확하게 전달하고 독자의 이해를 돕는 방법을 꾸준히 연습하세요.',
    },
    6: {
      context:
        '다양한 어휘를 내용과 의미에 맞춰 활용하는 것의 중요성을 숙지하고 있습니다.',
      detail:
        '전달하고자 하는 내용을 표현하기 위해 다양한 어휘를 알맞은 곳에 사용하는 방법을 알고 있습니다. 독자는 같은 단어가 반복되는 글을 지루해합니다. 또한 의미가 맞지 않는 단어를 다양하게 사용하는 것은 정확한 내용 이해를 방해합니다. ',
    },
    5: {
      context:
        '다양한 어휘를 내용과 의미에 맞춰 활용하는 것의 중요성을 숙지하고 있습니다. 하지만 다양한 어휘를 사용하는 능력이 다소 부족합니다. ',
      detail:
        '다양한 어휘를 알맞은 곳에 사용하는 방법을 알고 있습니다. 다만 이를 글에서 효율적으로 보여주지 못해 독자가 글의 메시지를 파악하는 데 어려움을 줄 수 있습니다.',
    },
    4: {
      context:
        '글쓰기에서 다양한 어휘를 내용과 의미에 맞춰 활용하는 것이 중요하다는 사실을 이해하고 있습니다.',
      detail:
        '다양한 어휘에 대한 중요성은 이해하고 있지만, 이를 알맞은 곳에 사용하는 방법을 숙지하지 못하고 있습니다. 적절하지 않은 단어를 사용하는 것은 정확한 내용 이해를 방해합니다. 정확한 어휘를 사용하는 연습을 추천합니다.',
    },
    3: {
      context:
        '사용하고 있는 어휘의 양이 한정적입니다. 어휘를 활용할 수 있는 능력이 다소 부족합니다.',
      detail:
        '다양한 어휘를 활용하여 글의 내용을 명확하게 전달하는 것이 어려워 보입니다. 또한 다양한 어휘를 사용했더라도, 의미가 맞지 않게 사용한다면 전달하고자 하는 내용이 왜곡될 수 있습니다. 글에 맞는 어휘를 사용하는 연습이 필요합니다.',
    },
    2: {
      context:
        '다양한 어휘를 내용과 의미에 맞춰 활용하는 능력을 거의 갖추지 못한 상태입니다.',
      detail:
        '사용할 수 있는 어휘의 양이 상당히 한정적인 것으로 보입니다. 다양한 어휘를 활용하여 글의 내용을 명확하게 전달하는 것이 어려운 상태입니다. 이로 인해 독자가 글의 메시지를 쉽게 파악할 수 없는 수준입니다. 알고 있는 어휘가 정확한 의미가 맞는지 다시 확인할 필요가 있습니다. ',
    },
    1: {
      context:
        '다양한 어휘를 내용과 의미에 맞춰 활용하는 능력을 전혀 갖추지 못한 상태입니다.',
      detail:
        '사용하고 있는 어휘의 양이 매우 한정적이고, 어휘의 의미를 정확하게 알고 있는지에 대한 확인이 필요합니다. ',
    },
    0: {
      context:
        '의미가 맞지 않는 단어를 사용하는 것은 정확한 내용 이해를 방해합니다. 또한 독자는 같은 단어가 반복되는 글을 지루해하기 때문에 다양한 어휘를 활용하여 글의 내용을 명확하게 전달하는 것은 매우 중요합니다. ',
      detail:
        '어휘의 의미와 유의, 반의 관계를 가진 다른 단어에 대한 학습이 필수적으로 필요한 상태입니다.',
    },
  },
  3: {
    9: {
      context:
        '접속 표지의 역할을 잘 알고, 이를 활용하여 글에서 표현하고자 하는 의도와 문장·문단 간의 관계를 명확하게 나타냈습니다. ',
      detail:
        '접속 표지를 활용하여 문장과 문장, 문단과 문단을 유기적으로 연결하고, 의도를 효과적으로 드러내는 방법에 대해 아주 잘 알고 있습니다. 이를 통해 독자에게 글의 목적을 훌륭하게 전달하였습니다.',
    },
    8: {
      context:
        '적절한 곳에 알맞은 표지를 활용하여 글에서 표현하고자 하는 의도를 나타내는 것에 익숙합니다.',
      detail:
        '접속 표지가 지니는 의미와 역할, 종류에 대해 잘 알고 있습니다. 접속 표지 사용으로, 적은 노력으로 글의 의도를 효과적으로 드러낼 수 있음을 잘 알고 있습니다. 이번에 사용한 접속 표지 이외에 다른 표현들에도 관심을 가져 보세요.',
    },
    7: {
      context:
        '접속 표지의 역할을 알고 있으며, 이를 활용하여 글의 응집력을 높이는 방법에 관심이 많습니다. 접속 표지의 다양한 역할과 종류에 대한 학습을 추천합니다.',
      detail:
        '접속 표지의 다양한 역할과 종류에 대해 안다는 것은 글을 효과적으로 쓸 수 있음을 말합니다. 다양한 접속 표지의 활용에 대해 알아보는 작업이 필요합니다.',
    },
    6: {
      context:
        '적절한 곳에 알맞은 표지를 활용하였습니다. 접속 표지를 활용하여 표현하고자 하는 의도를 나타낼 수 있음을 잘 알고 있습니다.',
      detail:
        '접속 표지를 잘 활용하면 의도를 효과적으로 드러낼 수 있습니다. 또한 문장과 문장, 문단과 문단을 유기적으로 연결할 수 있습니다. 이를 활용하면 응집성이 높은 글로 독자에게 글의 의도를 명확히 표현할 수 있습니다.',
    },
    5: {
      context:
        '접속 표지를 활용하여 의도를 표현하는 방법을 알고 있습니다. 하지만 접속 표지를 잘 활용하는 방법에 대해서는 추가적인 학습이 필요합니다.',
      detail:
        '접속 표지를 사용하면 적은 노력으로 글의 의도를 효과적으로 드러낼 수 있음을 잘 알고 있습니다. 또한 접속 표지가 지니는 의미와 역할, 종류에 대해 알고 있습니다. 문장 표지를 활용하여 글을 쓰는 것을 꾸준히 연습하여야 합니다.',
    },
    4: {
      context:
        '접속 표지 활용하면, 드러내고자 하는 의도를 효과적으로 드러낼 수 있음을 이해하고 있습니다.',
      detail:
        '접속 표지는 문장과 문장, 문단과 문단 사이를 유기적으로 연결해주는 효율적이고 효과적인 글쓰기 도구입니다. 접속 표지의 다양한 역할과 종류에 대해 안다는 것은 글을 효과적으로 쓸 수 있음을 말합니다. 다양한 접속 표지의 활용에 대해 학습해 보세요.',
    },
    3: {
      context:
        '접속 표지를 활용하여 의도를 드러내는 방법에 대해 알고 있지만, 활용하는 능력이 다소 부족합니다.',
      detail:
        '접속 표지는 문장과 문장, 문단과 문단 사이를 유기적으로 연결해주는 효율적이고 효과적인 글쓰기 도구입니다. 접속 표지를 사용하지 않으면 글을 읽을 때 자연스러운 흐름이 느껴지지 않습니다. 다양한 접속 표지의 활용을 연습해 보세요.',
    },
    2: {
      context:
        '글에서 표현하고자 하는 의도를 접속 표지를 통해 드러내는 방법에 대해 잘 알지 못합니다.',
      detail:
        '접속 표지가 무엇인지 잘 알지 못하고, 이를 활용하는 방법에 대해서도 추가적인 학습이 필요합니다. 접속 표지를 잘 활용하면, 응집성이 있는 글을 쓸 수 있으며 글에서 말하고자 하는 의도도 명확하게 전달할 수 있습니다. 잘 쓰인 글에서 접속 표지를 어떻게 사용하고 있는지 살펴보고, 이를 글을 쓸 때 활용할 수 있어야 합니다.',
    },
    1: {
      context:
        '글에서 표현하고자 하는 의도를 나타내는 접속 표지에 대해 잘 알지 못합니다.',
      detail:
        '접속 표지가 지니는 의미와 역할, 종류에 대해 알고 있지 못합니다. 접속 표지를 활용하면 문장과 문장, 문단과 문단 사이를 유기적으로 연결할 수 있습니다. 또한 말하고자 하는 내용을 효과적으로 드러낼 수 있습니다. 문장 표지를 활용하여 글을 쓰는 것을 꾸준히 연습한다면 보다 유기적이고 명확한 글을 작성할 수 있습니다. 이에 대한 학습과 연습이 필수적으로 필요하며, 꾸준히 진행되어야 합니다.',
    },
  },
  4: {
    9: {
      context:
        '다양한 길이와 구조의 문장을 활용하여 글에서 강조하고자 하는 바를 표현하는 능력을 갖췄습니다.',
      detail:
        '글의 내용에 따라 문장의 길이를 선택하여 글을 작성하는 방법을 아주 잘 알고 있습니다. 글의 완급 조절로 독자가 잘 이해할 수 있고 흥미롭게 읽을 수 있는 글을 완성하였습니다.',
    },
    8: {
      context:
        '문장의 형태와 구조가 다양하게 나타납니다. 자연스럽고 다채로운 문장 구성으로 인해 독자가 글을 이해하기 편합니다.',
      detail:
        '문장을 완급 조절하는 방법에 대해 잘 알고 있습니다. 이를 통해 글을 리듬감 있게 완성하였습니다. ',
    },
    7: {
      context:
        '다양한 길이와 구조의 문장을 활용하는 능력을 상당 수준 갖췄으며, 다채로운 문장으로 글을 구성할 수 있습니다. 이를 통해 독자의 이해도와 흥미도를 높이는 글이 되었습니다.',
      detail:
        '홑문장과 겹문장을 활용하여 글을 다채롭게 구성하였습니다. 문장 사용의 균형에 조금 더 신경 쓰면 좋은 글이 될 수 있습니다. 다채로운 글을 작성하도록 연습이 필요합니다.',
    },
    6: {
      context:
        '문장의 형태와 구조를 다양하게 활용하는 방법을 일정 수준으로 이해하고 있습니다. 독자가 이해하기 좋은 수준의 글을 작성할 수 있습니다.',
      detail:
        '문장의 종류를 이해하고 있으며, 활용 방법 또한 알고 있습니다. 하지만 홑문장과 겹문장을 조화롭게 활용하는 방법에 대해서는 추가적인 학습이 필요해 보입니다. 문장의 종류를 조화롭게 사용하여 글의 완급을 조절할 수 있고, 이를 통해 독자가 읽는 호흡을 조절할 수 있습니다.',
    },
    5: {
      context:
        '다양한 길이와 구조의 문장을 사용하는 방법을 알고 있습니다. 다만 홑문장 또는 겹문장 중 한쪽으로 치우치는 경향을 보입니다. ',
      detail:
        '홑문장 또는 겹문장 중 한쪽으로 치우치는 경향을 보입니다. 홑문장만 많이 사용하면 의미 연결이 끊어져, 산만하게 느껴질 수 있습니다. 반대로 겹문장만 많이 사용하면 글이 복잡해져서 정확한 의미 전달이 어려워지는 경우가 많습니다. 글의 내용에 따라 다채로운 글을 작성하도록 연습이 필요합니다.',
    },
    4: {
      context:
        "문장의 형태와 구조가 비교적 일정하여 '딱딱한 글'을 읽는 듯한 느낌을 줍니다. 다양한 길이와 구조의 문장을 활용하는 연습이 필요합니다.",
      detail:
        '다양한 문장의 종류를 활용하는 법에 미숙해 보입니다. 같은 길이와 구조의 문장만 사용하면 독자는 쉽게 지루해합니다. 다양한 길이와 구조의 문장을 활용하여 글을 작성해 보세요. 내용에 따른 완급조절을 할 수 있는 훌륭한 작가가 될 것입니다. 문장의 길이와 구조를 다양화하는 연습을 진행할 때입니다.',
    },
    3: {
      context:
        '다양한 문장의 길이와 구조에 대한 지식이 다소 부족해 보입니다. 유사한 형태와 구조를 가진 문장이 많아 독자가 지루함을 느낄 수 있습니다.',
      detail:
        '같은 길이와 구조의 문장만을 사용한 글은 독자를 지루하게 만듭니다. 내용의 중요도에 따라 글에 긴장감과 안정감을 섞어서 제시하는 방법을 활용하여야 합니다. 문장을 작성할 때 다양한 길이와 구조를 사용하도록 학습해 보세요.',
    },
    2: {
      context:
        '문장의 구조와 종류에 대해 잘 알고 있지 못하고 있습니다. 또한 이를 다채롭게 활용하는 능력 또한 갖추지 못한 상태입니다.',
      detail:
        "문장을 시작해서 마침표가 찍힐 때까지, '주어와 서술어'가 몇 번 나타나느냐에 따라 문장을 홑문장과 겹문장으로 나눌 수 있습니다. 대개 단호한 주장을 강조하고 싶거나, 독자가 속도감을 가지고 빠르게 읽게 하고 싶을 때 '홑문장'을 사용합니다. 자세한 설명을 하는 부분에서는 '겹문장'을 사용하는 경우가 많습니다. 다른 사람이 쓴 글을 잘 읽어보며 홑문장과 겹문장의 쓰임새에 대해 알아보세요.",
    },
    1: {
      context:
        '문장을 만드는 일에 서툴러 보입니다. 자연스럽지 않은 문장으로 인해 독자가 글을 한 번에 이해하기 상당히 어렵습니다.',
      detail:
        "하나의 문장은 주어와 서술어, 그리고 서술어의 필수 성분으로 이루어져 있습니다. 문장이 시작하고 마침표가 찍힐 때까지, '주어와 서술어'가 몇 번 나타나느냐에 따라 문장을 홑문장과 겹문장으로 나눌 수 있습니다. 홑문장과 겹문장을 만들어 보는 연습이 필수적으로 필요한 상태입니다. 이후, 홑문장과 겹문장을 다양하게 포함하고 있는 긴 글쓰기에 대한 학습까지 필수적으로 필요한 상태입니다.",
    },
  },
  5: {
    9: {
      context:
        '글을 논리적인 순서로 구성하는 능력이 탁월하며, 글의 장르에 맞춰 주제를 훌륭하게 전개하였습니다.',
      detail:
        '글의 주제와 관련된 내용을 순서에 맞게 배치하여 글의 내용을 효과적으로 전달하는 방법에 대해 아주 잘 알고 있습니다. 이러한 논리적 순서를 글의 장르에 맞춰 전개하는 방식에 대한 이해가 매우 뛰어납니다. 조직력이 좋은 글은 산만하지 않고, 하나의 주제에 대한 응집력을 보여줍니다.',
    },
    8: {
      context: '글의 장르에 맞게 논리적인 순서로 구성하는 능력이 우수합니다.',
      detail:
        '주제와 관련된 내용을 글의 전개 순서에 맞게 배치하는 방법에 대해 잘 알고 있습니다. 장르에 맞는 글의 전개 방식을 활용하여 짜임새 있는 글을 작성하였습니다.',
    },
    7: {
      context:
        '글을 논리적인 순서에 맞춰 구성하는 법을 알고 있습니다. 또한 글의 장르에 맞춰 주제를 전개하고 있습니다.',
      detail:
        '글의 주제를 장르의 전개 방식에 맞게 나타내는 방법에 대해 이해하고 있습니다. 내가 쓴 글이 하나에 주제에 대한 내용으로 구성되어 있는지, 글의 내용이 서로 연관성을 가지는지 검토하는 자세가 필요합니다.',
    },
    6: {
      context:
        '글을 논리적인 순서로 구성하는 방법에 대해 알고 있습니다. 글의 장르에 맞춰 주제를 전개하려고 노력합니다.',
      detail:
        '글에서 주제와 관련된 내용을 어떻게 전개해야 하는지 알고 있습니다. 글의 조직력은 글의 장르에 맞는 전개 순서를 이용하여 주제를 얼마나 잘 표현하였는가에 따라 달라집니다. 논리적 순서에 맞게 쓴 글은 독자가 글의 흐름을 파악하기 쉽습니다. 논리적 순서에 맞는 글을 쓰는 연습이 필요합니다.',
    },
    5: {
      context:
        '글을 논리적인 순서로 구성하는 방법에 대한 이해가 확실하지 않은 상태입니다. 글의 장르에 맞춰 주제를 전개하는 것이 아직 익숙하지 않습니다.',
      detail:
        '글의 갈래마다 글을 구성하고 전개하는 방식이 달라집니다. 내가 쓴 글의 주제를 생각하며 이 주제를 가장 효과적으로 전달할 수 있는 글의 형태가 무엇일지 고민하는 자세가 필요합니다. 조직력이 좋은 글은 글에 논리적인 흐름이 있어 독자가 글을 자연스럽게 읽을 수 있습니다. 글의 조직력을 높이기 위한 연습을 해 보세요.',
    },
    4: {
      context:
        '글의 장르에 맞게 주제를 전개하는 데에 다소 어려움을 느낍니다. 또한 글을 논리적인 순서로 구성하는 능력이 다소 부족합니다.',
      detail:
        '글에는 여러 장르가 있습니다. 글의 목적에 맞게 글의 장르가 정해지고, 이에 맞게 글의 형태와 구조 또한 달라집니다. 따라서 글에 목적에 맞게 주제를 드러내는 방법을 학습할 필요가 있습니다. 글을 논리적으로 작성하기 위해서는 말하려는 주제가 명확한지, 주제를 설명하는 순서가 독자가 이해하기 쉬운지를 계속해서 고민해야 합니다. 논리적 순서를 가진 글을 읽으며 글의 구조를 살펴보세요.',
    },
    3: {
      context:
        '글의 논리적인 순서와 장르에 대해 이해하고 있지만, 이에 맞게 주제를 전개하는 데에 어려움을 느낍니다.',
      detail:
        "글은 장르적 속성을 가지며, 이에 따라 일정한 논리를 가집니다. 독자는 글을 읽으며 글의 장르를 파악하게 되며, 예상한 장르에 대한 논리를 기대하고 있습니다. 이러한 기대에 맞게 글을 쓰는 것을 '조직력 있게 글을 쓴다'라고 합니다. 따라서 글에 따라 읽는 사람이 쉽게 이해하도록 순서를 정하여 차근차근 진행해야 합니다. 논리적인 순서에 맞는 글을 작성하는 연습을 꾸준히 진행해 보세요.",
    },
    2: {
      context:
        '글을 논리적인 순서로 구성하는 능력이 다소 부족합니다. 장르마다 가지는 글의 형태에 익숙하지 않은 것 같습니다.',
      detail:
        '글의 장르에는 주장 전달의 글, 객관적 사실 전달의 글, 감상의 글 등이 있습니다. 이러한 장르마다 가지는 글의 형태가 다릅니다. 글의 형태에 따라 가지는 논리적 순서 또한 다릅니다. 글을 논리적으로 적지 않으면 독자는 글을 읽으며 당황하게 됩니다. 독자가 당황하지 않도록 일정한 형태와 순서에 맞는 글을 작성하는 것을 연습하여야 합니다.',
    },
    1: {
      context:
        '글을 논리적인 순서로 구성하는 방법을 잘 알지 못하고 있습니다. 장르에 맞는 글의 형태에 대한 지식이 매우 부족한 상태입니다.',
      detail:
        '글은 하나의 주제를 가지고, 논리적인 순서를 가져야 합니다. 글의 논리성이라는 것은 읽는 사람이 쉽게 이해하도록 순서를 정하는 일이기도 합니다. 나의 글이 어느 부분에서 갑자기 다른 말을 하고 있지는 않은지 다시 한번 살피는 작업이 필요합니다. 또한 장르에 맞게 이러한 순서는 달라질 수 있음을 반드시 학습해야 하며, 익숙하게 만드는 것이 중요합니다.',
    },
  },
  6: {
    9: {
      context:
        '글의 주제를 명확하게 표현하였고, 세부 내용 또한 주제와 관련된 내용으로 잘 구성되었습니다.',
      detail:
        '주제를 선택하고 그 범위를 적절하게 한정하여 이와 관련된 내용을 작성하는 능력이 매우 뛰어납니다. 적절하고 관련 있는 세부 내용과 정보를 사용하여 구체적인 글을 쓰는 방법을 잘 알고 있습니다.',
    },
    8: {
      context:
        '글이 하나의 주제로 이어져 있고 모든 문단이 관련된 내용으로 잘 구성되었습니다.',
      detail:
        '글의 주제를 선택하고 그 범위를 한정하여 관련된 내용을 작성하는 방법을 잘 알고 있습니다. 세부 내용과 정보를 이용하여 구체적인 글을 작성하는 능력이 뛰어납니다.',
    },
    7: {
      context:
        '글이 대체로 주제와 관련된 내용으로 구성되어, 독자가 글의 주제를 파악하는 데에 어려움을 느끼지 않습니다.',
      detail:
        '글의 주제를 선택하고 이와 관련된 내용의 범위를 적절하게 한정하면 주제가 명확한 글을 쓸 수 있습니다. 또한 주제와 관련된 세부 내용이나 정보를 이용한다면 보다 구체적인 글이 됩니다.',
    },
    6: {
      context:
        '글의 주제를 명확하게 표현하려 노력하였고, 글이 내용이 전반적으로 주제와 관련된 내용으로 구성되었습니다.',
      detail:
        '적절한 글의 주제와 내용의 범위를 정하는 것은 좋은 글을 쓰기 위한 기초입니다. 명확하지 않은 주제와 적절하지 않은 범위의 내용은 독자를 혼란스럽게 합니다. 글의 주제와 범위에 대한 내용을 숙지하고 있어야 합니다.',
    },
    5: {
      context:
        '글에서 표현하려는 주제가 명확하지 않습니다. 하나의 글에서는 하나의 주제만을 말해야 합니다.',
      detail:
        '주제는 글쓴이가 글에서 전달하고자 하는 중심 생각입니다. 주제를 분명하고 명확하게 표현할수록 독자가 읽기 편한 글이라 할 수 있습니다. 이와 더불어 주제와 관련된 세부 내용과 정보를 함께 사용한다면 더욱 구체적인 글을 쓸 수 있습니다.',
    },
    4: {
      context:
        '글의 주제를 표현하려는 시도가 보이지만, 주제를 표현하는 능력이 기초적인 단계이기에 앞으로 노력이 필요합니다.',
      detail:
        '주제가 명확하지 않은 글은 독자가 글에서 전달하고자 하는 바를 이해하기 어렵게 합니다. 따라서 주제가 잘 드러나게 글을 쓰는 것이 중요합니다. 또한 주제와 관련이 있는 세부 내용과 정보를 이용하면 주제를 나타내는 좋은 뒷받침이 될 수 있습니다. 글을 쓸 때 주제를 잘 드러낼 수 있도록 연습해 보세요.',
    },
    3: {
      context:
        '글의 주제를 표현하는 능력이 다소 부족합니다. 글의 중심 주제가 갑자기 변화하거나 너무 다양하지는 않은지 확인해야 합니다.',
      detail:
        '글의 중심 생각인 주제가 명확할수록 독자가 읽기 좋고 이해하기 수월한 글입니다. 주제가 불분명한 글은 전달하고자 하는 바가 잘 드러나지 않아 읽을 때 혼란스러울 수 있습니다. 따라서 하나의 주제를 골라 이와 관련된 내용으로 글을 구성하는 것이 좋습니다. 글에서 주제를 확실하게 드러내는 방법을 연습해 보세요.',
    },
    2: {
      context:
        '글의 전반적인 내용과 주제의 연관성이 부족합니다. 독자가 글의 내용을 파악하기 어려울 수 있습니다.',
      detail:
        '글의 중심이 되는 내용, 또는 글의 목적을 주제라 합니다. 글의 주제가 또렷하게 나타나는 글은 읽기 쉽고 이해하기 좋습니다. 반면 주제가 명확하지 않은 글은 읽기 어렵고 혼란스러워 이해하기 어렵습니다. 글을 쓰기 전 하나의 주제를 정하고, 이와 연관된 지식이나 경험을 활용한 뒷받침 내용을 활용하여 글을 쓰는 연습이 필요합니다.',
    },
    1: {
      context:
        '글의 주제를 표현하는 능력이 부족하고, 글의 전반적인 내용과 주제의 연관성이 거의 없습니다. 독자가 내용을 파악하기 어려운 글입니다.',
      detail:
        '글쓴이가 글에서 전달하고자 하는 중심 생각을 "주제"라고 합니다. 주제가 분명한 글은 하나의 통일된 모습을 보이지만, 반대로 주제가 분명하지 않은 글은 글의 핵심이 뚜렷하지 않아 읽는 이에게 혼란을 줍니다. 하나의 주제를 정하고, 그 주제와 관련된 정보를 이용하여 글을 쓰는 연습이 필요합니다.',
    },
  },
};
