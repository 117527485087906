import service from 'core/services/api/essay/essayApi';

const essayRest = {
  async settingsLogin(params = {}) {
    console.log('essayRest getUserSettingsOptions...');
    console.log(params);
    return await service.settingsLogin(params);
  },

  async getUserContent(params = {}) {
    console.log('essayRest getUserContent..............');
    console.log(params);
    const res = await service.getUserContent(params);
    console.log('essayRest getUserContent..............after');
    return res;
  },

  async getOrSaveDraftDb(params = {}) {
    console.log('essayRest getOrSaveDraftDb..............');
    console.log(params);
    const res = await service.getOrSaveDraftDb(params);
    console.log(res);
    return res;
  },

  async submitDb(params = {}) {
    console.log('essayRest submitDb..............');
    console.log(params);
    const res = await service.submitDb(params);
    return res;
  },

  async getStructureSearch(params = {}) {
    console.log('essayRest getStructureSearch..............');
    console.log(params);
    const res = await service.getStructureSearch(params);
    console.log(res);
    return res;
  },

  async checkSpelling(params = {}) {
    console.log('essayRest getStructureBoxes..............');
    console.log(params);
    const res = await service.checkSpelling(params);
    return res;
  },

  async analysisPage(params = {}) {
    console.log('essayRest analysisPage..............');
    console.log(params);
    const res = await service.analysisPage(params);
    return res;
  },
  async analysisKeewiPage(params = {}) {
    console.log('essayRest analysisKeewiPage..............');
    console.log(params);
    const res = await service.analysisKeewiPage(params);
    return res;
  },

  async readingPage(params = {}) {
    console.log('essayRest readingPage..............');
    console.log(params);
    const res = await service.readingPage(params);
    return res;
  },

  async structurePage(params = {}) {
    console.log('essayRest structurePage..............');
    console.log(params);
    const res = await service.structurePage(params);
    return res;
  },
};

export default essayRest;
