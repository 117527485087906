import {CompositeDecorator} from 'draft-js';
import React from 'react';
import PropTypes from 'prop-types';

export const DecosUtils = {
  decoTypes: {
    SPELL: 'SPELL',
  },

  getDecoWords(correction) {
    if (correction && correction.length > 0) {
      const decoWords = correction.map((item, index) => {
        return {
          orgStr: item.word,
        };
      });
      // console.log(decoWords);
      return decoWords;
    } else {
      return [];
    }
  },

  generateDecorator: function (decoType, spells) {
    if (decoType === this.decoTypes.SPELL) {
      console.log(
        'generateDecorator.....................decoType: ' +
          decoType +
          ' spells ',
      );
      console.log(spells);
      return new CompositeDecorator([
        {
          strategy: (contentBlock, callback, contentState) => {
            // console.log(contentBlock);
            // console.log(callback);
            // console.log(contentState);

            if (spells.length > 0) {
              const _spells = spells
                .filter(x => {
                  console.log(x);
                  console.log(contentBlock.getKey());
                  // return x.blockKey === contentBlock.getKey();
                  return true;
                })
                .filter(x => x.orgStr != null);

              // console.log('_spells');
              // console.log(_spells);
              if (_spells.length > 0) {
                const _orgStr = _spells.map(
                  d =>
                    '([^\uac00-\ud7a3])' +
                    //eslint-disable-next-line
                    d.orgStr.replace(/[\[\]\/\{\}\(\)]/g, '\\$&').trim() +
                    '(?=[^\uac00-\ud7a3])',
                );
                // console.log('_orgStr');
                // console.log(_orgStr);
                if (_orgStr.length > 0) {
                  const regex = new RegExp(_orgStr.join('|'), 'g');
                  // console.log('regex');
                  // console.log(regex);
                  let matchArr, start, end;
                  while (
                    (matchArr = regex.exec(
                      ' ' +
                        //eslint-disable-next-line
                        contentBlock.getText().replace(/[\(\)\']/, ' ') +
                        ' ',
                    )) !== null
                  ) {
                    start = matchArr.index;
                    end = start + matchArr[0].trim().length;
                    // console.log('start');
                    // console.log(start);
                    // console.log('end');
                    // console.log(end);
                    callback(start, start + matchArr[0].trim().length);
                  }
                }
              }
            }
          },
          component: TokenSpellSpan,
          props: {},
        },
      ]);
    }
  },
};

const TokenSpellSpan = props => {
  console.log('TokenSpan props');
  console.log(props);
  const style = getDecoratedStyle('HIGHLIGHT_SPELL');
  return (
    <span data-offset-key={props.offsetkey} style={style}>
      {props.children}
    </span>
  );
};

TokenSpellSpan.propTypes = {
  offsetkey: PropTypes.object,
  children: PropTypes.node.isRequired,
};

function getDecoratedStyle(mutability) {
  switch (mutability) {
    case 'HIGHLIGHT_SPELL':
      return styles.immutable;
    case 'MUTABLE':
      return styles.mutable;
    case 'SEGMENTED':
      return styles.segmented;
    default:
      return null;
  }
}

const styles = {
  root: {
    fontFamily: "'Helvetica', sans-serif",
    padding: 20,
    width: 600,
  },
  editor: {
    border: '1px solid #ccc',
    cursor: 'text',
    minHeight: 80,
    padding: 10,
  },
  button: {
    marginTop: 10,
    textAlign: 'center',
  },
  immutable: {
    // backgroundColor: 'rgba(0, 0, 0, 0.2)',
    backgroundColor: '#f5df92',
    padding: '2px 0',
  },
  mutable: {
    backgroundColor: 'rgba(204, 204, 255, 1.0)',
    padding: '2px 0',
  },
  segmented: {
    backgroundColor: 'rgba(248, 222, 126, 1.0)',
    padding: '2px 0',
  },
};
