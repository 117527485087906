import React from 'react';
import {Box} from '@mui/material';
import {useEffect, useState} from 'react';
import {CoalaConstants} from 'core/utils/CoalaConstants';
import {useDispatch, useSelector} from 'react-redux';
import essayRest from 'core/services/api/essay/essayRest';
import {saveTimerId} from 'core/reducer/essay/commonEssayReducer';
import {DbSubmitUtils} from 'core/utils/DbSubmitUtils';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ClockImg from 'assets/media/draft-timer/ClockClockwise.png';

const DraftTimer = () => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [notTimer, setNotTimer] = useState(true);

  const commonEssayReducer = useSelector(state => state.commonEssayReducer);
  const contentReducer = useSelector(state => state.contentReducer);

  useEffect(() => {
    const timerId = setTimeout(async () => {
      const newCount = (count + 1) % CoalaConstants.DEFAULT_TIMER_SAVE_GAP;
      console.log('old count = ' + count);
      console.log('new count = ' + newCount);
      setCount(count => (count + 1) % CoalaConstants.DEFAULT_TIMER_SAVE_GAP);

      console.log(contentReducer.content);

      console.log(new Date() + ': old count=' + count + ' ######');
      console.log(new Date() + ': new count=' + newCount + ' ######');
      if (newCount === 0) {
        console.log(' newCount = ' + newCount + ' saved ######');
        const context = DbSubmitUtils.createContext();
        console.log(context);

        if (notTimer) {
          setNotTimer(false);
        }

        return await essayRest
          .getOrSaveDraftDb({
            cmd: 'put',
            key: DbSubmitUtils.getDbKey(commonEssayReducer.selUserSettings),
            context: JSON.stringify(context),
          })
          .then(res => {
            console.log(res);
          });
        // .finally(() => {})
      }
    }, CoalaConstants.DEFAULT_TIMER_MILLI_SEC);

    console.log('DraftTimer timerId = ' + commonEssayReducer.timerId);
    dispatch(saveTimerId(timerId));

    return () => clearTimeout(timerId);
  }, [count]);

  return (
    <Box
      sx={{
        fontSize: '16px',
        textAlign: 'right',
        padding: '3px 10px 0px 0px',
        color: '#343f2b',
        height: '20px',
      }}
    >
      {notTimer && <Box> </Box>}

      {/*<AccessAlarmIcon sx={{fontSize: '13px'}} />*/}
      {!notTimer && (
        <Box>
          <img src={ClockImg} alt={'Timer'} />
          <span style={{color: '#ff8414'}}>
            {' '}
            {count === 0 ? '방금' : count}
          </span>
          <span> {count === 0 ? '' : '분'} 전에 저장되었습니다.</span>
        </Box>
      )}
    </Box>
  );
};

export default DraftTimer;
