import service from 'core/services/api/auth/authApi.js';
import store from 'core/store';
import {removeLoginData, saveLoginData} from 'core/reducer/common/authReducer';

const authRest = {
  async signin(params = {}) {
    console.log('authRest signin...');
    console.log(params);
    const res = await service.signin(params);
    console.log(res);

    return res;
  },

  async signout({commit}, params = {}) {
    console.log('commonStore signout..............');
    console.log(params);
    const res = await service.signout(params);
    console.log('-----------------------save removeLoginData');
    store.dispatch(removeLoginData());
    return res;
  },

  reissueJwtToken({commit}, params = {}) {
    console.log('commonStore refreshToken...');
    console.log(params);
    let res = params;
    if (res && res.result === 1) {
      console.log(res);
      console.log('-----------------------reissueJwtToken save setLoginData');
      store.dispatch(saveLoginData(res));
    } else {
      console.log('------------- not save refreshToken ');
    }
    return res;
  },

  async changePassword({commit}, params = {}) {
    console.log('myinfoStore changePassword...');
    console.log(params);
    const res = await service.changePassword(params);
    console.log(res);
    return res;
  },
};

export default authRest;
