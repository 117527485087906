import PropTypes from 'prop-types';
import {useRef} from 'react';
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const BarChart = props => {
  const barChart = useRef();

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      //   x: {
      //     // categories: [
      //     //   'Apples Apples Apples Apples Apples Apples Apples ',
      //     //   'Oranges',
      //     //   'Pears',
      //     //   'Grapes',
      //     //   'Bananas',
      //     // ],
      //     labels: {
      //       //   x: 25,
      //       //   y: -22,
      //       align: 'left',
      //       style: {
      //         width: '300px',
      //       },
      //     },
      //   },
      y: {
        beginAtZero: true,
        max: 50,
        ticks: {
          stepSize: 10,
          callback: function (value, index) {
            if (value <= 1) {
              return '';
            } else if (value <= 10) {
              return '매우 쉬움';
            } else if (value <= 20) {
              return '약간 쉬움';
            } else if (value <= 30) {
              return '보통';
            } else if (value <= 40) {
              return '약간 어려움';
            } else if (value <= 50) {
              return '매우 어려움';
            }
            return '';
          },
        },
      },
    },
  };
  return (
    <Bar
      ref={barChart}
      data={props.barData}
      options={options}
      width={props.width}
      height={props.height}
    />
  );
};

BarChart.propTypes = {
  barData: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default BarChart;
