import bgDndColRec from 'assets/media/bg/bg-dnd-col-rec.png';

import React, {useEffect, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {
  Box,
  Button,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AddIcon from '@mui/icons-material/Add';
import {DeleteOutline} from '@mui/icons-material';
import {useDispatch, useSelector} from 'react-redux';
import {ElementUtils} from 'core/utils/ElementUtils';
import {
  addStructureBox,
  removeStructureBox,
  saveStructureBoxText,
  saveStructureBoxWhenDropped,
} from 'core/reducer/essay/structureReducer';
import {StringUtils} from 'core/utils/StringUtils';
import {saveStepValues} from 'core/reducer/essay/commonEssayReducer';
import {CoalaConstants} from 'core/utils/CoalaConstants';
import {SHintChip, StructTextField, StructTitle} from '../CourseComps';

const WritingCards = props => {
  const dispatch = useDispatch();

  const [clickedIndex, setClickedIndex] = useState('-1');
  const [openRemoveDlg, setOpenRemoveDlg] = useState(false);

  const commonEssayReducer = useSelector(state => state.commonEssayReducer);
  const structureReducer = useSelector(state => state.structureReducer);

  const [collapsed, setCollapsed] = useState(
    new Array(structureReducer.structureBoxes.length).fill(false),
  );

  useEffect(async () => {
    console.log('SearchResults useEffect props.searchResults ...............');
    setCollapsed(new Array(structureReducer.structureBoxes.length).fill(false));
  }, [structureReducer.selectedHintIndex]);

  // StructureBox Drag And Drop //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleDropped = result => {
    if (!result.destination) return;
    console.log('handleDropped..............result');
    console.log(result);

    const items = [...collapsed];
    if (result.destination.index === result.source.index) {
      items[result.destination.index] = true;
    } else {
      items[result.destination.index] = true;
      items[result.source.index] = false;
    }
    setCollapsed(items);

    const newBoxes = [...structureReducer.structureBoxes];
    console.log(newBoxes);
    const [reorderedItem] = newBoxes.splice(result.source.index, 1);
    newBoxes.splice(result.destination.index, 0, reorderedItem);

    console.log('handleDropped..............newBoxes');
    console.log(newBoxes);
    dispatch(saveStructureBoxWhenDropped(newBoxes));
  };

  // StructureBox Collapse //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleWritingBoxExpandToggle = e => {
    const {noid, clickedIndex} = ElementUtils.getClosestDataInfo(
      e,
      '.WritingCard',
    );
    console.log(
      'handleWritingBoxExpandToggle noid = ' +
        noid +
        ', clickedIndex = ' +
        clickedIndex,
    );

    const items = [...collapsed];
    items.forEach((value, index, array) => {
      array[index] = false;
    });
    items[clickedIndex] = !items[clickedIndex];
    setCollapsed(items);
  };

  function handleWritingBoxExpand(e) {
    console.log('handleWritingBoxExpand................');
    const {noid, clickedIndex} = ElementUtils.getClosestDataInfo(
      e,
      '.WritingCard',
    );
    console.log(
      'handleWritingBoxExpand noid = ' +
        noid +
        ', clickedIndex = ' +
        clickedIndex,
    );

    const items = [...collapsed];
    items.forEach((value, index, array) => {
      array[index] = false;
    });
    items[clickedIndex] = !items[clickedIndex];
    setCollapsed(items);
  }

  function handleChangeWritingBoxText(e) {
    const {noid, clickedIndex} = ElementUtils.getClosestDataInfo(
      e,
      '.WritingCard',
    );
    console.log(
      'handleChangeWritingBoxText noid = ' +
        noid +
        ', clickedIndex = ' +
        clickedIndex,
    );

    dispatch(saveStructureBoxText({index: clickedIndex, text: e.target.value}));

    if (StringUtils.isNotEmpty(e.target.value)) {
      console.log('Writing card is not empty');
      if (commonEssayReducer.workedLastStep <= commonEssayReducer.activeStep) {
        dispatch(
          saveStepValues({
            clickableCompletedStepsArr: [
              {index: commonEssayReducer.activeStep + 1, value: true},
            ],
          }),
        );
      }
      return;
    }

    console.log(clickedIndex + ' writing card is empty');
    if (commonEssayReducer.workedLastStep < CoalaConstants.STEP_INDEX_WRITING) {
      let isAllCardEmpty = true;
      for (let i = 0; i < structureReducer.structureBoxes.length; i++) {
        console.log(structureReducer.structureBoxes[i]);
        console.log(
          StringUtils.isEmpty(structureReducer.structureBoxes[i].text),
        );
        if (i === clickedIndex) continue;
        if (!StringUtils.isEmpty(structureReducer.structureBoxes[i].text)) {
          isAllCardEmpty = false;
          break;
        }
        console.log('isAllCardEmpty = ' + isAllCardEmpty);
      }

      if (isAllCardEmpty) {
        dispatch(
          saveStepValues({
            clickableCompletedStepsArr: [
              {index: commonEssayReducer.activeStep + 1, value: false},
            ],
          }),
        );
      }
    }
  }

  // StructureBox Add/Remove  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const handleAdd = e => {
    e.stopPropagation();
    const {noid, clickedIndex} = ElementUtils.getClosestDataInfo(
      e,
      '.WritingCard',
    );
    console.log(
      'handleAdd noid = ' + noid + ', clickedIndex = ' + clickedIndex,
    );

    dispatch(addStructureBox({clickedIndex: clickedIndex}));

    const items = [...collapsed];
    items.splice(clickedIndex + 1, 0, false);
    items.forEach((value, index, array) => {
      array[index] = false;
    });
    items[clickedIndex + 1] = !items[clickedIndex];
    setCollapsed(items);
  };

  const handleClickOpenRemoveDlg = e => {
    console.log(e.target);

    const {noid, clickedIndex} = ElementUtils.getClosestDataInfo(
      e,
      '.WritingCard',
    );
    console.log(
      'handleClickOpenRemoveDlg noid = ' +
        noid +
        ', clickedIndex = ' +
        clickedIndex,
    );

    if (structureReducer.structureBoxes.length <= 1) {
      alert('최소 1개의 글상자가 필요합니다.');
      return;
    }

    setClickedIndex(clickedIndex);
    setOpenRemoveDlg(true);
  };

  const handleCloseRemove = e => {
    console.log('handleCloseRemove clickedIndex = ' + clickedIndex);
    dispatch(removeStructureBox({clickedIndex: clickedIndex}));

    setOpenRemoveDlg(false);
  };

  const handleCloseCancelRemove = e => {
    setOpenRemoveDlg(false);
  };

  return (
    <Box pt={1} pl={1} pr={1}>
      <DragDropContext onDragEnd={handleDropped}>
        <Droppable droppableId="writingCards">
          {provided => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              {structureReducer.structureBoxes.map((structureBox, index) => (
                <Draggable
                  key={'key' + index}
                  draggableId={'draggable-' + index}
                  index={index}
                >
                  {provided => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <Box
                        data-noid={structureBox.noid}
                        data-index={index}
                        className={'WritingCard'}
                        sx={{
                          display: 'flex',
                          marginBottom: '1.2rem',
                          alignItems: 'stretch',
                          // height: '200px',
                          // backgroundColor: '#4156a0',
                          backgroundImage: `url(${bgDndColRec})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: '40px 100%',
                        }}
                      >
                        {/*<Box*/}
                        {/*  onClick={handleWritingBoxExpandToggle}*/}
                        {/*  sx={{*/}
                        {/*    backgroundColor: '#4156a0',*/}
                        {/*    borderTopLeftRadius: 8, borderBottomLeftRadius: 8,*/}
                        {/*    width: '1rem',*/}
                        {/*    // borderLeft: '5px solid #efeff4'*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*</Box>*/}

                        <Collapse
                          orientation="horizontal"
                          in={collapsed[index]}
                          sx={{
                            backgroundColor: '#578634',
                          }}
                        >
                          <Box
                            onClick={handleWritingBoxExpandToggle}
                            sx={{
                              backgroundColor: '#578634',
                              // boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%)'
                              //   + ', 10px 3px 4px 0px rgb(0 0 0 / 14%)'
                              //   + ', 10px 1px 8px 0px rgb(0 0 0 / 12%)',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'stretch',
                              justifyContent: 'space-between',
                              // marginLeft: '20px',
                              margin: '0px 0.75rem 0px 0.3rem',
                              height: '100%',
                              width: '30px',
                            }}
                          >
                            <IconButton
                              aria-label="드래그"
                              {...provided.dragHandleProps}
                              sx={{padding: '0 !important', marginTop: '1rem'}}
                            >
                              <DragIndicatorIcon
                                mr={2}
                                sx={{
                                  color: 'white',
                                }}
                              />
                            </IconButton>
                            <IconButton
                              aria-label="추가"
                              sx={{padding: '0 !important'}}
                            >
                              <AddIcon
                                onClick={handleAdd}
                                mr={2}
                                sx={{
                                  color: 'white',
                                }}
                              />
                            </IconButton>
                            <IconButton
                              aria-label="삭제"
                              sx={{
                                padding: '0 !important',
                                marginBottom: '1rem',
                              }}
                            >
                              <DeleteOutline
                                mr={2}
                                onClick={handleClickOpenRemoveDlg}
                                sx={{
                                  color: 'white',
                                }}
                              />
                            </IconButton>
                          </Box>
                        </Collapse>

                        <Paper
                          component="form"
                          elevation={3}
                          data-index={index}
                          className={'structure-box'}
                          sx={{
                            p: '1rem 1.5rem 1.5rem',
                            width: 'calc(100%)',
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            border: 'solid 1px #979797',
                            backgroundColor: '#edf1e0',
                          }}
                        >
                          <Box>
                            {commonEssayReducer.selUserSettings.mode ===
                              'help' &&
                              !structureBox.added && (
                                <Box
                                  style={{
                                    display: 'table',
                                    paddingBottom: '1.25rem',
                                  }}
                                >
                                  <Box style={{display: 'table-cell'}}>
                                    <SHintChip label="힌트" />
                                  </Box>
                                  <StructTitle pl={1.5}>
                                    {structureBox.title}
                                  </StructTitle>
                                </Box>
                              )}

                            <StructTextField
                              id="outlined-multiline-static"
                              label=""
                              multiline
                              fullWidth
                              rows={4}
                              placeholder="글을 써 보세요."
                              value={structureBox.text}
                              onChange={handleChangeWritingBoxText}
                              onClick={handleWritingBoxExpand}
                              onFocus={handleWritingBoxExpand}
                              spellCheck="false"
                            />
                          </Box>
                        </Paper>
                      </Box>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>

      <Dialog
        open={openRemoveDlg}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography>글상자를 지우면, 작성한 내용이 모두 사라져오.</Typography>
          <Typography color={'red'}>선택한 글상자를 지울까요?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRemove} autoFocus>
            지우기
          </Button>
          <Button onClick={handleCloseCancelRemove}>취소하기</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default WritingCards;
