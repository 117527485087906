import React from 'react';
import {makeStyles} from '@mui/styles';
import {Container} from '@mui/material';
import {Outlet} from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#EFEFF4',
    height: '100vh',
  },
  mainContainer: {
    padding: '1rem !important',
  },
});

const EmptyLayout = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/*<TopHeader/>*/}
      <Container className={classes.mainContainer}>
        <Outlet />
      </Container>
    </div>
  );
};

export default EmptyLayout;
