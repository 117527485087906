import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';

import ApiService from 'core/services/api.service';
import {store, persistor} from 'core/store';
import {PersistGate} from 'redux-persist/integration/react';
import {ThemeProvider} from '@mui/material/styles';
import theme from './core/ui/coalaTheme';
import {DbSubmitUtils} from './core/utils/DbSubmitUtils';

ApiService.init(store);
DbSubmitUtils.init(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter basename="/">
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
