import {useNavigate} from 'react-router-dom';

import GlobalHistory from 'core/router/GlobalHistory';
import PropTypes from 'prop-types';

const NavigateSetter = props => {
  GlobalHistory.navigate = useNavigate();
  GlobalHistory.mediaMode = props.mediaMode;
  return null;
};

NavigateSetter.propTypes = {
  mediaMode: PropTypes.number,
};

export default NavigateSetter;
