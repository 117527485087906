import React, {useEffect, useRef, useState} from 'react';
import {
  BlockMapBuilder,
  ContentState,
  Editor,
  EditorState,
  getDefaultKeyBinding,
  Modifier,
} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import {DecosUtils} from 'core/utils/DecosUtils';
import {Map} from 'immutable';
import {saveWritingForced} from 'core/reducer/essay/writingReducer';
import {useDispatch, useSelector} from 'react-redux';
import {StringUtils} from 'core/utils/StringUtils';
import {forwardRef, useImperativeHandle} from 'react';
import SelectionState from 'draft-js/lib/SelectionState';
import {DbSubmitUtils} from 'core/utils/DbSubmitUtils';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import {CoalaConstants} from 'core/utils/CoalaConstants';
import commonEssayReducer, {
  saveStepValues,
} from 'core/reducer/essay/commonEssayReducer';

import {saveLengthForced} from 'core/reducer/essay/textLengthReducer';

const customRenderMap = Map({
  unstyled: {
    element: 'div',
    // will be used in convertFromHTMLtoContentBlocks
    aliasedElements: ['p'],
  },
});

// eslint-disable-next-line react/display-name
const SpellEditor = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  let className = 'RichEditor-editor';
  const editor = useRef();

  const structureReducer = useSelector(state => state.structureReducer);
  const commonEssayReducer = useSelector(state => state.commonEssayReducer);
  const writingReducer = useSelector(state => state.writingReducer);

  const textLengthReducer = useSelector(state => state.textLengthReducer);

  const _createEditorState = plainText => {
    console.log(
      'SpellEditor _createEditorState plainText ========================================',
    );
    console.log(plainText);
    if (StringUtils.isEmpty(plainText)) {
      return EditorState.createEmpty();
    } else {
      const blocksFromHtml = htmlToDraft(plainText);
      const {contentBlocks, entityMap} = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );

      // if (props.correction && props.correction.length > 0) {
      //   const decorator = getDecorator(props.correction);
      //   return EditorState.createWithContent(contentState, decorator);
      // } else {
      return EditorState.createWithContent(contentState);
      // }
    }
  };

  const _getInitEditorState = () => {
    console.log(
      '_getInitEditorState writingReducer.writing ' + writingReducer.writing,
    );
    console.log(
      '_getInitEditorState writingReducer.initialized ' +
        writingReducer.initialized,
    );
    let plainText = '';
    if (StringUtils.isEmpty(writingReducer.writing.trim())) {
      console.log(
        'SpellEditor _getInitEditorState isEmpty writingReducer.writing',
      );
      if (!writingReducer.initialized) {
        plainText = DbSubmitUtils.getStructurePlainText(
          structureReducer.structureBoxes,
        );
        if (!StringUtils.isEmpty(plainText)) {
          dispatch(saveWritingForced(plainText));
        }
      }
    } else if (writingReducer.initialized) {
      console.log('writingReducer.initialized');
      plainText = writingReducer.writing;
    }

    dispatch(saveLengthForced(plainText.trim().length));
    return _createEditorState(plainText);
  };

  const [editorState, setEditorState] = useState(_getInitEditorState());
  const [keyStatus, setKeyStatus] = useState('ETC');

  const getDecorator = correction => {
    const decoWords = DecosUtils.getDecoWords(correction);
    return DecosUtils.generateDecorator('SPELL', decoWords);
  };

  const getDecoEditorState = (beforeEditorState, correction) => {
    let decoEditorState = beforeEditorState;
    if (correction && correction.length > 0) {
      const decorator = getDecorator(correction);
      decoEditorState = EditorState.set(editorState, {
        decorator: decorator,
      });
    }

    return decoEditorState;
  };

  useEffect(() => {
    changStepWhenEmptyWriting(writingReducer.writing);
  }, []);

  useEffect(() => {
    console.log('SpellEditor useEffect...............[props.correction]');
    const decoEditorState = getDecoEditorState(editorState, props.correction);
    setEditorState(decoEditorState);
  }, [props.correction]);

  const getNewContent = (plainText, targetEditorState) => {
    const blocksFromHtml = htmlToDraft('&nbsp;' + plainText);
    // console.log(blocksFromHtml);
    const currentContent = targetEditorState.getCurrentContent();
    const currentSelection = targetEditorState.getSelection();
    console.log(currentContent);
    console.log(currentSelection);
    const htmlContentMap = BlockMapBuilder.createFromArray(
      blocksFromHtml.contentBlocks,
    );
    // console.log(htmlContentMap);
    return Modifier.replaceWithFragment(
      currentContent,
      currentSelection,
      htmlContentMap,
    );
  };

  const resetWithText = plainText => {
    let contentState = editorState.getCurrentContent();
    const firstBlock = contentState.getFirstBlock();
    const lastBlock = contentState.getLastBlock();
    const allSelected = new SelectionState({
      anchorKey: firstBlock.getKey(),
      anchorOffset: 0,
      focusKey: lastBlock.getKey(),
      focusOffset: lastBlock.getLength(),
      hasFocus: true,
    });
    contentState = Modifier.removeRange(contentState, allSelected, 'backward');
    let newEditorState = EditorState.push(
      editorState,
      contentState,
      'remove-range',
    );

    let lastEditorState = newEditorState;
    console.log('************************ plainText-------------' + plainText);
    if (!StringUtils.isEmpty(plainText)) {
      console.log(
        '************************ plainText-------------' + plainText,
      );
      const newContent = getNewContent(plainText, newEditorState);
      lastEditorState = EditorState.push(
        newEditorState,
        newContent,
        'insert-characters',
      );
      // const newEditorState2 = EditorState.forceSelection(newEditorState, newEditorState.getSelectionAfter())
    }
    setEditorState(lastEditorState);
  };

  const clearEditor = () => {
    let contentState = editorState.getCurrentContent();
    const firstBlock = contentState.getFirstBlock();
    const lastBlock = contentState.getLastBlock();
    const allSelected = new SelectionState({
      anchorKey: firstBlock.getKey(),
      anchorOffset: 0,
      focusKey: lastBlock.getKey(),
      focusOffset: lastBlock.getLength(),
      hasFocus: true,
    });
    contentState = Modifier.removeRange(contentState, allSelected, 'backward');
    let newEditorState = EditorState.push(
      editorState,
      contentState,
      'remove-range',
    );
    setEditorState(newEditorState);
  };

  useImperativeHandle(ref, () => ({
    refresh: (plainText, correction) => {
      console.log('SpellEditor refresh plainText=' + plainText);
      dispatch(saveWritingForced(plainText));
      resetWithText(plainText);
    },
  }));

  const changStepWhenEmptyWriting = writingText => {
    if (StringUtils.isEmpty(writingText.trim())) {
      console.log('spellEditor empty ..............................');
      const stepValues = {
        clickableCompletedStepsArr: [
          {index: CoalaConstants.STEP_INDEX_ANALYSIS, value: false},
          {index: CoalaConstants.STEP_INDEX_SUBMIT, value: false},
        ],
      };
      dispatch(saveStepValues(stepValues));
      return;
    }

    if (
      commonEssayReducer.clickableCompletedStep[
        CoalaConstants.STEP_INDEX_ANALYSIS
      ] === false
    ) {
      console.log('spellEditor is not empty change  .............');
      const stepValues = {
        clickableCompletedStepsArr: [
          {index: CoalaConstants.STEP_INDEX_ANALYSIS, value: true},
        ],
      };
      dispatch(saveStepValues(stepValues));
    }
  };

  const changeLengthText = writingText => {
    console.log('length count : ' + writingText.trim().length);
    dispatch(saveLengthForced(writingText.trim().length));
  };

  const onChange = newEditorState => {
    try {
      const content = newEditorState.getCurrentContent();
      const newText = content.getPlainText();
      console.log('onChange. newText = ' + newText);
      console.log(commonEssayReducer.clickableCompletedStep);
      setEditorState(newEditorState);
      dispatch(saveWritingForced(' ' + newText));

      changStepWhenEmptyWriting(newText);
      // 글자수 체크
      changeLengthText(newText);
    } catch (e) {
      //console.log(e);
    }
  };

  const focus = () => {
    console.log('focus..........');
    try {
      if (editor) {
        editor.current.focus();
      }
    } catch (e) {
      //console.log(e);
    }
  };

  const onKeyBindingFn = e => {
    if (e.keyCode === 32) {
      setKeyStatus('SPACE');
      return getDefaultKeyBinding(e);
    } else if (e.keyCode === 46) {
      setKeyStatus('DELETE');
      return getDefaultKeyBinding(e);
    } else if (e.keyCode === 8) {
      setKeyStatus('BACKSPACE');
      return getDefaultKeyBinding(e);
    } else if (e.keyCode === 13) {
      setKeyStatus('ENTER');
      return getDefaultKeyBinding(e);
    } else if (e.keyCode > 189) {
      setKeyStatus('SPECIAL');
      return getDefaultKeyBinding(e);
    } else {
      setKeyStatus('ETC');
      return getDefaultKeyBinding(e);
    }
  };

  const onHandlePastedText = (text, html) => {
    console.log('onHandlePastedText');
  };

  return (
    <Box onClick={focus} className={'SpellEditorWrapperBox'}>
      <Editor
        editorState={editorState}
        onChange={onChange}
        keyBindingFn={onKeyBindingFn}
        handlePastedText={onHandlePastedText}
        blockRenderMap={customRenderMap}
        placeholder="글을 작성하거나 붙여넣기 해주세요."
        ref={editor}
      />
    </Box>
  );
});

SpellEditor.propTypes = {
  correction: PropTypes.array,
};

export default SpellEditor;
