import React from 'react';
import {useHistory, useNavigate} from 'react-router-dom';
import {Box, Button} from '@mui/material';
import {SfBookBox} from '../../view/components/auth/SigninFormComps';
import logo from 'assets/media/logo/logo1.png';
import logoCoala from 'assets/media/logo/logo_coala5.png';
import {DlgActionsCmm} from '../../view/components/commons/DialogComps';

const NotFoundError = () => {
  const navigate = useNavigate();

  function goBefore() {
    navigate(-1);
  }

  function goFirst() {
    navigate('/usersettings', {replace: false});
  }

  return (
    <Box
      sx={{
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
      }}
    >
      <SfBookBox
        sx={{
          backgroundImage: 'none !important',
          flexDirection: 'column',
          textAlign: 'left !important',
        }}
      >
        <Box sx={{paddingTop: '10px'}}>
          <img src={logoCoala} alt={'logo'} style={{width: '300px'}} />
        </Box>
        <Box
          sx={{
            width: '398px',
            height: '18px',
            margin: '50px 0 5px',
            fontSize: '25px',
            fontWeight: '500',
            color: '#4e5c43',
          }}
        >
          요청하신 페이지를 찾을 수 없습니다.
        </Box>
        <Box
          sx={{
            width: '668px',
            height: '80px',
            margin: '23px 0 20px 2px',
            fontSize: '20px',
            color: '#4e5c43',
            lineHeight: '2rem',
          }}
        >
          <Box>방문하시려는 페이지의 주소가 잘못 입력되었거나,</Box>
          <Box>
            페이지의 주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수
            없습니다.
          </Box>
        </Box>
        <DlgActionsCmm sx={{justifyContent: 'flex-start', paddingLeft: '0'}}>
          <Button variant="outlined" color="secondary" onClick={goBefore}>
            이전으로
          </Button>
          <Button variant="contained" color="secondary" onClick={goFirst}>
            처음으로
          </Button>
        </DlgActionsCmm>
      </SfBookBox>
    </Box>
  );
};

export default NotFoundError;
