import {createAction, handleActions} from 'redux-actions';
import produce from 'immer';

const INIT_LENGTH = 'textLengthReducer/initLength';
const SAVE_LENGTH_FORCED = 'textLengthReducer/saveLengthForced';

export const initLength = createAction(INIT_LENGTH, data => data);
export const saveLengthForced = createAction(SAVE_LENGTH_FORCED, data => data);

const initialState = {
  textLength: 0,
};

const textLengthReducer = handleActions(
  {
    [INIT_LENGTH]: (state, action) =>
      produce(state, draft => {
        console.log('!!!INIT_TEXTLENGTH..................');
        draft.textLength = 0;
      }),
    [SAVE_LENGTH_FORCED]: (state, action) =>
      produce(state, draft => {
        console.log('!!!SAVE_TEXTLENGTH_FORCED..................');
        draft.textLength = action.payload;
      }),
  },
  initialState,
);

export default textLengthReducer;
