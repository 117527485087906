import './assets/css/App.css';
import useMediaQuery from '@mui/material/useMediaQuery';

import {RootRouter} from 'core/router';
import NavigateSetter from './core/router/NavigateSetter';
import React from 'react';
import {CoalaConstants as coalaConstants} from './core/utils/CoalaConstants';

function App() {
  const isPc = useMediaQuery('(min-width:1024px)');
  console.log(isPc);
  const isTablet = useMediaQuery('(min-width:600px) and (max-width:900px)');
  console.log(isTablet);
  const isMobile = useMediaQuery('(max-width:600px)');
  console.log(isMobile);

  // const isMobile = useMediaQuery({
  //   query: '(max-width:767px)',
  // });
  // const isTablet = useMediaQuery({
  //   query: '(min-width:768px) and (max-width:1023px)',
  // });
  // const isPc = useMediaQuery({
  //   query: '(min-width:1024px)',
  // });

  return (
    <div className="app">
      <NavigateSetter
        mediaMode={
          isMobile
            ? coalaConstants.MEDIA_MODE_TYPE_MOBILE
            : isTablet
            ? coalaConstants.MEDIA_MODE_TYPE_TABLET
            : coalaConstants.MEDIA_MODE_TYPE_PC
        }
      />
      <RootRouter />
    </div>
  );
}

export default App;
