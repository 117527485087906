import PropTypes from 'prop-types';
import React from 'react';
import {Box} from '@mui/material';
import {SfBookBox} from '../auth/SigninFormComps';

const ErrorPageComponent = props => {
  console.log(props);
  console.log(props.err.response.data.data);

  return (
    <SfBookBox
      sx={{
        backgroundImage: 'none !important',
        flexDirection: 'column',
        textAlign: 'left !important',
      }}
    >
      <Box
        sx={{
          width: '398px',
          height: '18px',
          margin: '20px 0 5px',
          fontSize: '25px',
          fontWeight: '500',
          color: '#4e5c43',
        }}
      >
        서비스 이용에 불편을 드려 죄송합니다.
      </Box>
      <Box
        sx={{
          width: '668px',
          height: '80px',
          margin: '23px 0 20px 2px',
          fontSize: '20px',
          color: '#4e5c43',
          lineHeight: '2rem',
        }}
      >
        <Box>
          연결하려는 페이지에 문제가 있어서 페이지를 표시할 수 없습니다.
        </Box>
        <Box pt={2} sx={{fontSize: '15px'}}>
          {props.err.response.data.message}
        </Box>
        <Box sx={{fontSize: '12px'}}>
          {props.err.response.data.data.substring(0, 100)}
        </Box>
        <Box pt={2}>더욱 좋은 서비스를 위해 노력하겠습니다.</Box>
      </Box>
    </SfBookBox>
  );
};

ErrorPageComponent.propTypes = {
  err: PropTypes.object,
};

export default ErrorPageComponent;
