import React, {useEffect, useState} from 'react';

import {Box, Button, FormControl, MenuItem} from '@mui/material';

import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import commonEssayReducer, {
  initAndSaveUserSettings,
} from 'core/reducer/essay/commonEssayReducer';
import essayRest from 'core/services/api/essay/essayRest';
import {initStructure} from 'core/reducer/essay/structureReducer';
import writingReducer, {initWriting} from 'core/reducer/essay/writingReducer';
import {initReading} from 'core/reducer/essay/readingReducer';
import {initContent} from 'core/reducer/essay/contentReducer';
import {ValidatorForm} from 'react-material-ui-form-validator';
import {DbSubmitUtils} from 'core/utils/DbSubmitUtils';
import {StringUtils} from 'core/utils/StringUtils';
import ApiLoading from '../commons/ApiLoading';
import {initAnalysis} from 'core/reducer/essay/analysisReducer';
import {
  SfBookBox,
  SfLabel,
  SfSelect,
} from 'view/components/auth/SigninFormComps';
import logo from 'assets/media/logo/logo1.png';
import logoCoala from 'assets/media/logo/logo_coala5.png';
import {DlgActionsCmm, DlgCmm, DlgContentCmm} from '../commons/DialogComps';
import ErrorPageComponent from '../commons/ErrorPageComponent';

const UserSettingsOptions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const authReducer = useSelector(state => state.authReducer);
  const commonEssayReducer = useSelector(state => state.commonEssayReducer);
  const contentReducer = useSelector(state => state.contentReducer);
  const writingReducer = useSelector(state => state.writingReducer);

  const [openLoadDlg, setOpenLoadDlg] = useState(false);
  const [apiContent, setApiContent] = useState(null);
  const [userSettings, setUserSettings] = useState({
    sid: authReducer.loginInfo.sid,
    mode: '',
    content: {value: '', text: ''},
  });

  useEffect(() => {
    console.log(
      '========================================== useEffect UserSettingsOptions',
    );
    console.log(commonEssayReducer);
    if (StringUtils.isEmpty(authReducer.loginInfo.sid)) {
      navigate('/signin', {replace: false});
      return;
    }

    const fetchPageData = async sid => {
      setError(null);
      console.log(sid);

      const selUserSettings = {
        sid: authReducer.loginInfo.sid,
        mode: commonEssayReducer.userSettingsOptions.modes[0].value,
        content: commonEssayReducer.userSettingsOptions.cids[0],
      };
      dispatch(
        initAndSaveUserSettings({
          userSettings: selUserSettings,
          clickableCompletedStepArr: [],
          activeStep: 0,
          workedLastStep: 0,
          activeSlide: 0,
          timerId: 0,
        }),
      );
      setUserSettings(selUserSettings);

      dispatch(initContent());
      dispatch(initReading());
      dispatch(initStructure());
      dispatch(initWriting());
      dispatch(initAnalysis());
      console.log(userSettings);
      // }
    };

    fetchPageData(authReducer.loginInfo.sid);
  }, []);

  const handleChangeMode = event => {
    console.log('handleChangeMode: ' + event.target.value);
    setUserSettings(prevState => ({
      ...prevState,
      mode: event.target.value + '',
    }));
  };

  const handleChangeContent = event => {
    console.log('handleChangeContent: ' + event.target.value);
    setUserSettings(prevState => ({
      ...prevState,
      content: {value: event.target.value, text: event.target.text},
    }));
  };

  const handleClickStartButton = event => {
    console.log('handleClickStartButton');

    if (StringUtils.isEmpty(contentReducer.content.id)) {
      const selUserSettings = {
        mode: userSettings.mode,
        content: userSettings.content,
      };
      dispatch(
        initAndSaveUserSettings({
          selUserSettings: selUserSettings,
          clickableCompletedStepArr: [],
          activeStep: 0,
          workedLastStep: 0,
          activeSlide: 0,
          timerId: 0,
        }),
      );

      const dbParams = {
        cmd: 'get',
        key: DbSubmitUtils.getDbKey(selUserSettings),
        context: '',
      };
      fetchContent(dbParams).then(r => {
        console.log('fetchContent End...');
      });
    }
  };

  const fetchContent = async dbParams => {
    // setLoading(true);
    setError(null);

    console.log(dbParams);
    console.log(
      'essayRest.getOrSaveDraftDb(dbParams) ----------------------------------------------------------------------------',
    );
    const res = await essayRest.getOrSaveDraftDb(dbParams).finally(() => {
      // setLoading(false);
    });
    console.log(res);
    if (res.data.context) {
      const contextStr = res.data.context;
      console.log(contextStr);
      console.log(
        'essayRest.getOrSaveDraftDb -> get Draft Db ----------------------------------------------------------------------------',
      );
      const content = JSON.parse(contextStr);
      setApiContent(content);
      setOpenLoadDlg(true);
    } else {
      await handleNewLoad();
    }
  };

  function handleSavedLoad() {
    DbSubmitUtils.settingContent(apiContent);
    setOpenLoadDlg(false);
    navigate('/essay', {replace: false});
  }

  async function handleNewLoad() {
    setOpenLoadDlg(false);
    const contentParams = {
      mode: userSettings.mode,
      cid: userSettings.content.value,
    };
    setLoading(true);
    console.log(
      'handleNewLoad essayRest -> getUserContent -----------------------------',
    );
    const res = await essayRest.getUserContent(contentParams).finally(() => {
      setLoading(false);
    });
    console.log(res);
    DbSubmitUtils.settingContent(res.data.result);
    navigate('/essay', {replace: false});
  }

  if (loading) return <ApiLoading loading={loading} />;

  if (error) return <ErrorPageComponent err={error} />;

  return (
    <SfBookBox className={'form1'}>
      <Box sx={{paddingLeft: '121px', paddingTop: '165px'}}>
        <img src={logoCoala} alt={'logo'} style={{width: '150px'}} />
      </Box>

      <Box
        sx={{
          width: '350px',
          paddingLeft: '12px',
          paddingTop: '175px',
        }}
      >
        <ValidatorForm onSubmit={handleClickStartButton}>
          <Box mb={2} sx={{display: 'flex'}}>
            <SfLabel>학습교재</SfLabel>
            <FormControl fullWidth required>
              <SfSelect
                id="content"
                value={userSettings.content.value}
                onChange={handleChangeContent}
              >
                {/*<MenuItem value="" className={classes.placeholder}>*/}
                {/*  선택해주세요.*/}
                {/*</MenuItem>*/}
                {commonEssayReducer.userSettingsOptions.cids.map(
                  (option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.text}
                    </MenuItem>
                  ),
                )}
              </SfSelect>
            </FormControl>
          </Box>

          <Box mb={2} sx={{display: 'flex'}}>
            <SfLabel>학습모드</SfLabel>
            <FormControl required fullWidth>
              <SfSelect
                id="mode"
                value={userSettings.mode}
                onChange={handleChangeMode}
              >
                {commonEssayReducer.userSettingsOptions.modes.map(mode => (
                  <MenuItem key={mode.value} value={mode.value}>
                    {mode.text}
                  </MenuItem>
                ))}
              </SfSelect>
            </FormControl>
          </Box>
          <Box pt={2} sx={{textAlign: 'right'}}>
            <Button
              variant="contained"
              color="info"
              onClick={handleClickStartButton}
            >
              시작하기
            </Button>
          </Box>

          {/*<Typography variant="caption" display="block" gutterBottom>*/}
          {/*  © TwoBlockAi 2022–2025*/}
          {/*</Typography>*/}
        </ValidatorForm>

        <DlgCmm open={openLoadDlg}>
          <DlgContentCmm>
            <Box pb={1}>작성 기록이 남아 있습니다.</Box>
            <Box>최종 작성 기록을 불러올까요?</Box>
          </DlgContentCmm>
          <DlgActionsCmm>
            <Button variant="outlined" color="warning" onClick={handleNewLoad}>
              처음부터 하기
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={handleSavedLoad}
              autoFocus
            >
              이어서 하기
            </Button>
          </DlgActionsCmm>
        </DlgCmm>

        <ApiLoading loading={loading} />
      </Box>
    </SfBookBox>
  );
};

export default UserSettingsOptions;
