import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Grid} from '@mui/material';

import {useDispatch} from 'react-redux';
import QuizContent from './QuizContent';
import SlickCarousel from './SlickCarousel';
import ApiLoading from '../../commons/ApiLoading';
import {
  CourseTitleWrapper,
  GreenDashBox,
  LeftWhiteBox,
  MissionBox,
  QnaTitleBox,
} from '../CourseComps';

const StepReading = () => {
  const readingReducer = useSelector(state => state.readingReducer);
  const contentReducer = useSelector(state => state.contentReducer);

  return (
    <CourseTitleWrapper>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={8} sx={{paddingTop: '16px !important'}}>
          <MissionBox>{contentReducer.content.mission}</MissionBox>
          <QnaTitleBox>
            {
              contentReducer.content.docs[readingReducer.activeDocIndex]
                .qna_title
            }
          </QnaTitleBox>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <LeftWhiteBox>
            <SlickCarousel activeReadingIndex={readingReducer.activeDocIndex} />
          </LeftWhiteBox>
        </Grid>
        <Grid item xs={12} md={4}>
          <GreenDashBox>
            <QuizContent />
          </GreenDashBox>
        </Grid>
      </Grid>
    </CourseTitleWrapper>
  );
};

export default StepReading;
