import {styled} from '@mui/material/styles';
import {TextValidator} from 'react-material-ui-form-validator';
import {Box, Button, Select} from '@mui/material';
import bgLogin from 'assets/media/bg/bg-login.png';

export const SfBookBox = styled(Box)(props => ({
  width: '100%',
  maxWidth: '706px',
  height: '536px',
  // padding: '15px',
  margin: 'auto',
  textAlign: 'center !important',
  fontSize: '1rem',
  backgroundImage: `url(${bgLogin}) !important`,
  backgroundRepeat: 'none',
  display: 'flex',
}));

export const SfLabel = styled(Box)(
  props => `
  width: 150px;
  font-size: 19px;
  font-weight: bold;
  color: #356115;
  text-align: right;
  padding-right: 1rem;
  padding-top: 3px;
}`,
);

export const SfControl = styled(Select)(props => ({}));

export const SfSelect = styled(Select)(props => ({
  textAlign: 'left',
  width: '240px',
  height: '37px',
  backgroundColor: '#FFFFFF !important',
  borderRadius: '8px',
  border: 'none',
}));

export const SfInput = styled(TextValidator)(props => ({
  border: 0,
  '& .MuiInputBase-root': {
    borderRadius: '8px',
    backgroundColor: '#fff',
    height: '37px',
  },
  '& .MuiInputBase-input': {
    padding: '0px 14px !important',
    height: '37px',
  },
  '& .MuiFormHelperText-root': {
    backgroundColor: 'transparent',
    margin: 0,
    padding: '3px 10px 0 10px',
    color: '#f27575 !important',
    fontSize: '12px',
  },
  '& .MuiFormHelperText-root:before': {
    content: 'url("/img/signin/helperIcon.png")',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    // border: '0',
  },
}));
