import PropTypes from 'prop-types';
import {useRef} from 'react';
import {
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from 'chart.js';
import {Radar} from 'react-chartjs-2';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
);

const RadarChart = props => {
  const radarChart = useRef();

  const options = {
    responsive: true,
    scales: {
      r: {
        beginAtZero: true,
        ticks: {
          max: 10,
          min: 0,
          stepSize: 2,
        },
        pointLabels: {
          font: {
            size: 15,
            weight: 'bold',
            family: 'Noto Sans KR',
            color: '#608445',
          },
        },
      },
    },
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          title: function (context) {
            try {
              const chartIndex = context[0].datasetIndex;
              const colIndex = context[0].dataIndex;
              let title = '';
              if (colIndex == 0) {
                title += '문법의 정확성';
              } else if (colIndex == 1) {
                title += '어휘의 풍부성';
              } else if (colIndex == 2) {
                title += '의도의 표현';
              } else if (colIndex == 3) {
                title += '문장 구사력';
              } else if (colIndex == 4) {
                title += '조직과 전개';
              } else {
                title += '주제의 명확성';
              }
              if (chartIndex == 0) {
                title += '(평균)';
              } else {
                title += '(회원)';
              }
              return title;
            } catch {
              return '';
            }
          },
          label: function (context) {
            const pointText = '점수 : ';
            if (context.raw == 9) {
              return pointText + 'A+';
            } else if (context.raw == 8) {
              return pointText + 'A';
            } else if (context.raw == 7) {
              return pointText + 'B+';
            } else if (context.raw == 6) {
              return pointText + 'B';
            } else if (context.raw == 5) {
              return pointText + 'C+';
            } else if (context.raw == 4) {
              return pointText + 'C';
            } else if (context.raw == 3) {
              return pointText + 'D+';
            } else if (context.raw == 2) {
              return pointText + 'D';
            } else if (context.raw == 1) {
              return pointText + 'F';
            } else {
              return '분석불가';
            }
          },
        },
      },
      legend: {
        display: true,
        onClick: function (event, legendItem) {
          //do nothing
        },
        position: 'right',
        align: 'end',
        labels: {
          font: {
            size: 15,
            weight: 'bold',
            family: 'Noto Sans KR',
            color: '#608445',
          },
        },
      },
    },
    onHover: props.hoverEvent,
  };

  return (
    <Radar
      ref={radarChart}
      data={props.radarData}
      options={options}
      width={props.width}
      height={props.height}
    />
  );
};

RadarChart.propTypes = {
  radarData: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  hoverEvent: PropTypes.func,
};

export default RadarChart;
