import {styled} from '@mui/material/styles';
import {Box, Grid, IconButton} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';

export const ItemBase = styled(Box)(({theme}) => ({
  textAlign: 'center',
  backgroundColor: '#fffcf3',
  border: '1px solid #578634',
  borderRadius: '10px',
  lineHeight: '1.5rem',
  fontSize: '28px',
  fontWeight: 'bold',
  color: '#5b6f4d',
  marginBottom: '4px',
}));

export const ItemBaseAtTop = styled(Box)(({theme}) => ({
  textAlign: 'center',
  backgroundColor: '#fffcf3',
  border: '1px solid #578634',
  borderRadius: '10px',
  lineHeight: '1.5rem',
  fontSize: '28px',
  fontWeight: 'bold',
  color: '#5b6f4d',
}));

export const AtGrid = styled(Grid)(({theme}) => ({
  display: 'table',
  textAlign: 'center',
  marginBottom: '4px',
  //paddingRight: '4px',
}));

export const AtGridRightEnd = styled(Grid)(({theme}) => ({
  display: 'table',
  textAlign: 'center',
  marginBottom: '4px',
}));

export const RadarDescItem = styled(Box)(({theme}) => ({
  display: 'table-cell',
  verticalAlign: 'middle',
  // padding: '0.5rem',
  border: 'solid 1px #578634',
  backgroundColor: '#fffcf3',
  color: '#4c5c41',
  fontSize: '22px',
}));
export const OverallItem = styled(Box)(({theme}) => ({
  display: 'table-cell',
  verticalAlign: 'middle',
  // padding: '0.5rem',
  //border: 'solid 1px #578634',
  //backgroundColor: '#fffcf3',
  //color: '#4c5c41',
  //fontSize: '22px',
  textAlign: 'center',
  border: '1px solid #578634',
  borderRadius: '10px',
  lineHeight: '1.5rem',
  fontSize: '28px',
  fontWeight: 'bold',
  backgroundColor: 'rgba(96, 132, 69, 1)',
  borderTopRightRadius: '0px',
  borderBottomRightRadius: '0px',
  color: '#fff',
}));

export const CustomWidthTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} classes={{popper: className}} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    maxWidth: 160,
    color: '#000000',
    border: '1px solid #FFAD33',
    marginBottom: '0px',
    display: 'inline-block',
    fontSize: '12px',
    padding: '0.5rem',
    wordBreak: 'keep-all',
  },
});

export function TooltipBox(props) {
  return (
    <Box
      style={{
        display: 'inline-flex',
      }}
    >
      <CustomWidthTooltip title={props.tooltipTitle} placement={'top-start'}>
        <IconButton
          className={'notPadding'}
          sx={{
            '& .MuiButtonBase-root': {
              padding: '0px !important',
            },
          }}
        >
          <HelpIcon sx={{fontSize: '1.2rem', color: '#f38c2d'}} />
        </IconButton>
      </CustomWidthTooltip>
      <span style={{marginLeft: '3px'}}>{props.title}</span>
    </Box>
  );
}
TooltipBox.propTypes = {
  tooltipTitle: PropTypes.string,
  title: PropTypes.string,
};

export const stName = {
  display: 'inline-block',
  width: '50%',
  color: '#4156a0',
  fontWeight: '700',
};
export const stNameContent = {
  display: 'inline-block',
  width: '50%',
  textAlign: 'right',
};
export const stContent = {
  display: 'inline-block',
  width: '60px',
  textAlign: 'right',
  fontWeight: 'normal',
  fontSize: '25px',
};

export const stLabel = {
  display: 'inline-block',
  width: '150px',
  fontWeight: 'normal',
  fontSize: '20px',
  textAlign: 'left',
};
export const stLabel2 = {
  display: 'inline-block',
  width: '150px',
  fontWeight: 'normal',
  fontSize: '20px',
  textAlign: 'left',
};
export const stContent2 = {
  display: 'inline-block',
  width: '80px',
  textAlign: 'right',
  fontWeight: 'normal',
  fontSize: '20px',
};
export const stScore = {
  color: '#f67f10',
  fontWeight: '700',
  fontSize: '20px',
};
export const stScore2 = {
  display: 'inline-block',
  color: '#f67f10',
  textAlign: 'left',
  fontWeight: '700',
  fontSize: '20px',
};
export const stScore3 = {
  display: 'inline-block',
  color: '#f67f10',
  width: '50px',
  textAlign: 'left',
  fontWeight: '700',
  fontSize: '20px',
};
export const commentLeft = {
  fontSize: '15px',
  fontWeight: '500',
  color: 'rgba(48, 48, 48, 1)',
};
export const commentRight = {
  fontWeight: '300',
  fontSize: '14px',
  letterSpacing: '-0.6px',
  color: 'rgba(48, 48, 48, 1)',
};
export const chipHelp = {
  fontSize: '10px',
  height: '15px',
  color: 'rgba(0, 0, 0, 1)',
  backgroundColor: 'white',
  border: '1px solid rgba(0, 0, 0, 1)',
};
export const chipHelp2 = {
  fontSize: '10px',
  height: '15px',
  color: 'rgba(255, 255, 255, 1)',
  backgroundColor: 'green',
};
export const coloredStyle = {
  coloredLabel: function (mycolor) {
    return {
      color: mycolor,
      fontSize: '17px',
    };
  },
  coloredText: function (mycolor) {
    return {
      backgroundColor: mycolor,
      fontSize: '16px',
    };
  },
};
