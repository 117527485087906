import {createAction, handleActions} from 'redux-actions';
import produce from 'immer';

const INIT_ANALYSIS = 'analysisReducer/initAnalysis';
const SAVE_SCORES = 'analysisReducer/saveScores';
const SAVE_TEACHER_SCORE = 'analysisReducer/saveTeacherScore';

export const initAnalysis = createAction(INIT_ANALYSIS, data => data);
export const saveScores = createAction(SAVE_SCORES, data => data);
export const saveTeacherScore = createAction(SAVE_TEACHER_SCORE, data => data);

const initialState = {
  scores: [],
  teacherScore: '-1',
};

const analysisReducer = handleActions(
  {
    [INIT_ANALYSIS]: state =>
      produce(state, draft => {
        console.log('INIT_ANALYSIS..................');
        draft.scores = [];
        draft.teacherScore = '-1';
      }),
    [SAVE_SCORES]: (state, action) =>
      produce(state, draft => {
        console.log('SAVE_SCORES..................');
        draft.scores = action.payload;
      }),
    [SAVE_TEACHER_SCORE]: (state, action) =>
      produce(state, draft => {
        console.log('SAVE_TEACHER_SCORE..................');
        draft.teacherScore = action.payload;
      }),
  },
  initialState,
);

export default analysisReducer;
