import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  TextField,
} from '@mui/material';
import React, {useEffect, useState} from 'react';

import {saveStepValues} from 'core/reducer/essay/commonEssayReducer';
import {useDispatch, useSelector} from 'react-redux';
import {
  markClickedBtn,
  saveAnswer,
  saveBtnValue,
} from 'core/reducer/essay/readingReducer';
import {CoalaConstants} from 'core/utils/CoalaConstants';
import {StringUtils} from 'core/utils/StringUtils';
import {
  QfControl,
  QuizButton,
  QuizCard,
  QuizCardHeader,
  QuizTextField,
} from '../CourseComps';

const QuizContent = props => {
  console.log('QuizContent ...............');
  const dispatch = useDispatch();

  const commonEssayReducer = useSelector(state => state.commonEssayReducer);
  const readingReducer = useSelector(state => state.readingReducer);
  const contentReducer = useSelector(state => state.contentReducer);

  const _getInitBtnValues = () => {
    return Array.from(
      readingReducer.uiQuizzes !== undefined &&
        Object.prototype.hasOwnProperty.call(
          readingReducer.uiQuizzes,
          readingReducer.activeDocIndex + '',
        )
        ? [...readingReducer.uiQuizzes[readingReducer.activeDocIndex].btnValues]
        : Array.from(
            {
              length:
                contentReducer.content.docs[readingReducer.activeDocIndex].qnas
                  .length,
            },
            () => CoalaConstants.DEFAULT_QUIZ_BTN_VALUE,
          ),
    );
  };

  const _getInitBtnDisable = () => {
    const btnDisableArr = Array.from(
      {
        length:
          contentReducer.content.docs[readingReducer.activeDocIndex].qnas
            .length,
      },
      () => true,
    );

    // const activeDoc = contentReducer.content.docs[readingReducer.activeDocIndex];
    const activeDoc = readingReducer.uiQuizzes[readingReducer.activeDocIndex];
    console.log(activeDoc);
    activeDoc.answers.forEach((quiz, quizIndex) => {
      // activeDoc.qnas.forEach((quiz, quizIndex) => {
      console.log(quiz);
      console.log(activeDoc.btnValues[quizIndex]);
      console.log(
        'activeDocIndex=' +
          readingReducer.activeDocIndex +
          ', qnaIndex' +
          quizIndex,
      );
      let btnDisable = false;
      if (
        StringUtils.isEmpty(quiz) ||
        (!StringUtils.isEmpty(quiz) &&
          activeDoc.btnValues[quizIndex] !==
            CoalaConstants.DEFAULT_QUIZ_BTN_VALUE)
      ) {
        btnDisable = true;
      }
      btnDisableArr[quizIndex] = btnDisable;
      console.log(btnDisableArr[quizIndex] + ' = ' + StringUtils.isEmpty(quiz));
    });

    console.log(btnDisableArr);
    return btnDisableArr;
  };

  const [btnValues, setBtnValues] = useState(_getInitBtnValues());
  const [btnDisable, setBtnDisable] = useState(_getInitBtnDisable());

  useEffect(() => {
    setBtnValues(_getInitBtnValues());
    setBtnDisable(_getInitBtnDisable());
  }, [readingReducer.activeDocIndex]);

  const handleChangeAnswer = e => {
    console.log('handleChangeAnswer.....' + e.target.value);
    const closest = e.target.closest('.quiz-card');
    const quizIndex = closest.getAttribute('data-index');
    console.log('quizIndex = ' + quizIndex);

    dispatch(
      saveAnswer({
        activeDocIndex: readingReducer.activeDocIndex,
        quizIndex: quizIndex,
        answer: e.target.value,
      }),
    );

    const newBtnDisable = [...btnDisable];
    newBtnDisable[quizIndex] = !(
      e.target.value &&
      btnValues[quizIndex] === CoalaConstants.DEFAULT_QUIZ_BTN_VALUE
    );
    setBtnDisable(newBtnDisable);
  };

  function handleClickAnswer(e) {
    console.log('handleClickAnswer.....');
    const closest = e.target.closest('.quiz-card');
    const quizId = closest.getAttribute('data-id');
    const quizIndex = closest.getAttribute('data-index');
    console.log('quizId = ' + quizId);
    console.log('quizIndex = ' + quizIndex);

    if (
      !(
        !readingReducer.uiQuizzes[readingReducer.activeDocIndex].answers[
          quizIndex
        ] || btnValues[quizIndex] === CoalaConstants.DEFAULT_QUIZ_BTN_VALUE
      )
    ) {
      return;
    }

    const newBtnValues = [...btnValues];
    newBtnValues[quizIndex] =
      contentReducer.content.docs[readingReducer.activeDocIndex].qnas[
        quizIndex
      ].a;
    setBtnValues(newBtnValues);

    dispatch(
      saveBtnValue({
        activeDocIndex: readingReducer.activeDocIndex,
        quizIndex: quizIndex,
        btnValue:
          contentReducer.content.docs[readingReducer.activeDocIndex].qnas[
            quizIndex
          ].a,
      }),
    );

    const newBtnDisable = [...btnDisable];
    newBtnDisable[quizIndex] = true;
    setBtnDisable(newBtnDisable);

    console.log('*******************************************************');
    console.log(Object.keys(readingReducer.clickedBtn).length);
    console.log(contentReducer.qnasCount);
    // if ((Object.keys(readingReducer.clickedBtn).length+1) === contentReducer.qnasCount) {
    if (Object.keys(readingReducer.clickedBtn).length + 1 >= 0) {
      console.log(
        'all completed props.qnas...........' +
          commonEssayReducer.clickableCompletedStep,
      );
      if (commonEssayReducer.workedLastStep <= commonEssayReducer.activeStep) {
        dispatch(
          saveStepValues({
            clickableCompletedStepsArr: [
              {index: commonEssayReducer.activeStep + 1, value: true},
            ],
          }),
        );
      }
    }

    dispatch(markClickedBtn(readingReducer.activeDocIndex + '-' + quizIndex));
  }

  function isCompleted(activeDocIndex, index) {
    return !!(
      StringUtils.isNotEmpty(
        readingReducer.uiQuizzes[readingReducer.activeDocIndex].answers[index],
      ) && btnValues[index] !== CoalaConstants.DEFAULT_QUIZ_BTN_VALUE
    );
  }

  if (
    !contentReducer.content.docs[readingReducer.activeDocIndex].qnas ||
    readingReducer.uiQuizzes === undefined
  ) {
    return null;
  }

  console.log(
    '--------------------------------------------------------==============',
  );
  console.log(btnDisable);

  return (
    <Box>
      {contentReducer.content.docs[readingReducer.activeDocIndex].qnas.map(
        (quiz, index) => (
          <QuizCard
            key={index}
            className={'quiz-card'}
            data-id={index}
            data-index={index}
          >
            <QuizCardHeader title={quiz.q} />
            <CardContent
              sx={{
                backgroundColor: '#ffddbe',
                paddingTop: '1rem',
                borderBottomLeftRadius: '20px',
                borderBottomRightRadius: '20px',
              }}
            >
              <QfControl fullWidth>
                <QuizTextField
                  required
                  multiline
                  rows={3}
                  value={
                    readingReducer.uiQuizzes[readingReducer.activeDocIndex]
                      .answers[index]
                  }
                  placeholder="이곳에 답을 적어주세요."
                  variant="outlined"
                  onChange={handleChangeAnswer}
                  inputProps={{'data-id': index, 'data-index': index}}
                  disabled={isCompleted(readingReducer.activeDocIndex, index)}
                  className={
                    isCompleted(readingReducer.activeDocIndex, index)
                      ? 'answerCompleted Mui-disabled'
                      : 'answerNotCompleted'
                  }
                />
              </QfControl>
              <QuizButton
                fullWidth
                variant={
                  !readingReducer.uiQuizzes[readingReducer.activeDocIndex]
                    .answers[index] ||
                  btnValues[index] === CoalaConstants.DEFAULT_QUIZ_BTN_VALUE
                    ? 'contained'
                    : 'outlined'
                }
                disabled={btnDisable[index]}
                id={'btnCheckAnswer' + quiz.id}
                onClick={handleClickAnswer}
                className={
                  isCompleted(readingReducer.activeDocIndex, index)
                    ? 'answerCompleted Mui-disabled'
                    : 'answerNotCompleted'
                }
              >
                <Box>
                  {
                    readingReducer.uiQuizzes[readingReducer.activeDocIndex]
                      .btnValues[index]
                  }{' '}
                </Box>
              </QuizButton>
            </CardContent>
          </QuizCard>
        ),
      )}
    </Box>
  );
};

export default QuizContent;
