import React from 'react';
import {Box, Card, CardContent, Chip, Divider, Typography} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import NotExistCorrection from 'assets/media/logo/not-exist-correction.png';
import PropTypes from 'prop-types';
import {WritingSpellingHelp} from '../CourseComps';

const Spelling = props => {
  return (
    <Box>
      {props.correction.length === 0 && (
        <Box
          sx={{
            textAlign: 'center',
            paddingTop: '3rem',
          }}
        >
          <div>
            <img
              src={NotExistCorrection}
              alt={'맞춤법 오류 결과 없음'}
              width={130}
            />
            <Box
              sx={{
                width: '13rem',
                margin: 'auto',
                marginTop: '1rem',
                textAlign: 'left',
                lineHeight: '1.5rem',
                color: '#334998',
              }}
            >
              맞춤법에 오류가 없네요 :) <br />
              작성한 글을 검토하고 <br />
              분석하기로 이동해 주세요.!
            </Box>
          </div>
        </Box>
      )}
      {props.correction.map((spelling, index) => {
        return (
          <Card
            sx={{
              width: '100%',
              marginBottom: '1rem',
              borderRadius: '14px',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
            }}
            key={index}
          >
            <CardContent
              className={'spelling-rec'}
              style={{padding: '1rem 0px', fontSize: '14px'}}
            >
              <Box sx={{display: 'flex'}}>
                <Box sx={{width: '90px'}}>
                  <ul>
                    <li>추천의견</li>
                  </ul>
                </Box>

                <Box
                  pl={3}
                  pr={2}
                  style={{
                    listStyleType: 'none',
                    margin: '0px',
                  }}
                >
                  <Box sx={{color: '#ff0000'}}>{spelling.word}</Box>
                  <Typography
                    style={{
                      paddingTop: '0.5rem',
                      lineHeight: '1.5rem',
                    }}
                  >
                    <ArrowDownwardIcon />
                  </Typography>
                  <Box sx={{color: '#0000ff'}}>{spelling.suggestion}</Box>
                </Box>
              </Box>

              <Divider style={{margin: '1rem 0px'}} />

              <Box sx={{display: 'flex'}}>
                <Box
                  sx={{
                    width: '90px',
                    paddingLeft: '22px',
                  }}
                >
                  <WritingSpellingHelp>도움말</WritingSpellingHelp>
                </Box>
                <Box
                  pl={3}
                  sx={{
                    paddingRight: '20px',
                    width: '100%',
                    lineHeight: '1.25',
                    fontSize: '12px',
                    color: '#848484',
                    marginBottom: '0.25rem',
                  }}
                >
                  {spelling.help}
                </Box>
              </Box>
            </CardContent>
          </Card>
        );
      })}
    </Box>
  );
};

Spelling.propTypes = {
  correction: PropTypes.array,
};

export default Spelling;
