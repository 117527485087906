import {createTheme} from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1b5e20',
      contrastText: '#fff',
    },
    info: {
      main: '#fa8e2b',
      contrastText: '#fff',
    },
    warning: {
      main: '#fa8e2b',
      contrastText: '#fff',
    },
    secondary: {
      main: '#4e8c21',
      contrastText: '#fff',
    },
    success: {
      main: '#4e8c21',
      contrastText: '#fff',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ownerState}) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'info' && {
              width: '148px',
              padding: '6px 12px',
              fontSize: '19px',
              fontWeight: '800',
              height: '40px',
              borderRadius: '50px',
              boxShadow: 'none',
              letterSpacing: '0.25rem',
            }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'warning' && {
              // width: '148px',
              padding: '6px 1.25rem',
              fontSize: '15px',
              fontWeight: 'bold',
              height: '37px',
              borderRadius: '50px',
              boxShadow: 'none',
            }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'warning' && {
              // width: '148px',
              padding: '6px 1.25rem',
              fontSize: '15px',
              fontWeight: 'bold',
              height: '37px',
              borderRadius: '50px',
              boxShadow: 'none',
            }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'secondary' && {
              // width: '148px',
              padding: '6px 1.25rem',
              fontSize: '15px',
              fontWeight: 'bold',
              height: '37px',
              borderRadius: '50px',
              boxShadow: 'none',
            }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'secondary' && {
              // width: '148px',
              padding: '6px 1.25rem',
              fontSize: '15px',
              fontWeight: 'bold',
              height: '37px',
              borderRadius: '50px',
              boxShadow: 'none',
            }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'success' && {
              width: '148px',
              padding: '6px 1.25rem',
              fontSize: '18px',
              fontWeight: 'bold',
              height: '45px',
              borderRadius: '8px',
              boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
            }),
        }),
      },
    },
  },
  typography: {
    fontFamily: [
      'Noto Sans KR',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
  },
});

export default theme;
