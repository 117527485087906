import React, {useEffect} from 'react';

import {Box, Container} from '@mui/material';
import StepReading from 'view/components/course/reading/StepReading';
import StepStructure from 'view/components/course/structure/StepStructure';
import StepWriting from 'view/components/course/writing/StepWriting';
import StepAnalysis from 'view/components/course/analysis/StepAnalysis';
import {useSelector} from 'react-redux';
import StepArchBar from 'view/components/commons/StepArchBar';
import StepSubmit from 'view/components/course/submit/StepSubmit';
import {StringUtils} from 'core/utils/StringUtils';
import {useNavigate} from 'react-router-dom';
import {
  isNotContent,
  isNotLogin,
  isNotUserSettingsOptions,
  notSelectedUserSettings,
} from '../../core/utils/AuthUserSettingsUtils';

const Essay = () => {
  const navigate = useNavigate();

  const authReducer = useSelector(state => state.authReducer);
  const commonEssayReducer = useSelector(state => state.commonEssayReducer);
  const contentReducer = useSelector(state => state.contentReducer);

  useEffect(() => {
    console.log('========================================== useEffect Essay');
    if (isNotLogin(authReducer.loginInfo.sid)) {
      console.log('Essay isNotLogin #############');
      navigate('/signin', {replace: false});
      return;
    }

    if (isNotUserSettingsOptions(commonEssayReducer.userSettingsOptions.cids)) {
      console.log('Essay isNotUserSettingsOptions #############');
      navigate('/signin', {replace: false});
      return;
    }

    console.log(commonEssayReducer.selUserSettings);
    if (notSelectedUserSettings(commonEssayReducer.selUserSettings)) {
      console.log('Essay notSelectedUserSettings #############');
      navigate('/usersettings', {replace: false});
      return;
    }

    if (isNotContent(contentReducer.content)) {
      console.log('Essay isNotContent #############');
      navigate('/usersettings', {replace: false});
    }
  }, []);

  if (
    isNotLogin(authReducer.loginInfo.sid) ||
    isNotUserSettingsOptions(commonEssayReducer.userSettingsOptions.cids) ||
    notSelectedUserSettings(commonEssayReducer.selUserSettings) ||
    isNotContent(contentReducer.content)
  ) {
    return <Box />;
  }

  return (
    <Box>
      <StepArchBar />
      {/*{GlobalHistory.mediaMode === CoalaConstants.MEDIA_MODE_TYPE_PC && (*/}
      {/*  <StepArchBar />*/}
      {/*)}*/}
      {/*{GlobalHistory.mediaMode === CoalaConstants.MEDIA_MODE_TYPE_TABLET && (*/}
      {/*  <StepMobileBar />*/}
      {/*)}*/}
      {/*{GlobalHistory.mediaMode === CoalaConstants.MEDIA_MODE_TYPE_MOBILE && (*/}
      {/*  <StepMobileBar />*/}
      {/*)}*/}
      <Container
        maxWidth={false}
        sx={{
          padding: '0rem 55px !important',
        }}
      >
        {commonEssayReducer.activeStep === 0 ? (
          <StepReading />
        ) : commonEssayReducer.activeStep === 1 ? (
          <StepStructure />
        ) : commonEssayReducer.activeStep === 2 ? (
          <StepWriting />
        ) : commonEssayReducer.activeStep === 3 ? (
          <StepAnalysis />
        ) : commonEssayReducer.activeStep === 4 ? (
          <StepSubmit />
        ) : (
          <div>Error</div>
        )}
      </Container>
    </Box>
  );
};

export default Essay;
