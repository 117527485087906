import PropTypes from 'prop-types';
import React from 'react';
import {Box} from '@mui/material';
import {StringUtils} from 'core/utils/StringUtils';
import {paddingRightBox, SlideIframeBox} from '../CourseComps';

const SlideContent = props => {
  console.log('SlideContent.................');
  console.log(props);

  if (!props.doc) {
    return null;
  } else {
    return (
      <Box>
        {props.doc.type === 'HTML' && (
          <SlideIframeBox>
            <iframe
              src={props.doc.url}
              name="webPageIframe"
              width="100%"
              height="100%"
              sandbox="allow-scripts allow-popups allow-same-origin"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              vspace="0"
            >
              <p>현재 사용 중인 브라우저는 iframe 요소를 지원하지 않습니다!</p>
            </iframe>
          </SlideIframeBox>
        )}
        {props.doc.type === 'PDF' && (
          <SlideIframeBox>
            <iframe
              src={
                'https://docs.google.com/gview?embedded=true&url=' +
                props.doc.url
              }
              name="webPageIframe"
              width="100%"
              height="100%"
              sandbox="allow-scripts allow-popups allow-same-origin"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              vspace="0"
            />
          </SlideIframeBox>
        )}
        {props.doc.type === 'TEXT' && (
          <SlideIframeBox>
            <Box className={'slide-content-text'}>{props.doc.url}</Box>
          </SlideIframeBox>
        )}
        {props.doc.type === 'HTMLTEXT' && (
          <SlideIframeBox>
            <Box className={'slide-content-text'}>
              <Box
                dangerouslySetInnerHTML={StringUtils.createMarkup(
                  props.doc.url,
                )}
              />
            </Box>
          </SlideIframeBox>
        )}
      </Box>
    );
  }
};

SlideContent.propTypes = {
  doc: PropTypes.object,
};

export default SlideContent;
