import {createAction, handleActions} from 'redux-actions';
import produce from 'immer';

const INIT_STRUCTURE = 'structureReducer/initStructure';
const SAVE_STRUCTURE_BOXES_ALL = 'structureReducer/saveStructureBoxesAll';
const SAVE_STRUCTURE_BOX_TITLE = 'structureReducer/saveStructureBoxTitle';
const SAVE_STRUCTURE_BOX_TEXT = 'structureReducer/saveStructureBoxText';
const ADD_STRUCTURE_BOX = 'structureReducer/addStructureBox';
const REMOVE_STRUCTURE_BOX = 'structureReducer/removeStructureBox';
const SAVE_STRUCTURE_BOX_WHEN_DROPPED =
  'structureReducer/saveStructureBoxWhenDropped';
const SAVE_HINT = 'structureReducer/saveHint';

export const initStructure = createAction(INIT_STRUCTURE, data => data);
export const saveStructureBoxesAll = createAction(
  SAVE_STRUCTURE_BOXES_ALL,
  data => data,
);
export const saveStructureBoxTitle = createAction(
  SAVE_STRUCTURE_BOX_TITLE,
  data => data,
);
export const saveStructureBoxText = createAction(
  SAVE_STRUCTURE_BOX_TEXT,
  data => data,
);
export const addStructureBox = createAction(ADD_STRUCTURE_BOX, data => data);
export const removeStructureBox = createAction(
  REMOVE_STRUCTURE_BOX,
  data => data,
);
export const saveStructureBoxWhenDropped = createAction(
  SAVE_STRUCTURE_BOX_WHEN_DROPPED,
  data => data,
);
export const saveHint = createAction(SAVE_HINT, data => data);

const initialState = {
  selectedHintIndex: 0,
  structureBoxes: [],
};

const structureReducer = handleActions(
  {
    [INIT_STRUCTURE]: (state, action) =>
      produce(state, draft => {
        console.log('INIT_STRUCTURE..................');
        draft.structureBoxes = [];
        draft.selectedHintIndex = 0;
      }),
    [SAVE_STRUCTURE_BOXES_ALL]: (state, action) =>
      produce(state, draft => {
        console.log(
          'SAVE_STRUCTURE_BOXES_ALL..................' + action.payload.mode,
        );
        draft.structureBoxes = action.payload.boxMap.structureBoxes;
      }),
    [SAVE_STRUCTURE_BOX_TITLE]: (state, action) =>
      produce(state, draft => {
        console.log('SAVE_STRUCTURE_BOX_TITLE..................');
        draft.structureBoxes[action.payload.index].title = action.payload.title;
      }),
    [SAVE_STRUCTURE_BOX_TEXT]: (state, action) =>
      produce(state, draft => {
        console.log('SAVE_STRUCTURE_BOX_TEXT..................');
        console.log(state);
        console.log(action);
        draft.structureBoxes[action.payload.index].text = action.payload.text;
      }),
    [ADD_STRUCTURE_BOX]: (state, action) =>
      produce(state, draft => {
        console.log(
          'ADD_STRUCTURE_BOX..................' + action.payload.clickedIndex,
        );

        console.log(state.structureBoxes);
        console.log(draft.structureBoxes);
        const items = [...state.structureBoxes];
        items.splice(action.payload.clickedIndex + 1, 0, {
          noid: -1,
          title: '',
          text: '',
          collapsed: 'false',
          boxIndex: -1,
          added: true,
        });
        draft.structureBoxes = items;
        console.log(draft.structureBoxes);
      }),
    [REMOVE_STRUCTURE_BOX]: (state, action) =>
      produce(state, draft => {
        console.log(
          'REMOVE_STRUCTURE_BOX..................' +
            action.payload.clickedIndex,
        );

        console.log(state.structureBoxes);
        console.log(draft.structureBoxes);
        const items = [...state.structureBoxes];
        items.splice(action.payload.clickedIndex, 1);
        draft.structureBoxes = items;
        console.log(draft.structureBoxes);
      }),
    [SAVE_STRUCTURE_BOX_WHEN_DROPPED]: (state, action) =>
      produce(state, draft => {
        console.log(
          'SAVE_STRUCTURE_BOX_WHEN_DROPPED.................. action.payload',
        );

        console.log(action.payload);
        console.log(state.structureBoxes);
        console.log(draft.structureBoxes);
        draft.structureBoxes = action.payload;
      }),
    [SAVE_HINT]: (state, action) =>
      produce(state, draft => {
        console.log(
          'SAVE_HINT.................. action.payload' + action.payload,
        );

        draft.selectedHintIndex = action.payload.selectedHintIndex;
        draft.structureBoxes = action.payload.structureBoxes;
      }),
  },
  initialState,
);

export default structureReducer;
