import React, {useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Box, Button, FormControl, Typography} from '@mui/material';
import authRest from 'core/services/api/auth/authRest';
import {StringUtils} from 'core/utils/StringUtils';
import {CoalaConstants} from 'core/utils/CoalaConstants';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {saveLoginData} from 'core/reducer/common/authReducer';
import logo from 'assets/media/logo/logo1.png';
import logoCoala from 'assets/media/logo/logo_coala5.png';
import {
  initAndSaveUserSettings,
  saveUserSettingsOptions,
} from 'core/reducer/essay/commonEssayReducer';
import {initContent} from 'core/reducer/essay/contentReducer';
import {initReading} from 'core/reducer/essay/readingReducer';
import {initStructure} from 'core/reducer/essay/structureReducer';
import {initWriting} from 'core/reducer/essay/writingReducer';
import {initAnalysis} from 'core/reducer/essay/analysisReducer';
import {
  SfBookBox,
  SfInput,
  SfLabel,
} from 'view/components/auth/SigninFormComps';

const SigninForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [coalaLogin, setCoalaLogin] = useState({
    sid: '',
    pwd: '',
    submitted: false,
  });

  const authReducer = useSelector(state => state.authReducer);
  const commonEssayReducer = useSelector(state => state.commonEssayReducer);
  // console.log('StepReading. start authReducer................ = ');
  // console.log(authReducer.loginInfo);

  useEffect(() => {
    ValidatorForm.addValidationRule('sid', value => {
      return !(
        StringUtils.isEmpty(value)
        // || !value.startsWith('s-')
      );
    });
    ValidatorForm.addValidationRule('pwd', value => {
      return !(
        StringUtils.isEmpty(value)
        // || !value.startsWith('s-')
      );
    });

    return () => {
      ValidatorForm.removeValidationRule('sid');
      ValidatorForm.removeValidationRule('pwd');
    };
  }, []);

  function handleChange(event) {
    const {name, value} = event.target;
    setCoalaLogin({...coalaLogin, [name]: value});
  }

  const handleSubmitSettingLogin = async event => {
    const res = await authRest.signin({
      loginId: coalaLogin.sid,
      password: coalaLogin.pwd,
    });

    console.log(res);
    console.log(authReducer.loginInfo);
    if (
      res.result === CoalaConstants.RESULT_SUCC &&
      !StringUtils.isEmpty(res.data.jwtToken.key)
    ) {
      dispatch(saveLoginData(res.data.jwtToken));
      console.log(res);
      const dataSettingLogin = res.data.settingLogin;

      dispatch(
        initAndSaveUserSettings({
          age: dataSettingLogin.age,
          name: dataSettingLogin.name,
          userSettingsOptions: {
            cids: dataSettingLogin.cids || [],
            modes: dataSettingLogin.modes || [],
          },
          selUserSettings: {
            // sid: authReducer.loginInfo.sid,
            mode: dataSettingLogin.modes[0].value,
            content: dataSettingLogin.cids[0],
          },
          clickableCompletedStepArr: [],
          activeStep: 0,
          workedLastStep: 0,
          activeSlide: 0,
          timerId: 0,
        }),
      );

      dispatch(initContent());
      dispatch(initReading());
      dispatch(initStructure());
      dispatch(initWriting());
      dispatch(initAnalysis());

      setLoading(false);
      // console.log(userSettings);

      navigate('/usersettings', {replace: false});
    } else {
      alert(res.message);
    }
    return false;
  };

  return (
    <SfBookBox className={'form1'}>
      <Box sx={{paddingLeft: '121px', paddingTop: '165px'}}>
        <img src={logoCoala} alt={'logo'} style={{width: '150px'}} />
      </Box>

      <Box
        sx={{
          width: '350px',
          paddingLeft: '12px',
          paddingTop: '175px',
        }}
      >
        <ValidatorForm onSubmit={handleSubmitSettingLogin}>
          <Box mb={2} sx={{display: 'flex'}}>
            <SfLabel>아이디</SfLabel>
            <FormControl fullWidth>
              <SfInput
                name={'sid'}
                // label="학습자 ID"
                required
                value={coalaLogin.sid}
                onChange={handleChange}
                validators={['required', 'sid']}
                errorMessages={[
                  '학습자 ID를 입력하세요.',
                  '학습자 ID를 반드시 입력하세요.',
                ]}
                fullWidth
              />
            </FormControl>
          </Box>
          <Box mb={2} sx={{display: 'flex'}}>
            <SfLabel>비밀번호</SfLabel>
            <FormControl fullWidth sx={{}}>
              <SfInput
                name={'pwd'}
                type="password"
                // label="암호"
                required
                value={coalaLogin.pwd}
                onChange={handleChange}
                validators={['required', 'pwd']}
                errorMessages={[
                  '암호를 입력하세요.',
                  '암호를 반드시 입력하세요.',
                ]}
                fullWidth
              />
            </FormControl>
          </Box>

          <Box pt={2} sx={{textAlign: 'right'}}>
            <Button variant="contained" color="info" type="submit">
              로그인
            </Button>
          </Box>
        </ValidatorForm>
      </Box>
    </SfBookBox>
  );
};

export default SigninForm;
