import {createAction, handleActions} from 'redux-actions';
import produce from 'immer';

const INIT_WRITING = 'writingReducer/initWriting';
const SAVE_WRITING_FORCED = 'writingReducer/saveWritingForced';

export const initWriting = createAction(INIT_WRITING, data => data);
export const saveWritingForced = createAction(
  SAVE_WRITING_FORCED,
  data => data,
);

const initialState = {
  writing: '',
  initialized: false,
};

const writingReducer = handleActions(
  {
    [INIT_WRITING]: (state, action) =>
      produce(state, draft => {
        console.log('INIT_UNDERSTANDING..................');
        draft.writing = '';
        draft.initialized = false;
      }),
    [SAVE_WRITING_FORCED]: (state, action) =>
      produce(state, draft => {
        console.log('SAVE_WRITING_FORCED..................');
        draft.writing = action.payload;
        draft.initialized = true;
      }),
  },
  initialState,
);

export default writingReducer;
