import activeStep1 from 'assets/media/steparch-green/1-active.png';
import completedStep1 from 'assets/media/steparch-green/1-completed.png';
import disableStep1 from 'assets/media/steparch-green/1-disable.png';

import activeStep2 from 'assets/media/steparch-green/2-active.png';
import completedStep2 from 'assets/media/steparch-green/2-completed.png';
import disableStep2 from 'assets/media/steparch-green/2-disable.png';

import activeStep3 from 'assets/media/steparch-green/3-active.png';
import completedStep3 from 'assets/media/steparch-green/3-completed.png';
import disableStep3 from 'assets/media/steparch-green/3-disable.png';

import activeStep4 from 'assets/media/steparch-green/4-active.png';
import completedStep4 from 'assets/media/steparch-green/4-completed.png';
import disableStep4 from 'assets/media/steparch-green/4-disable.png';

import activeStep5 from 'assets/media/steparch-green/5-active.png';
import completedStep5 from 'assets/media/steparch-green/5-completed.png';
import disableStep5 from 'assets/media/steparch-green/5-disable.png';

import bgHeaderBar from 'assets/media/steparch-green/bg-header-bar.png';

import React, {useEffect, useState} from 'react';

import {
  Box,
  Button,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
} from '@mui/material';

import LogoBrand from 'assets/media/logo/home.png';
import LogoCoala from 'assets/media/logo/logo_coala5.png';
import {useDispatch, useSelector} from 'react-redux';
import {saveStepValues} from 'core/reducer/essay/commonEssayReducer';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';

import {CoalaConstants} from 'core/utils/CoalaConstants';
import DraftTimer from './DraftTimer';
import {useNavigate} from 'react-router-dom';
import essayRest from 'core/services/api/essay/essayRest';
import {DbSubmitUtils} from 'core/utils/DbSubmitUtils';
import {StringUtils} from 'core/utils/StringUtils';
import {ValidatorForm, SelectValidator} from 'react-material-ui-form-validator';
import {saveTeacherScore} from 'core/reducer/essay/analysisReducer';
import {DlgActionsCmm, DlgCmm, DlgContentCmm} from './DialogComps';
import writingReducer, {
  saveWritingForced,
} from '../../../core/reducer/essay/writingReducer';

const steps = ['글읽기', '구조짜기', '글쓰기', '분석하기', '제출하기'];

const stepIcons = {
  activeStep1: activeStep1,
  completedStep1: completedStep1,
  disableStep1: disableStep1,

  activeStep2: activeStep2,
  completedStep2: completedStep2,
  disableStep2: disableStep2,

  activeStep3: activeStep3,
  completedStep3: completedStep3,
  disableStep3: disableStep3,

  activeStep4: activeStep4,
  completedStep4: completedStep4,
  disableStep4: disableStep4,

  activeStep5: activeStep5,
  completedStep5: completedStep5,
  disableStep5: disableStep5,
};

const StepArchBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log('================StepArchBar');

  const [openSubmitDlg, setOpenSubmitDlg] = useState(false);
  const [openEvalDlg, setOpenEvalDlg] = useState(false);
  const [teacherScore, setTeacherScore] = useState('-1');

  const [clickedStepIndexDlg, setClickedStepIndexDlg] = useState(-1);
  const [beforeActiveStepIndexDlg, setBeforeActiveStepIndexDlg] = useState(-1);

  const commonEssayReducer = useSelector(state => state.commonEssayReducer);
  const writingReducer = useSelector(state => state.writingReducer);
  const structureReducer = useSelector(state => state.structureReducer);

  function changeClickableArray(clickableCompletedStepsArr, param) {
    const findIndex = clickableCompletedStepsArr.findIndex(
      o => o.index === param.index,
    );
    if (findIndex === -1) {
      clickableCompletedStepsArr.push(param);
    } else {
      clickableCompletedStepsArr[findIndex] = param;
    }
  }

  useEffect(() => {
    console.log('StepArchBar useEffect.............. ');
    console.log(
      'StepArchBar useEffect workedLastStep = ' +
        commonEssayReducer.workedLastStep,
    );
    console.log('StepArchBar useEffect clickableCompletedStep = ');
    console.log(commonEssayReducer.clickableCompletedStep);

    if (
      commonEssayReducer.workedLastStep === CoalaConstants.STEP_INDEX_SUBMIT
    ) {
      const stepValues = {
        workedLastStep: CoalaConstants.STEP_INDEX_SUBMIT,
        activeStep: CoalaConstants.STEP_INDEX_SUBMIT,
        clickableCompletedStepsArr: [
          {index: 0, value: false},
          {index: 1, value: false},
          {index: 2, value: false},
          {index: 3, value: false},
          {index: 4, value: true},
        ],
      };
      console.log('stepValues last ..............................');
      const res = dispatch(saveStepValues(stepValues));
      return;
    }

    const clickableCompletedStepsArr = [];
    steps.forEach((value, index) => {
      if (index <= commonEssayReducer.workedLastStep) {
        clickableCompletedStepsArr.push({
          index: index,
          value: true,
        });
      } else {
        clickableCompletedStepsArr.push({
          index: index,
          value: false,
        });
      }
    });

    //글쓰기버튼이 active일 경우 글이 채워져 있는 경우에만 분석하기 버튼을 클릭할수 있게 하고
    if (commonEssayReducer.activeStep <= CoalaConstants.STEP_INDEX_WRITING) {
      console.log(writingReducer.writing);
      const plainText = writingReducer.writing;
      if (StringUtils.isEmpty(plainText.trim())) {
        changeClickableArray(clickableCompletedStepsArr, {
          index: CoalaConstants.STEP_INDEX_ANALYSIS,
          value: false,
        });
        changeClickableArray(clickableCompletedStepsArr, {
          index: CoalaConstants.STEP_INDEX_SUBMIT,
          value: false,
        });
      } else {
        changeClickableArray(clickableCompletedStepsArr, {
          index: CoalaConstants.STEP_INDEX_ANALYSIS,
          value: true,
        });
        changeClickableArray(clickableCompletedStepsArr, {
          index: CoalaConstants.STEP_INDEX_SUBMIT,
          value: false,
        });
      }
    }

    //분석하기버튼이 active일 경우 제출하기 버튼을 클릭할수 있게 한다.
    if (commonEssayReducer.activeStep === CoalaConstants.STEP_INDEX_ANALYSIS) {
      clickableCompletedStepsArr.push({
        index: CoalaConstants.STEP_INDEX_SUBMIT,
        value: true,
      });
    }

    if (clickableCompletedStepsArr.length !== 0) {
      dispatch(
        saveStepValues({
          clickableCompletedStepsArr: clickableCompletedStepsArr,
        }),
      );
    }
  }, []);

  function handleCloseSubmit() {
    console.log(
      'handleCloseSubmit--------------------------------------------',
    );
    console.log('timerId = ' + commonEssayReducer.timerId);
    clearTimeout(commonEssayReducer.timerId);
    const stepValues = {
      workedLastStep: CoalaConstants.STEP_INDEX_SUBMIT,
      activeStep: CoalaConstants.STEP_INDEX_SUBMIT,
      clickableCompletedStepsArr: [
        {index: 0, value: false},
        {index: 1, value: false},
        {index: 2, value: false},
        {index: 3, value: false},
        {index: 4, value: true},
      ],
    };
    console.log('stepValues last ..............................');
    const res = dispatch(saveStepValues(stepValues));

    setOpenSubmitDlg(false);
  }

  const handleClickedStep = async e => {
    const closest = e.target.closest('.MuiStep-root');
    const closestId = closest.getAttribute('id');
    const clickedStepIndex = Number(closestId.substring(4));
    const beforeActiveStepIndex = commonEssayReducer.activeStep;

    console.log(closest);
    console.log('closestId = ' + closestId);
    console.log('clickedStepIndex =' + clickedStepIndex);
    console.log('beforeActiveStepIndex =' + beforeActiveStepIndex);
    console.log(
      'commonEssayReducer.workedLastStep = ' +
        commonEssayReducer.workedLastStep,
    );
    console.log(commonEssayReducer.clickableCompletedStep);

    setClickedStepIndexDlg(clickedStepIndex);
    setBeforeActiveStepIndexDlg(beforeActiveStepIndex);
    if (clickedStepIndex === commonEssayReducer.activeStep) {
      console.log('clicked activeStep again');
      return;
    }

    if (
      commonEssayReducer.clickableCompletedStep[clickedStepIndex] ===
        undefined ||
      commonEssayReducer.clickableCompletedStep[clickedStepIndex] === false
    ) {
      console.log('비활성화 버튼을 클릭');
      return;
    }

    if (
      commonEssayReducer.workedLastStep === CoalaConstants.STEP_INDEX_SUBMIT
    ) {
      console.log('submit again - nothing');
      return;
    }

    if (clickedStepIndex === CoalaConstants.STEP_INDEX_ANALYSIS) {
      if (beforeActiveStepIndex === CoalaConstants.STEP_INDEX_WRITING) {
        console.log('clicked Analysis');
        //setOpenEvalDlg(true);
        handleTeacherScore();
        return;
      }
    }

    await clickedStepMenu(clickedStepIndex, beforeActiveStepIndex);
  };

  async function clickedStepMenu(clickedStepIndex, beforeActiveStepIndex) {
    if (clickedStepIndex === CoalaConstants.STEP_INDEX_SUBMIT) {
      console.log('clicked submit first');
      setOpenSubmitDlg(true);
      return;
    }

    const workedLastStep =
      commonEssayReducer.workedLastStep < clickedStepIndex
        ? clickedStepIndex
        : commonEssayReducer.workedLastStep;

    const clickableCompletedStepsArr = [
      {index: beforeActiveStepIndex, value: true},
      {index: clickedStepIndex, value: true},
    ];

    //글쓰기를 클릭했을 경우 글이 써있으면 자동으로 분석하기도 활성화시킨다.
    console.log(writingReducer.writing);
    if (clickedStepIndex === CoalaConstants.STEP_INDEX_WRITING) {
      let plainText = '';
      if (
        beforeActiveStepIndex === CoalaConstants.STEP_INDEX_STRUCTURE &&
        !writingReducer.initialized
      ) {
        plainText = DbSubmitUtils.getStructurePlainText(
          structureReducer.structureBoxes,
        );
        dispatch(saveWritingForced(plainText));
      } else {
        plainText = writingReducer.writing;
      }

      clickableCompletedStepsArr.push({
        index: CoalaConstants.STEP_INDEX_ANALYSIS,
        value: StringUtils.isNotEmpty(plainText),
      });

      if (StringUtils.isEmpty(plainText.trim())) {
        clickableCompletedStepsArr.push({
          index: CoalaConstants.STEP_INDEX_SUBMIT,
          value: false,
        });
      }
    }

    //분석하기를 클릭했을 경우 자동으로 제출하기도 활성화시킨다.
    if (clickedStepIndex === CoalaConstants.STEP_INDEX_ANALYSIS) {
      clickableCompletedStepsArr.push({
        index: CoalaConstants.STEP_INDEX_SUBMIT,
        value: true,
      });
    }

    const paramsStep = {
      clickableCompletedStepsArr: clickableCompletedStepsArr,
      activeStep: clickedStepIndex,
      workedLastStep: workedLastStep,
    };
    dispatch(saveStepValues(paramsStep));

    const context = DbSubmitUtils.createContext(paramsStep);
    await essayRest
      .getOrSaveDraftDb({
        cmd: 'put',
        key: DbSubmitUtils.getDbKey(commonEssayReducer.selUserSettings),
        context: JSON.stringify(context),
      })
      .then(res => {
        console.log(res);
      });
    // .finally(() => {});
  }

  const ColorlibConnector = styled(StepConnector)(({theme}) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 25,
      left: 'calc(-50% + 40px)',
      // right: 'calc(50% + 20px)',
      width: '5px',
      height: '28px',
      // marginLeft: '12px',
      backgroundSize: '12px',
      backgroundRepeat: 'no-repeat',
      // backgroundImage: `url(${arrowDisable})`,
      backgroundImage: 'none',
    },
    [`&.${stepConnectorClasses.active}`]: {
      // backgroundImage: `url(${arrowActive}) !important`,
      backgroundImage: 'none',
    },
    [`&.${stepConnectorClasses.completed}`]: {
      // backgroundImage: `url(${arrowActive}) !important`,
      backgroundImage: 'none',
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderTopWidth: 0,
    },
  }));

  function ColorlibStepIcon(props) {
    // console.log('ColorlibStepIcon....................... props');
    // console.log(props);
    const {active, completed, className, icon} = props;
    let status = active ? 'active' : completed ? 'completed' : 'disable';

    return (
      <Box
        className={classnames(
          'stepWrapper',
          active ? 'Mui-active' : '',
          completed ? 'Mui-completed' : '',
        )}
        sx={{
          textAlign: 'right',
        }}
      >
        <img
          src={stepIcons[status + 'Step' + icon]}
          // className="stepIconImage"
          alt={'menu'}
          height={67}
        />
      </Box>
    );
  }

  ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
    icon: PropTypes.node,
  };

  function logoClick() {
    navigate('/', {replace: false});
  }

  function handleTeacherScore() {
    console.log('handleEvalScore');
    if (StringUtils.isEmpty(teacherScore)) {
      alert('선생님께서 글의 점수를 선택해 주세요.');
      return false;
    }
    //setOpenEvalDlg(false);
    console.log('점수 : ' + teacherScore);
    dispatch(saveTeacherScore(teacherScore));
    clickedStepMenu(
      CoalaConstants.STEP_INDEX_ANALYSIS,
      CoalaConstants.STEP_INDEX_WRITING,
    ).then(r => console.log(r));
    //clickedStepMenu(clickedStepIndexDlg, beforeActiveStepIndexDlg).then(r =>
    //  console.log(r),
    //);
  }

  return (
    <Box>
      <Box
        className={'headerBox'}
        sx={{
          backgroundImage: `url(${bgHeaderBar})`,
          backgroundRepeat: 'repeat',
          // backgroundColor: '#e0e1ef',
          // boxShadow:
          //   'rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px',
          width: '100%',
          textAlign: 'center !important',
          height: '88px !important',
        }}
      >
        <Box
          pt={1}
          sx={{
            height: '60px',
            display: {xs: 'none', sm: 'none', md: 'block'},
            width: {xs: '0px', md: '60px', lg: '180px'},
            position: 'absolute',
            top: '10px',
            left: {xs: '0px', md: '30px', lg: '20px'},
            textAlign: 'left',
          }}
        >
          <img
            src={LogoCoala}
            alt={'코알라'}
            onClick={() => logoClick()}
            style={{
              marginTop: '10px',
              width: '60px',
              cursor: 'pointer',
            }}
          />
        </Box>

        <Box
          sx={{
            padding: '0',
            margin: '0 auto',
            flexGrow: '1',
          }}
        >
          <Box
            sx={{
              width: '800px',
              margin: 'auto',
              paddingTop: '21px',
            }}
          >
            <Stepper
              nonLinear
              alternativeLabel
              activeStep={commonEssayReducer.activeStep}
              connector={<ColorlibConnector />}
            >
              {steps.map((label, index) => (
                <Step
                  key={label}
                  completed={commonEssayReducer.clickableCompletedStep[index]}
                  onClick={handleClickedStep}
                  variant={'outlined'}
                  id={'step' + index}
                  sx={{
                    paddingLeft: '2px',
                    paddingRight: '2px',
                    marginRight: '0px',
                  }}
                >
                  <StepLabel
                    color="inherit"
                    StepIconComponent={ColorlibStepIcon}
                    sx={{
                      '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
                        marginTop: '6px !important',
                      },
                    }}
                  />
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>
      </Box>
      <DraftTimer />

      <DlgCmm
        open={openSubmitDlg}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiPaper-root': {
            border: 'solid 1.5px #c1b0a0',
            borderRadius: '20px',
            // backgroundColor: '#fefcf8',
          },
        }}
      >
        <DlgContentCmm sx={{width: '400px', paddingLeft: '3rem'}}>
          <Box pb={1}>글을 제출하면 수정할 수가 없어요.</Box>
          <Box>&quot;제출하기&quot;를 할까요?</Box>
        </DlgContentCmm>
        <DlgActionsCmm
          sx={{
            padding: '0 2rem 1.5rem 0',
            textAlign: 'right',
          }}
        >
          <Button
            variant="outlined"
            color="warning"
            onClick={e => setOpenSubmitDlg(false)}
          >
            취소하기
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={handleCloseSubmit}
            autoFocus
          >
            제출하기
          </Button>
        </DlgActionsCmm>
      </DlgCmm>
    </Box>
  );
};

export default StepArchBar;
