import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import storage from 'redux-persist/lib/storage';
import authReducer from 'core/reducer/common/authReducer';
import loadingReducer from 'core/reducer/common/loadingReducer';
import commonEssayReducer from 'core/reducer/essay/commonEssayReducer';
import contentReducer from 'core/reducer/essay/contentReducer';
import readingReducer from 'core/reducer/essay/readingReducer';
import structureReducer from 'core/reducer/essay/structureReducer';
import writingReducer from 'core/reducer/essay/writingReducer';
import analysisReducer from 'core/reducer/essay/analysisReducer';

import textLengthReducer from 'core/reducer/essay/textLengthReducer';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  // storage: storage,
  whitelist: [
    'commonEssayReducer',
    'authReducer',
    'contentReducer',
    'readingReducer',
    'structureReducer',
    'writingReducer',
    'analysisReducer',
    'textLengthReducer',
  ],
};

const rootReducer = combineReducers({
  commonEssayReducer: commonEssayReducer,
  authReducer: authReducer,
  contentReducer: contentReducer,
  readingReducer: readingReducer,

  structureReducer: structureReducer,
  writingReducer: writingReducer,
  analysisReducer: analysisReducer,

  textLengthReducer: textLengthReducer,

  // for async
  loadingReducer: loadingReducer,
});

// export default rootReducer;
export default persistReducer(persistConfig, rootReducer);
