import {styled} from '@mui/material/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import {TextValidator} from 'react-material-ui-form-validator';
import PropTypes, {object} from 'prop-types';

export const CourseTitleWrapper = styled(Box)(props => ({
  minHeight: 'calc(100vh - 312px) !important',
  // backgroundColor: 'skyblue',
  paddingBottom: '30px',
}));

export const paddingRightBox = {
  firstPadding: 10,
  secondPaddingVertical: 20,
  height: 928,
  height940: 928,
  height1200: 1000,
};

export const WritingNoteBox = props => {
  const sx = Object.assign(
    {
      height: paddingRightBox.height + paddingRightBox.firstPadding + 'px',
      '@media screen and (min-height: 940px) and (max-height: 1200px)': {
        height: paddingRightBox.height940 + paddingRightBox.firstPadding + 'px',
      },
      '@media screen and (min-height: 1201px)': {
        height:
          paddingRightBox.height1200 + paddingRightBox.firstPadding + 'px',
      },
    },
    props.sxStyle,
  );
  if (props.children) {
    return <Box sx={sx}>{props.children}</Box>;
  } else {
    return <Box sx={sx} />;
  }
};
WritingNoteBox.propTypes = {
  children: PropTypes.node,
  sxStyle: object,
};

export const SlideIframeBox = props => {
  return (
    <Box
      sx={{
        height: paddingRightBox.height + 'px',
        '@media screen and (min-height: 940px) and (max-height: 1200px)': {
          height: paddingRightBox.height940 + 'px',
        },
        '@media screen and (min-height: 1201px)': {
          height: paddingRightBox.height1200 + 'px',
        },
      }}
    >
      {props.children}
    </Box>
  );
};
SlideIframeBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export const LeftWhiteBox = props => {
  return (
    <Box
      sx={{
        paddingTop: paddingRightBox.firstPadding + 'px',
        paddingBottom: paddingRightBox.firstPadding + 'px',
        height:
          paddingRightBox.secondPaddingVertical * 2 +
          paddingRightBox.height +
          'px',
        '@media screen and (min-height: 940px) and (max-height: 1200px)': {
          height:
            paddingRightBox.secondPaddingVertical * 2 +
            paddingRightBox.height940 +
            'px',
        },
        '@media screen and (min-height: 1201px)': {
          height:
            paddingRightBox.secondPaddingVertical * 2 +
            paddingRightBox.height1200 +
            'px',
        },
        overflowY: 'scroll',
        paddingLeft: '10px',
        paddingRight: '10px',
        backgroundColor: '#ffffff',
        borderRadius: '25px',
        boxShadow: '2px 2px 3px 0 rgba(116, 136, 101, 0.46)',
      }}
      className={'LeftWhiteBox'}
    >
      {props.children}
    </Box>
  );
};

LeftWhiteBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export const GreenDashBox = props => {
  return (
    <Box
      sx={{
        padding: paddingRightBox.firstPadding + 'px',
        backgroundColor: '#cad8bf',
        borderRadius: '25px',
      }}
      className={'GreenDashBox'}
    >
      <Box
        sx={{
          paddingTop: paddingRightBox.secondPaddingVertical + 'px',
          paddingBottom: paddingRightBox.secondPaddingVertical + 'px',
          borderRadius: '20px',
          backgroundImage:
            "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23748865' stroke-width='4' stroke-dasharray='10%2c 11' stroke-dashoffset='13' stroke-linecap='square'/%3e%3c/svg%3e\")",
          paddingLeft: '18px',
          paddingRight: '10px',
        }}
      >
        <Box
          sx={{
            height: paddingRightBox.height + 'px',
            '@media screen and (min-height: 940px) and (max-height: 1200px)': {
              height: paddingRightBox.height940 + 'px',
            },
            '@media screen and (min-height: 1201px)': {
              height: paddingRightBox.height1200 + 'px',
            },
            overflowY: 'scroll',
            paddingRight: '1rem',
          }}
        >
          {props.children}
        </Box>
      </Box>
    </Box>
  );
};

GreenDashBox.propTypes = {
  children: PropTypes.node.isRequired,
};

GreenDashBox.propTypes = {
  radarData: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
};

export const MissionBox = styled(Box)(
  props => `
  font-size: 40px;
  font-weight: bold;
  color: #4e5c43;  
  padding-top: 0.7rem;
  padding-bottom: 0.8rem;
}`,
);

export const QnaTitleBox = styled(Box)(
  props => `
  color: #4e5c43;
  font-weight: 500;
  font-size: 24px;
  padding-left: 1rem !important;
  padding-bottom: 0.75rem;
}`,
);

export const QuizCard = styled(Card)(props => ({
  fontSize: '20px',
  fontWeight: '500',
  backgroundColor: '#cad8bf',
  boxShadow: 'none',
  width: '100%',
  // padding: '2rem 8rem 2rem 2rem',
  marginBottom: '1.5rem',
}));

export const QuizCardHeader = styled(CardHeader)(props => ({
  padding: '0.75rem 1.4rem !important',
  color: '#3d6614',
  backgroundColor: '#ffb16a',
  borderTopLeftRadius: '20px',
  borderTopRightRadius: '20px',
  '& .MuiTypography-root': {
    fontSize: '21px',
    fontWeight: '500',
    lineHeight: '1.5',
  },
}));

export const QfControl = styled(FormControl)(props => ({
  textAlign: 'center',
  backgroundColor: '#ffddbe',
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    padding: '10px 14px !important',
  },
}));

export const QuizTextField = styled(TextField)(props => ({
  backgroundClip: 'padding-box',
  '& .MuiInputBase-input': {},
  '& .MuiOutlinedInput-input': {
    textAlign: 'left',
    lineHeight: '1.25',
    color: '#979797',
    fontSize: '20px !important',
  },
  '& .MuiOutlinedInput-input.Mui-disabled': {
    color: '#000000 !important',
  },
  '&.MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
    opacity: 1,
    // -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
    webkitTextFillColor: 'none',
  },
}));

export const QuizButton = styled(Button)(props => ({
  fontSize: '20px',
  lineHeight: '1.25',
  minHeight: '58px',
  borderRadius: '12px',
  backgroundColor: '#fc8d27',
  color: '#fff',
  marginTop: '1rem',
  '&.Mui-disabled': {
    backgroundColor: '#bcb3aa !important',
    color: '#fff !important',
  },
  '&.answerCompleted': {
    textAlign: 'left',
    backgroundColor: '#fff1e4 !important',
    color: '#cb6a12 !important',
    border: 'solid 1.5px #fc8d27',
    boxShadow: 'none',
  },
  boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
  '&:hover': {
    backgroundColor: '#fc8d27',
    color: '#fff',
  },
}));

export const StructHintSelect = styled(Select)(props => ({
  backgroundColor: '#578634',
  color: '#fff',
  minWidth: '211px',
  textAlign: 'left',
  height: '44px',
  borderRadius: '8px',
  margin: '1',
  fontSize: '18px',
  border: '0',
}));

export const StructSearchPaper = styled(Paper)(props => ({
  p: '0px 4px',
  backgroundColor: '#FFFFFF',
  borderRadius: '20px !important',
  border: 'solid 1.5px #578634',
  width: '100%',
  position: 'absolute',
  bottom: '0px',
}));

export const StructSearch = styled(TextValidator)(props => ({
  '& .MuiOutlinedInput-root': {
    fontSize: '17px !important',
    paddingLeft: '18px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '0 !important',
  },
  '& .MuiOutlinedInput-input': {
    paddingLeft: '0px !important',
  },
}));

export const SHintChip = styled(Chip)(props => ({
  backgroundColor: '#578634',
  color: '#fff',
  fontSize: '18px',
}));

export const StructTitle = styled(Box)(props => ({
  display: 'table-cell',
  verticalAlign: 'middle',
  fontSize: '20px',
  color: '#3a4631',
}));

export const StructTextField = styled(TextField)(props => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    fontSize: '18px',
  },
}));

export const StructAccordion = styled(Accordion)(props => ({
  borderRadius: '10px !important',
  backgroundColor: '#ffddbe',
  // border: 'solid 1px #b7b7b7 !important',
  boxShadow:
    '0 8px 24px -4px rgba(24, 39, 75, 0.08), 0 6px 12px -6px rgba(24, 39, 75, 0.12) !important',
  marginBottom: '16px',
  '&::before': {
    height: '0px !important',
  },
}));

export const StructAccSummary = styled(AccordionSummary)(props => ({
  borderBottom: '1px solid #bababd',
  margin: '0px',
}));
export const StructAccSummaryBox = styled(Box)(props => ({
  flexShrink: 0,
  wordBreak: 'break-all',
  whiteSpace: 'normal',
  fontSize: '18px',
  lineHeight: '1.75rem',
  color: '#1f3c0a',
  padding: '4px 2px',
}));
export const StructAccDetails = styled(AccordionDetails)(props => ({
  paddingTop: '1rem' + '',
  backgroundColor: '#ffffff',
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
}));
export const StructAccDetailsBox = styled(Box)(props => ({
  fontSize: '18px',
  lineHeight: '1.75rem',
  color: '#000',
  paddingBottom: '1rem',
}));
export const StructAccChip = styled(Chip)(props => ({
  backgroundColor: '#fc8d27',
  color: '#fff',
  fontSize: '14px',
}));
//
// export const BootstrapDialog = styled(Dialog)(({theme}) => ({
//   '& .MuiDialogContent-root': {
//     padding: theme.spacing(2),
//   },
//   '& .MuiDialogActions-root': {
//     padding: theme.spacing(1),
//   },
//   '& .MuiPaper-root': {
//     width: '90%',
//     height: '90%',
//   },
// }));
//
// export const BootstrapDialogTitle = props => {
//   const {children, onClose, ...other} = props;
//
//   return (
//     <DialogTitle
//       sx={{m: 0, p: 3, fontSize: '1rem', fontWeight: '700'}}
//       {...other}
//     >
//       {children}
//
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           onClick={onClose}
//           sx={{
//             position: 'absolute',
//             right: 8,
//             top: 8,
//             color: theme => theme.palette.grey[500],
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </DialogTitle>
//   );
// };
//
// BootstrapDialogTitle.propTypes = {
//   onClose: PropTypes.bool,
//   children: PropTypes.node.isRequired,
// };

export const WritingButton = styled(Button)(props => ({
  borderRadius: '8px',
  fontSize: '18px',
  backgroundColor: '#578634',
  color: '#fff',
  width: '193px',
  height: '44px',
  border: 0,
  boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
  '&:hover': {
    backgroundColor: '#578634',
    color: '#fff',
  },
  '&:active': {
    backgroundColor: '#578634',
    color: '#fff',
  },
  '&:active:hover': {
    backgroundColor: '#578634',
    color: '#fff',
  },
  '&:focus': {
    backgroundColor: '#578634',
    color: '#fff',
  },
  '&:focus:hover': {
    backgroundColor: '#578634',
    color: '#fff',
  },
}));

export const WritingSpellingHelp = styled(Box)(props => ({
  width: '58px',
  height: '22px',
  borderRadius: '8px',
  border: 'solid 0.6px #c5cdbe',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  fontSize: '10px',
  lineHeight: '2.2',
  textAlign: 'center',
  color: '#848484',
}));
