import {Routes, Route, Navigate, useLocation} from 'react-router-dom';
import Home from 'page/home/Home';
import Signin from 'page/auth/Signin';
import UserSettings from 'page/setting/UserSettings';
import NotFoundError from 'page/error/404';
import Essay from 'page/essay/Essay';
import EmptyLayout from 'view/layout/course/EmptyLayout';
import EssayLayout from 'view/layout/course/EssayLayout';
import {useSelector} from 'react-redux';

export const RootRouter = () => {
  const authReducer = useSelector(state => state.authReducer);

  const location = useLocation();

  // eslint-disable-next-line react/prop-types
  const PrivateWrapper = ({children}) => {
    console.log(authReducer);
    const isLogin = authReducer.isLogin;
    return isLogin ? (
      children
    ) : (
      <Navigate to="/signin" replace state={{path: location.pathname}} />
    );
  };

  return (
    <Routes>
      <Route path={'/'} element={<EmptyLayout />}>
        <Route path="/" element={<Home />} index={true} />
      </Route>
      <Route path={'/signin'} element={<Signin />} />
      <Route
        path={'/usersettings'}
        element={
          <PrivateWrapper>
            <UserSettings />
          </PrivateWrapper>
        }
      />
      <Route
        path={'/essay'}
        element={
          <PrivateWrapper>
            <EssayLayout />
          </PrivateWrapper>
        }
      >
        <Route path="/essay" element={<Essay />} />
      </Route>
      <Route path={'/404'} element={<NotFoundError />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
};
