import {Backdrop, CircularProgress} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const ApiLoading = props => {
  return (
    <Backdrop
      sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}}
      open={props.loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

ApiLoading.propTypes = {
  loading: PropTypes.bool,
};

export default ApiLoading;
